.form-wrapper {
  box-shadow: 1.5rem 1rem 4rem -2rem #000b;
  background: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
  padding: 5px 10px;
  border: solid 11.2px black;
  border-radius: 32px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.295);
  width: 300px;
}
.input-group-with-tooltip {
  position: relative;
  width: 100%;
}
.about {
  color: rgba(53, 53, 53, 0.9019607843);
  font-size: 1rem;
  line-height: 28px;
  width: auto;
  // position: absolute;
  // top: 88%;
  // right: 3%;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;

  label {
    color: #000;
    line-height: 25px;
    font-weight: 700;
    text-shadow: 0 0 black;
    white-space: nowrap;
  }

  input {
    font-size: 0.75rem;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #000;
    outline: #f3cf71;
    color: black;
    background-color: hsla(0, 0%, 100%, 0.09);
    box-shadow: 0 5px 5px #b3ab96;
  }

  .required {
    color: #de0000;
    font-size: 0.75rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
}
.match {
  font-weight: 800;
  color: #de0000;
  margin-top: 5px;
}
.react-tel-input {
  width: 100%;
  .special-label {
    display: none !important;
  }

  .form-control {
    outline: none;
    background: transparent !important;
    border: 1px solid #000 !important;
    border: none;
    width: 100% !important;
    height: 40px;
    font-size: 0.75rem;
    border-radius: 8px;
    color: black;
  }

  .country-list {
    .country {
      color: black;
    }
  }
}
@media screen and (max-width: 400px) {
  .input-group label {
    white-space: pre-wrap !important;
  }
  .slide {
    width: 250px;
  }
  .newAcct {
    font-size: 0.75rem !important;
  }
}

.input-group label {
  font-size: 0.75rem;
  font-weight: 900;
}

.react-tel-input .form-control {
  // to overide the styles from the phone library I added specifity now the input box looks like the others
  height: auto; /* Remove fixed height */
  padding: 8px 12px; /* Adjust padding to match other inputs */
  font-size: 0.75rem;
}
.react-tel-input .form-control:focus {
  outline: 1px solid #f3cf71 !important; /* Set your desired color */
}

.react-tel-input .form-control {
  /* other styles */
  text-indent: 35px; /* Adjust this value as needed */
}


.react-phone-input .input::placeholder {
  color: red !important;
}
