#chat-wrapper {
  width: 300px;
  height: 350px;
  border-radius: 30px;
  border: 2px solid #000;
  // border: 2px solid rgba(53, 53, 53, 0.9019607843);
  padding: 20px 18px 5px 25px;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 280px;
  height: 350px;
  overflow: hidden;

  .chat-message-list {
    list-style: none;
    height: 100%;
    width: 98%;
    overflow-y: auto;
    padding: 0 20px 0 0;

    &::-webkit-scrollbar {
      width: 5px;
      height: 30px;
      background: transparent;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      height: 8%;
    }
    &::-webkit-scrollbar-track-piece {
      height: 40px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .message-left,
  .message-right {
    .message-time {
      display: block;
      font-size: 12px;
      color: #ccc;
      font-family: Courier;
    }

    .message-text {
      max-width: 80%;
      display: inline-block;
      padding: 15px;
      font-size: 1rem;
      border-radius: 30px;
      font-weight: 100;
      line-height: 1.5em;
      white-space: pre-wrap;
    }
  }

  .message-left {
    text-align: left;
    margin-bottom: 16px;

    .message-time {
      text-align: left;
      padding-left: 30px;
      padding-top: 4px;
    }

    .message-text {
      background: #233f5f;
      color: #fefefe;
      text-align: left;
      font-family: sans-serif;
      font-weight: 600;
      white-space: pre-wrap;
    }
  }

  .message-right {
    text-align: right;
    margin-bottom: 16px;

    .message-time {
      text-align: right;
      padding-right: 20px;
      padding-top: 4px;
    }

    .message-text {
      background: #186acf;
      color: #fefefe;
      text-align: left;
      font-family: sans-serif;
      font-weight: 600;
    }
  }
  .chat-thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px; // adjust as needed
  }

  .message-right .chat-thumbnail {
    margin-left: 10px;
    margin-right: 0;
  }

  .spinme-left,
  .spinme-right {
    display: inline-block;
    padding: 15px 20px;
    font-size: 14px;
    border-radius: 30px;
    line-height: 1.25em;
    font-weight: 100;
    opacity: 0.2;
  }

  .spinme-left {
    color: #ccc;
  }

  .typingIndicator {
    margin: 0;
    width: 30px;
    text-align: center;

    > div {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      background: #fff;
    }

    .bounce1 {
      animation-delay: -0.32s;
    }

    .bounce2 {
      animation-delay: -0.16s;
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .chat-thumbnail {
    object-fit: cover;
    border-radius: 50%; /* If you want a circular thumbnail */
  }
}
