 
 
.avatar-border svg {
  border: 10px solid black;
}
.notification-card{
  display: flex;
  flex-direction: row;
  align-items: center;
  position:relative;
  flex-grow: 1;
  box-sizing: border-box;
  gap:10px
  // padding-left: 18px; // pushes it to the right
}

