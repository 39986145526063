#app-layout {
  background-color: rgba(255, 255, 255, 0.09);
  max-width: 1800px;
  margin: 0 auto;

  header {
    box-shadow: 0 8px 6px 0px rgba(0, 0, 0, 0.493);

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    
    // making the header stick at the top when user scrolls
    &.sticky-header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 20;
    }

    &#landing-page-header {
      transition: transform 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-family: Arial, Helvetica, sans-serif;
      color: #e2b645;
      font-weight: 500;
      font-size: 1.2rem;
      background-color: rgb(3, 3, 3);
      border-bottom: 2px solid #949494; // giving the edge a smooth appearance and saving the eyes for the right color leaves it professional
      background-image: url("../../assets/leatherTexture.png") !important;
      // background-size: cover; /* Cover the entire area */
      background-size: 100% 100%; /* setting x and y background size to be stretched
       The image is being stretched or squished to fit the dimensions of the header seperate from the global because there is a conditional scrolling on this header for the landing page */
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Prevent repeating the image */


      svg {
        height: auto;
        width: 25px;
      }
    }

    &#global-header {
      background-color: rgb(3, 3, 3);
      background-size: 100% 100%; // had to smooth it here too since the conditional is set for the landing to scroll to avoid the book getting stuck see notes above
      background-image: url("../../assets/leatherTexture.png") ;
      border-bottom: 2px solid #949494; // giving the edge a smooth appearance and saving the eyes for the right color leaves it professional
      @media(max-width:811px){
        height: 50px; 
      }
    }
  }

  main {
    padding: 30px 24px;
    min-height: calc(100vh - 160px);

    &.is-landing-page {
      background-color: hsla(0, 0%, 100%, 0.6);
    }
  }
}
