#event-details-card {
  h4 {
    margin-bottom: 0;
  }

  ul {
    margin-top: 0;
  }
}
#EventDetailspage-container {
  max-width: 828px;
  margin: 0 auto;
  padding-bottom: 60px;


  .eventspg-required-message {
    color: red;
    position: absolute;
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "roboto-slab";
    right: 30%;
    bottom: 30%;
  }
  .required-message::placeholder {
    color: red;
    font-size: 1.5rem;
    font-weight: 800;
    background: rgba(0, 0, 0, 0.3);
    font-family: "roboto-slab";
    -webkit-text-stroke: 0.5008px red;
  }
}
// this spaces the details under the picture
.event-details h2,
.event-details h3,
.event-details .EventLocation {
  margin-bottom: 8px; /* Adjust this pixel value for desired spacing */
}

.event-title {
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  box-shadow: 0 1px 5px black;
}
.EventLocation {
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  box-shadow: 0 1px 5px black;
}
.date-time {
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
}
.address {
  display: flex;
  flex-wrap: wrap;
}
.address p.compactSpacing {
  margin-right: 8px; /* Adjust as needed */
}
.hosts {
  font-size: 1.3rem;
  font-style: italic;
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
}
.place {
  font-family: "math";
  font-size: 1rem;
}
.spacer {
  display: inline-block;
  width: 10px; /* or whatever space you want */
}
.events-cover-picture {
  position: relative;
  overflow: hidden;
  border: 3px solid black;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(
    to bottom,
    #2c3e50,
    /* Dark color at the top */ #bdc3c7
      /* Lighter color at the bottom, create a file that analzyes the process color and show the dominate color from top down to enahnce the mood for version 1 this works */
  );
  img {
    width: 100%;
    // max-height: 50vh;
    max-height: clamp(350px, 35vh, 500px);
    object-fit: fill; /* This will maintain the aspect ratio and fill the container */
    overflow-clip-margin: content-box;
    overflow: clip;
    object-position: center center;
    -webkit-tap-highlight-color: transparent;
  }
  @media (max-width: 758px) {
    padding-left: 12%;
    padding-right: 12%;
    max-height: clamp(350px, 40vh, 500px);
  }
  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
    img {
      max-height: clamp(450px, 50vh, 500px);
    }
  }
  .current-time-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    @media (max-width: 760px) {
      display: none;
    }
  }
}
.EventLocation {
  display: flex;
  flex-direction: row;
}
.eventsDetailsTitle {
  word-break: break-word;
}
.flex-column h3 {
  display: flex;
  white-space: nowrap;
  margin-bottom: 15%; /* Initial margin for smallest screens */
}
@media (min-width: 290px) {
  .flex-column h3 {
    margin-bottom: 14%; /* Slightly reduced margin */
  }
}

@media (min-width: 400px) {
  .flex-column h3 {
    margin-bottom: 12%; /* Further reduction */
  }
}

@media (min-width: 500px) {
  .flex-column h3 {
    margin-bottom: 10%; /* Continue reducing */
  }
}

@media (min-width: 600px) {
  .flex-column h3 {
    margin-bottom: 8%; /* Continue reducing */
  }
}

@media (min-width: 700px) {
  .flex-column h3 {
    margin-bottom: 6%; /* Closer to the final value */
  }
}

@media (min-width: 767px) {
  .flex-column h3 {
    margin-bottom: 5%; /* Target margin for 767px screen width */
  }
}

.events-details-slider-wrapper {
  position: relative;
  left: 6%;
  transform: none;
}
.action-buttons {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row; /* Align buttons horizontally for larger screens */
    justify-content: space-evenly; /* Even space distribution */
    flex-wrap: wrap; /* Allow buttons to wrap to the next line */
    padding-top: 10px; // space between event details and buttons
    .second-row-buttons {
      display: flex; /* Use flexbox for layout */
      justify-content: center; /* Align buttons to the start */
      margin-top: 20px; /* Add some space between the rows */
      gap: 20px; /* This adds space between the buttons */
      flex-wrap: wrap; /* Allow buttons to wrap to the next line */
    }
  }
}
.status-buttons {
  display: flex;
}
.admin-ctrls {
  .maybeNum-active {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fff;
    -webkit-text-stroke: 1px #000;
  }
  .maybeNum-inactive {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #964b00;
    -webkit-text-stroke: 1px #964b00;
  }
  .excludedNum-active {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: white;
    -webkit-text-stroke: 1px #000;
  }
  .excludedNum-inactive {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #964b00;
    -webkit-text-stroke: 1px #964b00;
  }
  .declinedNum-active {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fff;
    -webkit-text-stroke: 1px #000;
  }
  .declinedNum-inactive {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #964b00;
    -webkit-text-stroke: 1px #964b00;
  }
  .invitedNum-active {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fff;
    -webkit-text-stroke: 1px #000;
  }
  .invitedNum-inactive {
    font-size: 1rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #964b00;
    -webkit-text-stroke: 1px #964b00;
  }
}
.attendingNum-active {
  font-size: 1rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}
.attendingNum-inactive {
  font-size: 1rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #fdfd96;
}
.interestedNum-active {
  font-size: 1rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}
.interestedNum-inactive {
  font-size: 1rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #e2b645;
}

.partyListHeader {
  margin: 0;
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  font-family: "roboto-slab";
  color: #fff;
  width: 100%;
  font-size: 2rem;
  text-align: center;
}

.btns-required-message {
  color: red;
  position: absolute;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "roboto-slab";
  right: 30%;
  bottom: 30%;
}

@media screen and (min-width: 1200px) {
  .party-list {
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    align-items: center; /* Center child elements horizontally */
    text-align: center; /* Center text within child elements */
  }
}
.filterNavContainer {
  @media (max-width: 766px) {
    display: none;
  }

  @media (min-width: 767px) {
    display: block; /* or flex, inline-block, etc., as per your layout needs */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    box-shadow: 0 1px 5px black;
    z-index: 30; /* To ensure it appears above other content */
  }
}

.party-list {
  padding-bottom: 20px;
}

.btnsContain {
  @media (min-width: 766px) {
    display: flex;
    gap: 5px;
  }
}
.actionBtnEventsDetailsDsktp {
  display: flex;
  gap: 10px;
}
.backBtnEventsPg {
  display: flex;
  justify-content: space-between; /* This will put maximum space between items, pushing them to opposite ends */
  align-items: center; /* This ensures vertical centering of the items */
}
.eventspg-required-message {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-weight: bold;
}

.compactSpacing {
  margin: 2px 0;
  line-height: 1.3;
}
.compact-Spacing {
  margin: 6px 0;
  line-height: 1.3;
}
.mrgn-top {
  margin-top: 75px;
}
.show-hide-control-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.statusContainer {
  padding-top: 5px;
}
.hide-buttons {
  display: none;
}
.Clear-eventDetailsPgBtn {
  position:absolute;
  left: 90%;
  bottom:30%;
}
/* Additional CSS rules for different contexts or components */
/* ... */

// explaination for object fill approach
// That's an interesting approach.
// By using padding combined with `object-fit: fill;`,
// you're essentially creating a "frame" within which the
// image can expand without stretching beyond the aspect
// ratio you're aiming for.
// Since you're using padding percentages,
// the actual padding amount will scale with the width
// of the container, which can help maintain the
// appearance of the image across different screen sizes.
// This method can be quite effective for responsive designs,
// as it allows for flexibility while keeping the core visual elements intact.
// However, always remember to test extensively.
// Different browsers and devices might render padding
// and object-fit slightly differently, so it's crucial
// to ensure consistency across all platforms your users might access your site from.
