.pic-upload-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5); // Black background with opacity
  
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px;
      border-radius: 10px;
      border: 1px solid #707070;
      box-shadow: 2px 2px 10px 0px rgba(99, 106, 119, 0.6); // Slight shadow for elevation
      backdrop-filter: blur(10px);
      animation-name: slideDown;
      -webkit-animation-name: slideDown;
      animation-duration: 0.9s;
      -webkit-animation-duration: 0.9s;
      animation-timing-function: ease;
      -webkit-animation-timing-function: ease;
  
      input[type="file"] {
        margin-bottom: 1rem;
        padding: 0.5rem;
        border: 1px solid #e2b645;
        border-radius: 4px;
        cursor: pointer;
      }
      .file-upload-btn {
            cursor: pointer;
              height: 50px;
              width: 250px;
              display: flex;
              border-radius: 10px;
              box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
              align-items: center;
              justify-content: center;
              border: 1px solid #e2b645;
              color:#fff;
              font-weight: 800;
        input[type="file"] {
            opacity: 0;
            position: absolute;
            left: -9999px;
        }
    }
    }
  }
  