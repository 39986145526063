html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  a{
    text-decoration: none;
  }
  /* GRID */
  
  .twelve { width: 100%; }
  .eleven { width: 91.53%; }
  .ten { width: 83.06%; }
  .nine { width: 74.6%; }
  .eight { width: 66.13%; }
  .seven { width: 57.66%; }
  .six { width: 49.2%; }
  .five { width: 40.73%; }
  .four { width: 32.26%; }
  .three { width: 23.8%; }
  .two { width: 15.33%; }
  .one { width: 6.866%; }
  
  /* COLUMNS */
  
  .col {
      display: block;
      float:left;
      margin: 1% 0 1% 1.6%;
  }
  
  .col:first-of-type {
    margin-left: 0;
  }
  
  .container{
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }
  
  /* CLEARFIX */
  
  .cf:before,
  .cf:after {
      content: " ";
      display: table;
  }
  
  .cf:after {
      clear: both;
  }
  
  .cf {
      *zoom: 1;
  }
  
  /* ALL */
  
  .row .three{
      padding: 80px 30px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #2c3e50;
      color: #ecf0f1;
      text-align: center;
    }
    
    .three-line-animation .line{
      width: 50px;
      height: 5px;
      background-color: #964b00;
      display: block;
      margin: 8px auto;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    
    @media screen and (max-width: 1130px) {
      .three-line-animation .line:before {
          background: #964b00!important;
      }
  }
  
    
    .three-line-animation .line:before {
        background-color: #964b00!important;
    }
    
    .line:before {
        background-color: #964b00!important;
    }
    
  
  .three-line-animation:hover{
    cursor: pointer;
  }
  
  /* ONE */
  
  #three-line-animation-1.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #three-line-animation-1.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }
  
  #three-line-animation-1.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
  }
  
  /* TWO */
  
  #three-line-animation-2.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
  }
  
  #three-line-animation-2.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px);
    -ms-transform: translateY(-13px);
    -o-transform: translateY(-13px);
    transform: translateY(-13px);
  }
  
  /* THREE */
  
  #three-line-animation-3.is-active .line:nth-child(1),
  #three-line-animation-3.is-active .line:nth-child(3){
    width: 40px;
  }
  
  #three-line-animation-3.is-active .line:nth-child(1){
    -webkit-transform: translateX(-10px) rotate(-45deg);
    -ms-transform: translateX(-10px) rotate(-45deg);
    -o-transform: translateX(-10px) rotate(-45deg);
    transform: translateX(-10px) rotate(-45deg);
  }
  
  #three-line-animation-3.is-active .line:nth-child(3){
    -webkit-transform: translateX(-10px) rotate(45deg);
    -ms-transform: translateX(-10px) rotate(45deg);
    -o-transform: translateX(-10px) rotate(45deg);
    transform: translateX(-10px) rotate(45deg);
  }
  
  /* FOUR */
  
  #three-line-animation-4.is-active .line:nth-child(1),
  #three-line-animation-4.is-active .line:nth-child(3){
    width: 40px;
  }
  
  #three-line-animation-4.is-active .line:nth-child(1){
    -webkit-transform: translateX(10px) rotate(45deg);
    -ms-transform: translateX(10px) rotate(45deg);
    -o-transform: translateX(10px) rotate(45deg);
    transform: translateX(10px) rotate(45deg);
  }
  
  #three-line-animation-4.is-active .line:nth-child(3){
    -webkit-transform: translateX(10px) rotate(-45deg);
    -ms-transform: translateX(10px) rotate(-45deg);
    -o-transform: translateX(10px) rotate(-45deg);
    transform: translateX(10px) rotate(-45deg);
  }
  
  /* FIVE */
  
  #three-line-animation-5.is-active{
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  #three-line-animation-5.is-active .line:nth-child(2){
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  
  #three-line-animation-5 .line:nth-child(2){
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  
  
  #three-line-animation-5.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #three-line-animation-5.is-active .line:nth-child(1),
  #three-line-animation-5.is-active .line:nth-child(3){
    width: 35px;
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
  }
  
  #three-line-animation-5.is-active .line:nth-child(1){
    -webkit-transform: translateY(15px) rotate(45deg);
    -ms-transform: translateY(15px) rotate(45deg);
    -o-transform: translateY(15px) rotate(45deg);
    transform: translateY(15px) rotate(45deg);
  }
  
  #three-line-animation-5.is-active .line:nth-child(3){
    -webkit-transform: translateY(-15px) rotate(-45deg);
    -ms-transform: translateY(-15px) rotate(-45deg);
    -o-transform: translateY(-15px) rotate(-45deg);
    transform: translateY(-15px) rotate(-45deg);
  }
  
  /* SIX */
  
  #three-line-animation-6.is-active{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #three-line-animation-6.is-active .line:nth-child(2){
    width: 0px;
  }
  
  #three-line-animation-6.is-active .line:nth-child(1),
  #three-line-animation-6.is-active .line:nth-child(3){
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  
  #three-line-animation-6.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
  }
  
  #three-line-animation-6.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(90deg);
    -ms-transform: translateY(-13px) rotate(90deg);
    -o-transform: translateY(-13px) rotate(90deg);
    transform: translateY(-13px) rotate(90deg);
  }
  
  /* SEVEN */
  
  #three-line-animation-7.is-active .line:nth-child(1){
    width: 30px;
  }
  
  #three-line-animation-7.is-active .line:nth-child(2){
    width: 40px;
  }
  
  #three-line-animation-7.is-active .line{
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  
  /* EIGHT */
  
  #three-line-animation-8.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #three-line-animation-8.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
  }
  
  #three-line-animation-8.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(90deg);
    -ms-transform: translateY(-13px) rotate(90deg);
    -o-transform: translateY(-13px) rotate(90deg);
    transform: translateY(-13px) rotate(90deg);
  }
  
  /* NINE */
  
  #three-line-animation-9{
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #three-line-animation-9.is-active{
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #three-line-animation-9:before{
    content: "";
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border: 5px solid transparent;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #three-line-animation-9.is-active:before{
    border: 5px solid #ecf0f1;
  }
  
  #three-line-animation-9.is-active .line{
    width: 35px;
  }
  
  #three-line-animation-9.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #three-line-animation-9.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
  }
  
  #three-line-animation-9.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(90deg);
    -ms-transform: translateY(-13px) rotate(90deg);
    -o-transform: translateY(-13px) rotate(90deg);
    transform: translateY(-13px) rotate(90deg);
  }
  
  /* TEN */
  
  #three-line-animation-10{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #three-line-animation-10.is-active{
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  #three-line-animation-10.is-active .line:nth-child(1){
    width: 30px
  }
  
  #three-line-animation-10.is-active .line:nth-child(2){
    width: 40px
  }
  
  /* ELEVEN */
  
  #three-line-animation-11{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  #three-line-animation-11.is-active{
    animation: smallbig 0.6s forwards;
  }
  
  @keyframes smallbig{
    0%, 100%{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  
    50%{
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
    }
  }
  
  #three-line-animation-11.is-active .line:nth-child(1),
  #three-line-animation-11.is-active .line:nth-child(2),
  #three-line-animation-11.is-active .line:nth-child(3){
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  
  #three-line-animation-11.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #three-line-animation-11.is-active .line:nth-child(1){
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }
  
  #three-line-animation-11.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
  }
  
  /* TWELVE */
  
  #three-line-animation-12.is-active .line:nth-child(1){
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  
  #three-line-animation-12.is-active .line:nth-child(3){
    opacity: 0;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
