.bio-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); // This gives the dim effect.
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#bio-form {
  width: 80%;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #707070;
  box-shadow: 2px 2px 10px 0px rgba(99, 106, 119, 0.6); // Slight shadow for elevation
  backdrop-filter: blur(10px);
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  .header {
    display: flex;
    align-items: center; /* vertically centers the items */
    justify-content: space-between; /* spreads the h2 and the button apart */
    gap: 10px; /* a bit of gap between items, just in case */
    width: 100%;
    height: 50px;
    padding-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.09);
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: relative;
  }

  /* Styling for texts */
  .headerFont {
    margin: 0;
    color: #fff;
    white-space: pre-wrap; /* Wrap text as in textarea */
    word-wrap: break-word; /* Ensure long words do not overflow */
    white-space: wrap;
    font-size: 1.5rem;
    font-weight: 800;
    -webkit-text-stroke: 0.5008px black;
    font-family: "Roboto Slab";
  }

  .weight700 {
    font-weight: 700;
  }
  .mrg-top {
    margin-top: 5px;
  }

  /* Styling for focused inputs */
  .focused-textarea {
    box-shadow: 0px 1px 5px #000;
    border-color: transparent;
    background-color: #fff;
  }

  /* Styling for event name input's placeholder */
  .textarea-container textarea::placeholder {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #ffff;
    -webkit-text-stroke: 0.5008px #e2b645;
  }

  /* More input stylings */
  .textarea-container .focused-textarea {
    background-color: white;
    box-shadow: 0 1px 5px black;
    border-color: transparent;
  }

  .textarea-container .focused-textarea::placeholder {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: Helvetica;
    color: inherit !important;
    -webkit-text-stroke: 0 !important;
  }

  .textarea-container textarea {
    font-size: 1.3rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 1px 5px black;
    background: transparent;
    border-color: #e2b645;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /* Styling for description input */
  .description textarea {
    height: 100px;
  }
  /* Input styles */
  .focused-textarea {
    color: #964b00;
    font-size: 1rem;
    font-weight: 600;
  }
  /* Clear button styles */
  .reset-bio {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 0px 0 0;
    // position: absolute;
    // top: 5%;
    // right: 8%;
    height: 30px;
    width: 50px;
    display: table-cell;
    vertical-align: middle;
    border-radius: 25px !important;
    background: rgba(255, 255, 255, 0.09);
    font-weight: 700;
    font-family: "roboto-slab";
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    font-size: 1rem;
    border: 1px solid #fdfd96;
    color: #fdfd96;
    margin-top: 10px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
  }
  /* Container styles to position clear button correctly */
  .textarea-container {
    position: relative;
  }
  .textarea-with-value {
    color: #fdfd96;
    font-size: 1rem;
    font-weight: 600;
  }
  .textarea-focused-value {
    color: #964b00;
    border: 1px solid #964b00;
  }

  .textarea-container .textarea-focused-value + .clear-button {
    color: #964b00;
    border: 1px solid #964b00;
  }
}
.textarea-focused-value,
.focused-textarea,
.textarea-with-value {
  height: 1000px; // set default height
  overflow-y: auto; // show scrollbar if content exceeds the height
  resize: none; // disable manual resize
}
