.white-blend-btn {
    --accent-color: currentcolor;
    border: 1px solid #e2b645;;
    border-radius: 25px;
    box-shadow: 0 4px 3px 1px #fcfcfc, 
                0 6px 8px #d6d7d9, 
                0 -4px 4px #cecfd1, 
                0 -6px 4px #fefefe, 
                inset 0 0 3px 0 #cecfd1;
    display: inline-block;
    background: transparent;
    padding: 5px 4.8px 4.8px;
    font-weight: 900;
    cursor: pointer;

    // Add any additional styling or variations here...
}
