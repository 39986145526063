.unlock-slide {
  position: relative;
  height: 32px;
  width: "290px";
  font-size: 1rem;
  top: -6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 32px;
  backdrop-filter: brightness(4) saturate(3) blur(4rem);
  background: linear-gradient(92deg, #faf9f5 0%, #ffe39d 80%);
  box-shadow: 0 5px 5px #b3ab96;
}

.unlockSlidebtn {
  position: absolute;
  top: -8px;
  width: 47px;
  height: 47px;
  border: 1px solid rgb(214, 212, 212);
  border-radius: 100px;
  box-sizing: border-box;
  background: linear-gradient(120deg, #ffffff 0%, #e6dcc3 80%);
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  cursor: grab;
  overflow: hidden;

  .lock-icon,
  .unlock-icon {
    width: 30px; // Adjust icon size as needed
    height: auto;
  }

  .lock-icon {
    animation: lockAnimation 2s infinite; // 2 seconds animation duration
  }

  .unlock-icon {
    animation: unlockAnimation 2s infinite;
  }
}

.unlock-slide .unlockLabel {
  font-family: "robot-slab";
  color: #964b00;
  text-shadow: 0 0 black;
  box-sizing: border-box;
  flex: 1;
  padding-left: 36px;
  text-align: center;
}
