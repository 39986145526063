.slider-switch {
    position: relative;
    top: 0;   // Adjust this to start at the top of the container wrapped in the component
    left: 0;
    margin-right: 27%;
    height: 32px;

    .label {
        position: absolute;
        font-family: 'Droid Sans', sans-serif;
        font-style: italic;
        color: #fdfd96;
        transition: color .3s;

        &:first-of-type { 
            right: 50%; 
            margin-right: 50px; 
        }

        &:last-of-type { 
            left: 50%; 
            margin-left: 50px; 
        }
    }

    &.left {
        .label:first-of-type {
            color: #fefefe;
        }
    }

    &.right {
        .label:last-of-type {
            color: #fefefe;
        }
    }

    .slider {
        position: absolute;
        display: inline-block;
        background: gray;
        left: 50%;
        width: 80px; 
        height: 27px;
        margin-left: -40px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0 1px 0 rgba(255,255,255,.5), inset 0 4px 0 rgba(0,0,0,.2);
        
        .switch {
            position: absolute;
            top: -4px; 
            right: 4px; // default set next to the default word transition property will need to be on the same side as well to switch correctly
            background: linear-gradient(0deg, #c79f27, #e2b645);            
            width: 32px;
            height: 32px;
            border-radius: 4px;
            transition: right .3s; // setting the slide or switch to be smooth the transition must start whatever side the default side is on
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.4), inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 #964b00, inset 0 -5px 0 rgba(255, 255, 255, 0.4);           
            &::before, &::after {
                content: '';
                position: absolute;
                top: 50%; 
                left: 8px; 
                right: 8px;
                margin-top: -1px;
                height: 2px;
                background: rgba(255,255,255,.9);
            }

            &::before {
                top: calc(50% - 5px);
            }

            &::after {
                top: calc(50% + 3px);
            }
        }

    }
}
.slider-switch.right .slider .switch {
    // transition property is still looking for changes in the right value.
    right: 44px;
}
