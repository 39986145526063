.create-post-circle {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    background: linear-gradient(120deg, #ffffff 0%, #e6dcc3 80%);
    border: 1px solid rgb(214, 212, 212);
    border-radius: 50%; /* Make it round */
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    cursor: pointer; /* Change cursor on hover */
  }
  @media screen and (min-width: 812px) {
    .createPostCircle {
      display: none;
    }
  }
  
  // @media (max-width: /* Your breakpoint for smaller screens, e.g., 600px */) {
  //   .create-post-circle {
  //     /* Adjust styles for smaller screens if necessary */
  //   }
  // }
  .create-post-wrapper {
    display: flex;
    align-items: center; /* Vertically center the items */
    justify-content: flex-start; /* Align items horizontally */
    cursor: pointer; /* Change cursor on hover */
}

.create-post-circle {
    /* Your existing styles for the circle */
    margin-right: 10px; /* Adjust spacing between the circle and the text */
}

// .create-post-text {
//     /* Optional: Add additional styling for the text */
// }
