.notifications-list {
  position: relative;
  
}
.notification-item.unread {
  font-weight: bold;
  background-color: #f5f5f5;
}

.notification-card-spacing {
  margin: 10px 0;
}
