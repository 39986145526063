#community-pad-mobile-search {
  -webkit-backdrop-filter: blur(200px);
  backdrop-filter: blur(200px);
  width: 100%;
  position: sticky;
  top: 50px; /* Adjust based on the header height to make the sticky work */
  z-index: 10; 
  display: flex;
  flex-direction: column;
  align-items: center;

  .smallestScreen {
    // for the top two buttons so that member aligns under the buttons positioned horizontally
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .mbPadFollowOption-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 45px;
    margin-top: 10px;
  }

  .input-container {
    .globalized-btn {
      margin: 0;
    }
  }
  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-position: 5px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    input {
      color: #fff!important; /* Replace with your desired placeholder color */
    }

    .search-icon-btn {
      display: flex;
      flex-direction: column; /* Stack icon and text vertically */
      align-items: center; /* Center-align contents */
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;
      .search-instruction {
        margin-top: 5px; /* Space between icon and text */
        font-size: 0.8rem;
        color: #666;
      }
    }
  }

  .Clear-mb-pad-communitypg{
    display:none;
    @media (min-width: 375px) {
      display: block;
      position: absolute;
      right: 40%; /* Adjust for default view */
      top: 0;
      transform: translateY(-50%);
      top: 25px;
    }
  }
  .cancel {
    height: 42px;
  }

  .followingNumPadMble-inactive {
    font-size: 0.75rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #e2b645;
  }
  .followingNumPadMble-active {
    font-size: 0.75rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fff;
  }
  .followersNumPadMble-inactive {
    font-size: 0.75rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fdfd96;
  }
  .followersNumPadMble-active {
    font-size: 0.75rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #fff;
  }
  .search-btn-mbpad {
    padding-right: 5px;
  }
  .cancel-btn-mbpad {
    padding-right: 5px;
  }

  @media (min-width: 811px) {
    display: none; // hide for bigger screens
  }
  .btns-container {
    display: grid;
    gap: 10px 10px; /* First value is for vertical (row) gap, second for horizontal (column) gap */
    grid-template-areas:
      "all-cta posts-cta"
      "members-cta members-cta";
    @media (min-width: 350px) {
      grid-template-areas: "all-cta posts-cta members-cta";
      grid-template-rows: 1fr;
    }
  }
  .posts-cta {
    grid-area: posts-cta;
    height: 40px;
  }
  .all-cta {
    grid-area: all-cta;
    height: 40px;
  }
  .members-cta {
    grid-area: members-cta;
    height: 40px;
  }
}
