/*----------- google fonts--------- */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap");

* {
  margin: 0px;
  padding: 0px;
}

/*  variable decl*/

:root {
  // --selection-background-image: url('../icons/member.svg');
}

body {
  position: relative;
}

/* html {
    scroll-behavior: smooth;
} */

/* ---------------------------#Change--------------------------------- */
.showing_Bidpoints {
  border-radius: 0 0 15px 15px;
  #apply-redeem-btn {
    font-size: 1.5rem;
  }
}

#apply-redeem-btn {
  border-radius: 0 0 15px 15px;
}

.nav_container {
  width: 90%;
  height: 100px;
  margin: auto;
  display: flex;
  /* This was used to put the icon on the begining and the end */
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.pos-corner {
  position: relative;
  left: 80px;
  bottom: 40px;
}

.nav_container > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.nav_container > h1 {
  font-size: 3rem;
  color: #6a6a6a;
  font-family: Roboto-slab;
  /* margin-top: 5px; */
  /* padding: 1px; */
}

.checkoutHeading {
  height: 100px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.checkoutHeading > h1 {
  font-family: "roboto slab";
  font-weight: bold;
  font-size: 1.5rem;
  -webkit-text-stroke: 1px;
}

.checkoutHeading > h1 .showingItems {
  font-size: 1.2rem;
}

.showingItems .totalItem {
  color: #016dda;
  padding: 5px;
}

/* ------------------slider--------- */

#sliderSection {
  margin: auto;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.slider_area {
  width: 570px;
  height: 255px;
  border: 2px solid #a5a7aa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  gap: 30px;
}

.icons {
  width: 80%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.cancel {
  font-size: 0.75rem !important;
  animation: none !important;
  color: #233f5f;
}
.upArrowIndcator {
  height: 30px;
  width: 61px;
  position: absolute;
  top: -30px;
  left: 10px;
}
.singleLine {
  width: 88%;
  position: absolute;
  height: 5px;
  background: #a5a7aa;
  left: 30px;
  z-index: -1;
}

.line1,
.line2,
.line3,
.line4,
.line5 {
  height: 5px;
  width: 61px;
  position: absolute;
}

.line1 {
  left: 30px;
}

.line2 {
  left: 120px;
}

.line3 {
  left: 210px;
  width: 63px;
}

.line4 {
  left: 300px;
}

.line5 {
  left: 390px;
}

.childLine1,
.childLine2,
.childLine3,
.childLine4,
.childLine5 {
  height: 5px;
  width: 0%;
  background: #f18400;
  transition: 0.8s ease;
}

.childLine1 {
  transition: 0.5s ease;
}

.card-icons {
  display: flex;
  justify-content: space-between;
}

#sliderRange[type="range"] {
  -webkit-appearance: none;
  height: 3px;
  border-radius: 4px;
  outline: none;
  background: #ccc;
  cursor: pointer;
}

#sliderRange[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #f18401;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  cursor: pointer;
}

.card-icons img {
  width: 30px;
}

.dashboardCheckout {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.confirm_order_section {
  max-width: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.confirm_order_section > button {
  width: 210px;
  height: 35px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
  color: #000;
  font-size: 1.3rem;
  gap: 5px;
  margin: auto;
  border-radius: 30px;
  text-shadow: 0 1px #fff;
  font-weight: 800;
  cursor: pointer;
}
// .confirm_order_section > button:not(:disabled) {
//   background-image: linear-gradient(
//     to bottom,
//     #f6f4f4 -112%,
//     #ffd9ab -14%,
//     rgba(243, 145, 25, 0.98) 73%,
//     #f18400
//   );
// }

.take_to_payment {
  width: 210px;
  height: 35px;
  outline: none;
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  // background-image: linear-gradient(
  //   to bottom,
  //   #f6f4f4 -112%,
  //   #ffd9ab -14%,
  //   rgba(243, 145, 25, 0.98) 73%,
  //   #f18400
  // );
  font-family: "Nunito";
  font-size: 1.3rem;
  gap: 5px;
  margin: auto;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.Order_confirmed {
  cursor: pointer;
}

.Order_confirmed > a {
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-content: center;
}

.termContainer_subtotalSection {
  font-family: "Roboto";
  text-align: center;
  margin: auto;
  padding: 15px 0px 45px;
}

.termContainer_subtotalSection > p > a {
  text-decoration: none;
  color: #fdfd96;
}

.dashboardCheckoutHeader {
  font-family: "Roboto slab";
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-text-stroke: 1px;
  padding: 15px 0px;
}

.wrapper_giftcard {
  background: #817d7d;
  border-radius: 10px;
  max-width: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.wrapper_giftcard .slider_giftcard {
  height: 4px;
  width: 100%;
  position: relative;
  border-radius: 50px;
}

.slider_giftcard input {
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  position: absolute;
  background: none;
  outline: none;
  top: 0;
  z-index: 2;
}

/* --------------------------#Change----------------------------------- */
#giftCard_slider_container {
  position: relative;
}

.slider_giftcard input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: none;
  cursor: pointer;
}

.slider_giftcard .progress-bar_giftcard {
  height: 100%;
  width: 0%;
  background: #e2b645;
  border-radius: 50px;
  position: relative;
}

.slider_giftcard .thumb_giftcard {
  height: 15px;
  width: 15px;
  /* background: #252424; */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: -5px;
}

.slider_giftcard .thumb_giftcard span {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #e2b645;
  display: block;
}

.cardCoinatiner {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(0, 0, 0, 0.7333333333);
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally in the column */
    padding: 5px 4px; /* Adjust padding as needed */
  }
  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
}

.cardImage {
  border-radius: 15px;
  margin: 5px;
  /* padding: 10px; */
}

.reward-option {
  width: 200px;
  height: 100px;
  border: 1px solid #a5a7aa;
  padding: 10px;
  border-radius: 15px;
  margin: 5px;
}

.starAndreview > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-family: Nunito;
}

.starAndreview > div > img {
  transform: scale(1.5);
  margin-left: 2px;
}

.rewardText {
  font-size: 1.3rem;
  font-weight: bolder;
  color: #fefefe;
  text-decoration: underline;
}

.rewardlearnmore {
  font-size: 1rem;
  font-weight: bolder;
  color: #393636;
}

/* another way to write a child with a comma */

.circleOfpoints,
.showAvailableBidpoints {
  height: 100px;
  width: 200px;
  padding: 10px;
  border: 1px solid #a5a7aa;
  border-radius: 15px;
  position: relative;
  margin: 5px;
}

.circleOfpoints {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.showAvailableBidpoints > div {
  font-family: Nunito;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showAvailableBidpoints > div > p {
  font-size: 1rem;
  font-weight: bold;
  color: #fefefe;
}

.showAvailableBidpoints > div > img {
  margin-bottom: 18px;
  transform: scale(1.4);
}

.usergiftcardBalance,
.userBidPoints {
  color: #c79f27;
  font-family: "Roboto slab";
  font-weight: 800;
}

.bidPoints {
  font-weight: 800;
  color: #c79f27;
}

.redeemBtnandPoints > button {
  font-family: "Nunito";
  border: 1px solid #c2c2c2;
  width: 80px;
  height: 32px;
  outline: none;
  padding: 5px;
  cursor: pointer;
  // background: #3076bc;
  font-size: 0.75rem;
  margin-top: 0px;
  margin-right: 0px;
}

.show_showingBidpoints_section:disabled {
  background-color: #a5a7aa;
  cursor: no-drop;
}

/* ----------------------------#Change------------------------------- */
/* .redeemBidBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-image: url(../dashboard/assets/btn-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-family: 'Nunito Sans';
    font-weight: bold;
    color: #FFF;
    z-index: 1;
} */

.showing_Bidpoints {
  display: none;
  width: 100%;
  z-index: 5;
  border: 1px solid #006fdb;
  background-color: #fff;
  height: auto;
  position: absolute;
  left: 0px;
}

.showAllBidPoints {
  background: #fff5ef;
  border-top: 1px solid #006fdb;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.show_hide {
  display: block !important;
}

.countAmount {
  padding: 5px 0px;
  margin-left: 5px;
}

.header_showBidpoints {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  gap: 5px;
}

.header_showBidpoints > h1 {
  -webkit-text-stroke: 1px;
  color: #2f7fc1;
}

.showAvailableBidpoints > p {
  font-family: "Roboto Slab";
  font-size: 0.7rem;
  color: #fefefe;
  font-weight: bold;
  padding-top: 3px;
}

.cartAndTotal {
  width: 80%;
  height: 80px;
  //   margin-left: 5%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.cartAndTotal > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.cartAndTotal > div > h1 {
  font-family: "Roboto slab";
  font-size: 1.5rem;
  font-weight: bold;
  -webkit-text-stroke: 1px;
}

.cartAndTotal > h1 {
  font-family: "Roboto slab";
  font-size: 1.3rem;
  font-weight: bold;
  -webkit-text-stroke: 1px;
}

.rangeSlider > input[type="range"] {
  width: 100px;
  height: 2px;
  -webkit-appearance: none;
  outline: none;
  background-color: #dbdcde;
}

#staticRange {
  position: relative;
  height: 20px;
  width: 100px;
}

#staticRange > input[type="range"] {
  width: 100px;
  height: 2px;
  -webkit-appearance: none;
  outline: none;
  background: rgb(158, 151, 151);
}

.uperStatic {
  height: 2px;
  width: 100px;
  position: absolute;
  background: #000;
  left: 0px;
  top: 50%;
}

.rangeSlider > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #016dda;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 1;
}

.rangeSlider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 40px;
}

.roundForSlider {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  background-color: #016dda;
  left: 269px;
  top: 13px;
  z-index: 0;
}

.rangeSlider > h2 {
  font-family: "Roboto Slab";
  font-size: 1rem;
}

.text_guide_range {
  width: 100%;
  height: 20px;
  padding: 20px 0px 0;
}

.underLine {
  width: 75%;
  height: 1px;
  outline: none;
  border: none;
  background: #fefefe;
  margin: 20px auto 0;
}

.total_due {
  width: 100%;
  height: 50px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.total_due > h1 {
  font-family: "Roboto Slab";
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-text-stroke: 1px;
}

.due_amount {
  font-family: "Nunito";
  font-size: 1.1rem;
  font-weight: bold;
}

.text_guide_range > p {
  font-size: 1.1rem;
  text-align: center;
}

#selected_balance_fromRangeSlider {
  font-family: "Nunito";
  font-size: 1rem;
  width: 50px;
}

#itemTable {
  width: 90%;
  margin: auto;
}

#itemTable > table {
  width: 100%;
}


.place-order-btn > h1 {
  font-family: "Roboto slab";
  font-size: 1.3rem;
  text-align: center;
}

.place-order-btn > button {
  width: 210px;
  height: 35px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
  color: #000;
  font-size: 1.3rem;
  gap: 5px;
  margin: auto;
  border-radius: 30px;
  text-shadow: 0 1px #fff;
  font-weight: 800;
  cursor: pointer;
}


.order_summary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 5px;
}

.order_summary > img {
  transform: scale(1.3);
}

.ItemSub {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ItemSub > h2 {
  font-family: "Roboto Slab";
  font-size: 1.2rem;
  font-weight: bold;
}

.subtotal_price {
  font-family: "Nunito";
  font-weight: bold;
}

.Item_reffer_fee {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Item_reffer_fee > h2 {
  font-family: "Roboto Slab";
  font-size: 1.1rem;
}

.Item_reffer_fee > span {
  font-family: "Nunito";
  font-weight: bold;
  border-bottom: 1px solid #fefefe;
  width: 65px;
  text-align: right;
}

.Item_grand_total {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Item_grand_total > h2 {
  font-family: "Roboto Slab";
  font-weight: bold;
  font-size: 1.3rem;
}

.Item_grand_total > span {
  font-family: "Nunito";
  font-weight: bold;
}

.termsAndCondition {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.payment-method {
  position: relative;
}

#payment {
  width: 100%;
  margin: auto;
  position: relative;
  padding-bottom: 100px;
}



.otherIcons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  height: 100%;
}

.otherIcons > .item_price {
  font-family: "Roboto Slab";
  font-size: 0.9rem;
}


.bidPointsandIcon {
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  small {
    font-size: .75rem;
    font-weight: 400;
  }
}





// checkout table ends


