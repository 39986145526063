#groups-page-container {
  max-width: 828px;
  margin: 0 auto;
  padding-bottom: 100px; /* Footer height + extra space */
  .required-message::placeholder {
    color: red;
    font-size: 1.5rem;
    font-weight: 800;
    background: rgba(0, 0, 0, 0.3);
    font-family: "roboto-slab";
    -webkit-text-stroke: 0.5008px red;
  }
}
.groups-cover-picture {
  position: relative;
  overflow: hidden;
  border: 3px solid black; /* if needed */
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(
    to bottom,
    #2c3e50,
    /* Dark color at the top */ #bdc3c7
      /* Lighter color at the bottom, create a file that analzyes the process color and show the dominate color from top down to enahnce the mood for version 1 this works */
  );
  img {
    width: 100%;
    max-height: clamp(350px, 35vh, 500px);
    object-fit: fill; /* or 'cover' if you want to maintain aspect ratio */
    object-position: center center;
  }
  @media (max-width: 758px) {
    padding-left: 12%;
    padding-right: 12%;
  }
  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  .groupsEventDetails {
    position: absolute;
    bottom: 0px;
    left: 10px;
  }
}
.membersconnects-info {
  display: flex;
  flex-direction: row;
  align-items: center; // centers content horizontally on the page itself
  align-items: flex-start; // This will align items to the beginning of the page horizontally
  font-size: 1rem;
}
.gpstats {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  font-size: 1rem;
}
.top-buttons {
  display: none;
  @media (min-width: 780px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    gap: 5px; /* Adjusting the gap between buttons */
  }
}
.group-admin-ctrls {
  .interestedNum-active {
    font-size: 1rem;
    font-weight: 800;
    font-family: Helvetica;
    color: #000;
  }
  .interestedNum-inactive {
    font-size: 1rem;
    font-weight: 800;
    font-family: Helvetica;
    color: #964b00;
    -webkit-text-stroke: 1px #964b00;
  }
}

.group-join-btn {
  order: 2;
}

.group-invite-btn {
  order: 1;
}
.group-members-btn {
  margin-top: auto;
  margin-bottom: 10px;
}
.bottom-buttons {
  display: none;
  @media (min-width: 780px) {
    display: flex;
  }
}
.members-section {
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  z-index: 5;
}
.groups-connections-input-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Clear-groupDetailsPg {
  position: absolute;
  bottom: 30px;
  left: 80%;
  @media (min-width: 851px) {
    bottom: 40px;
    position: relative;
    left: 90%;
    cursor: pointer;
  }
}

.backBtnGroupsPg {
  display: flex;
  justify-content: space-between; /* This will put maximum space between items, pushing them to opposite ends */
  align-items: center; /* This ensures vertical centering of the items */
}
@media (max-width: 779px) {
  .group-admin-ctrls {
    display: none; // hide for bigger screens
  }
}
@media (max-width: 779px) {
  .backBtnGroupsPg {
    display: none; // hide for bigger screens
  }
}
@media (max-width: 780px) {
  .groups-connections-input-slider-container {
    display: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  display: none; /* Hidden by default */
  z-index: 10; /* Ensure it's above other content */
  transition: opacity 1s ease; /* Transition for opacity over 2 seconds */

  // animation: fadeOut 2s ease forwards; /* Adjust the duration as needed */
  transition: all 3s ease-in-out; /* Smooth transition for background change */
  display: none; /* Initially hidden */
  opacity: 0; /* Initially fully transparent */
}
.highlight-spotlight {
  position: relative;
  z-index: 1001; /* Above the overlay */
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7); /* Large shadow creates the spotlight effect */
}

.gp-prof-thumbnails {
  .gp-prof-thumbnail {
    // Styles for the thumbnail container (if needed)
    img {
      width: 50px;
      object-fit: contain;
      align-self: end;
      justify-self: start;
      // Add any additional styles here
    }
  }
}
