.event-card {
  // background: linear-gradient(120deg, #f6f0dc 0%, #e2b645 80%); show this if no pic
  box-shadow: inset 0 -3px 8px rgba(0, 0, 0, 0.6),
    inset 0 3px 8px rgba(252, 255, 255, 0.7), 0 3px 8px -3px rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  display: grid;
  grid-template-rows: 10px 1fr 85px; /* Example: 110px for image, remaining space for event info, 40px for button */
  // grid-template-rows: 180px auto auto; /* Explicit height for the image, flexible for the rest */
  width: 300px; /* Adjust width as needed */
  height: 300px; /* Height same as width for square */
  overflow: hidden;
  border: 10px solid black;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  .event-image-container {
    padding-bottom: 56.25%; /* (9 / 16) * 100% */
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      overflow-clip-margin: content-box;
      overflow: clip;
    }
  }
  .event-info {
    color: #fcfcfc;
    box-shadow: rgba(0, 0, 0, 0) 0px 8px 32px;
    padding-left: 10px;
    padding-top: 10px;
    grid-row: 2;
    backdrop-filter: contrast(0.7) brightness(0.7);
  }
  // .eventDate-s {
  //   font-family: "helvetica";
  // }
  .eventTitle {
    word-wrap: break-word;
    font-weight: 800;
    text-align: center;
    color: #fefefe;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    backdrop-filter: blur(100px);
    background: rgba(0, 0, 0, 0.3);
  }

  .eventTotalInterested {
    font-family: "roboto-slab";
    font-weight: 800;
    font-size: 0.75rem;
  }

  .eventLocation {
    font-family: "helvetica";
    font-weight: 500;
  }
  #locationPlace {
    font-family: "helvetica";
    font-weight: 800;
  }
  .button-container {
    grid-row: 3; /* Moves button container to the second row */
    display: grid; /* Use grid layout inside the button container */
    place-items: center; /* Center the content horizontally and vertically */
  }
}
.eventDate-s {
  font-family: helvetica;
  font-weight: 600;
  line-height: 2.5;
  background: rgba(0, 0, 0, 0.3);
}
.location-remote-and-physical {
  font-weight: 800;
  font-size: 0.75rem;
  padding-top: 20px;
}

.only-remote {
  font-style: italic;
  font-weight: 600 !important;
}

.in-person {
  font-style: italic;
  font-weight: 600 !important;
  font-size: 0.75rem;
  padding-top: 20px;
}
