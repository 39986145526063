body {
  margin: 0 !important;
  overflow-x: hidden;
}

.fill-line {
  position: relative;
  z-index: 1;
}
.gift-card-carousel {
  // Your specific styles for gift card dashboard
  background-color: rgba(0, 0, 0, 0.7);
  // Any other styles you need
}
.points-carousel {
  background-color: yellow;
}
.dashboard-gift-balance {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns children vertically */
  height: 100%; /* Adjust based on your needs */
}
.giftcard-center {
  display: flex;
  justify-content: center;
  /* margin-right: 32%; */
  padding-right: 70px;
}
.giftcard-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Aligns terms, share button, and gift card name */
  padding: 10px; /* Adjust as needed */
}
.giftBalancePos {
  position: relative;
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .giftcard-footer {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: flex-start; /* Align items to the start of the container */
    justify-content: center;
  }

  .giftcard-footer > * {
    margin-bottom: 10px; /* Add some space between items */
  }
}

.dashboard-gift-card {
  display: flex;
  justify-content: space-between; /* Space out left, center, and right sections */
  align-items: center; /* Vertically center the content */
}

.giftcard-center {
  flex-grow: 1; /* Take up the remaining space */
}

/* You might need to adjust the styling for the left and right sections to ensure they don't take too much space */
.giftcard-left,
.giftcard-right {
  position: relative;
  top: 23%;
}

.fill-line:before {
  height: 3px;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  z-index: -1;
}

/* all buttons */
button:disabled {
  transition: 0.4s;
}
/* back section starts */
.dashback {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
}

.dashback button {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-left: 80px;
  cursor: pointer;
}


.diffNextStatus {
  color: #fefefe;
}

.terms {
  margin-right: 85%;
  @media screen and (min-width: 300px) {
    margin-right: 0%;
  }
}
.dashboard-name {
  position: relative;
  top: 100px;
  white-space: nowrap;
  font-size: 1.4rem;
  right: 65%;
}

.terms a {
  font-size: 0.75rem;
  color: white;
  @media screen and (min-width: 875px) {
    font-size: 1rem;
  }
}
.giftcard-avail-content {
  font-size: 0.75rem;
  // position: relative;
  // right: 40%;
  @media screen and (min-width: 875px) {
    font-size: 1rem;
  }
}

.dashboard-bottom-content-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.expiring {
  margin-top: 73% !important;
}
.gift-card-bottom-extra-container {
  display: none;
}
.posbtnleft {
  margin-top: 60%;
}
.points-balace-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  transition: 2s cubic-bezier(0, 0.88, 0, 0.91) transform;
  box-sizing: border-box;
}
#level_status {
  position: relative;
}

#redeem_modal {
  z-index: 30;
}
.active {
  z-index: 1;
}
.points-header {
  font-size: 0.75rem;
  font-weight: 800;
  @media screen and (min-width: 875px) {
    font-size: 2rem;
    white-space: nowrap;
  }
}

.points-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 0 50px;
  overflow: auto;
}

.info-flex {
  font-size: 0.75rem;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  position: relative;
  top: 50%;
}

.info-flex img {
  @media screen and (min-width: 875px) {
    width: 30px;
  }
}

.AvailPntsBal > p {
  font-size: 0.75rem;
  position: relative;
  left: 50%;
  @media screen and (min-width: 875px) {
    text-align: center;
    font-size: 1rem;
    left: 0px;
  }
}

.AvailPntsBal-figure {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    font-size: 3rem;
  }
}

.points-top > div > a {
  color: #fff;
}

.redeem-disabled {
  background-color: transparent;
  color: #b2bec3;
  border: 1px solid #b2bec3;
  border-radius: 4px;
  padding: 10px 60px;
  cursor: not-allowed;
}

.arrows {
  display: flex;
  justify-content: space-between;
}

.arrows > img {
  cursor: pointer;
}
.points-right {
  position: relative;
  top: 116px;
  right: 40%;
  @media screen and (min-width: 475px) {
    top: 0px;
    right: 0px;
  }
}
.points-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 50px;
  overflow: hidden;
}

.Points-escrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40%;
}

.expirepoints {
  margin-top: 70%;
}
.marg-bottom {
  margin-top: 5%;
  display: block;
  text-align: right;
}
.member-status > p {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    font-size: 1.3rem;
    margin-bottom: 5%;
  }
}

/* MODAL STARTS */

.redeem-modal {
  display: flex;
}

.redeem-modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease 0.06s;
}

.redeem-modal-content {
  font-size: 0.75rem;
  padding-left: 20%;
  padding-right: 20%;
  height: 30%;
  @media screen and (min-width: 875px) {
    margin: 20px auto;
    padding: 1rem;
    max-width: 50%;
    height: 40%;
    visibility: hidden;
    box-shadow: 2px 2px 10px 0px rgba(99, 106, 119, 0.6);
    border-radius: 5px;
  }
}

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(4%);
  }

  65% {
    transform: translateY(-2%);
  }

  80% {
    transform: translateY(2%);
  }

  95% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  35% {
    transform: translateY(-2%);
  }
  50% {
    transform: translateY(4%);
  }
  100% {
    transform: translateY(100%);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content > hr {
  height: 1px;
  width: 55%;
  background-color: #016dda;
}

.modal-logo {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.range-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.range {
  position: relative;
  height: 3px;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pseudo-range {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: #f18401;
}

#sliderRange > input[type="range"] {
  -webkit-appearance: none;
  height: 3px;
  border-radius: 4px;
  outline: none;
  background: #ccc;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #f18401;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  cursor: pointer;
}

.modal-content > .apply {
  display: flex;
  justify-content: center;
}

.apply-btn {
  border: 1px solid #f08400;
  color: #006fdb;
  background-color: #fff;
  padding: 10px 40px;
  cursor: pointer;
}



#apply-redeem-btn > a {
  text-decoration: none;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MODAL ENDS */

/* reward-levels start */
.points {
  font-size: 0.75rem;
  padding-top: 10px;

  @media screen and (min-width: 345px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 450px) {
    font-size: 1rem;
  }
}

.reward-level-flex {
  display: flex;
  justify-content: space-around; /* Adjust this to 'space-around' or 'space-between' */
  padding: 0 20px; /* Adjust padding as necessary */
}

.levels > h1 {
  font-size: 1.3rem;
  color: #e2b645;
  font-family: "Bree Serif", serif;
  @media screen and (min-width: 875px) {
    font-size: 2.5rem;
  }
}

.levels > div {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr); // Two columns with equal width
}

.levels > div > p {
  display: flex;
  gap: 10px;
}

.levels img {
  width: 30px;
}

.earnings > h1 {
  font-size: 1.3rem;
  color: #e2b645;
  font-family: "Bree Serif", serif;
  @media screen and (min-width: 875px) {
    font-size: 2.5rem;
    margin: 35px 0 0;
  }
}

.earnings > div {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.earnings > div > p {
  display: flex;
  gap: 10px;
  color: #fefefe;
}

.earnings img {
  width: 30px;
}

/* reward-levels end */

/* card starts */

.member-reward-status-card-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.member-reward-status-card {
  height: 160px;
  padding: 30px 40px;
  border: 1px solid #000;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.member-reward-status-card-icons {
  display: flex;
  justify-content: space-between;
}

.icon-numbers {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-numbers > p:nth-child(1) {
  margin-right: 22px;
}

.icon-numbers > p:nth-child(2) {
  margin-right: 15px;
}

.member-reward-status-card-div button {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border: 1px solid #f08400;
    border-radius: 30px;
    padding: 10px 60px;
    cursor: pointer;
    font-size: 20px;
  }
}
.member-reward-status-card {
  color: #fefefe;
}



/* card ends */

/* redemption benefits start */

/* .redemption > div{
    
} */

.redemption > h1 {
  font-size: 0.75rem;
  color: #e2b645;
  font-family: "Bree Serif", serif;
  @media screen and (min-width: 875px) {
    font-size: 2.5rem;
    margin: 35px 0 0;
    margin-left: 8%;
  }
}

.redemption > div {
  @media screen and (min-width: 875px) {
    align-items: center;
  }
}

.redemption > div > p {
  font-size: 0.75rem;
  display: flex;
  align-items: center; // This will vertically align the SVG and text
  gap: 10px;

  @media screen and (min-width: 875px) {
    font-size: 2rem;
  }
}

.redemption > div > p > img {
  width: 25px;
}

.redemption > div > div {
  margin-left: 40px;
}

.redemption > div > div > p {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    line-height: 2;
    margin-left: 10%;
  }
}

/* redemption benefits end */

/* FAQ starts */

.faq {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    margin: 100px 80px;
    display: grid;
    grid-gap: 20px;
    margin-left: 15%;
    font-size: 1.5rem;
  }
}

.conditions {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  text-decoration-color: #fdfd96;
  margin: 80px;
  gap: 3px;
  font-size: 20px;
}

.conditions a {
  color: #fdfd96;
}

.text {
  margin: 90px;
  text-align: center;
  font-size: 20px;
}

/* FAQ ends */

/* -------------------slider----------------- */

.icon-numbers > p {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    font-size: 0.875rem;
    color: #000;
  }
}

.icon-numbers {
  width: 100%;
}
.underline-gold {
  text-decoration: underline;
  text-decoration-color: #e2b645;
}

.dashboardSlider {
  position: relative;
  max-width: 900px;
  margin: 0;
  margin-left: 15%;
  box-sizing: border-box; // Ensures padding does not affect the overall width
  padding: 10px;
  @media (max-width: 768px) {
    width: 95%;
    margin-left: 2.5%;
  }
}
.GiftCardBal > p {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    text-align: center;
    font-size: 2rem;
    width: 300px;
    font-weight: 600;
  }
}
#GiftCardBalance {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    font-size: 3rem;
  }
}

.giftCardBalanceMiddle {
  margin-left: 75px;
}

.dollarIcon {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 7px;
}

.GiftCardBalanceLeft {
  width: 12px;
}

.Right,
.Left {
  cursor: pointer;
}

.GiftCardBalanceRight {
  width: 12px;
  margin-bottom: 13px;
  margin-left: 5px;
}

.nameOflevel {
  align-items: center;
}

.arrows {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    height: 4rem;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
  }
}

.levels > div {
  color: #fefefe;
}

.textforI {
  height: 25px;
  width: 25px;
  vertical-align: middle;
}

/* status indicator  */
/* -------------------------------#Change------------------------------- */
.wrapperProgress {
  position: absolute;
  width: 100%;
  // width: calc(100% - 24px);
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.linearLine {
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1.5s ease;
  background: #e2b645;
}
#sliderRange {
  width: 96%;
}
.statusShow {
  display: block;
  margin: 0px;
}
.contentAllBars {
  text-align: center;
}
/* ------------------------------#Change-------------------------- */
.member-reward-status-card-icons {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.member-reward-status-card-icons::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  width: 100%;
  background: #000;
  transform: translateY(-50%);
  z-index: -1;
  transition: 0.4s ease;
}

/* ---------------------------#Change-------------------------------- */
.statusIndicator {
  position: absolute;
  top: -50px;
  height: 25px;
}

.upArrow {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    height: 5rem;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0%;
    transform: translateY(-50%);
    z-index: -1;
    transition: 3.5s ease;
    width: 94%;
    margin-left: 3%;
    box-sizing: border-box;
  }
}

/* ----------------------------#Change------------------------------ */
.lineForIndicate {
  --larger-screen-additional-dis-100: 0;
  --larger-screen-additional-dis-80: 0;
  --larger-screen-additional-dis-60: 0;
  --larger-screen-additional-dis-40: 0;
  position: absolute;
  top: 50%;
  left: 13.5px;
  height: 4px;
  width: 0%;
  transform: translateY(-50%);
  z-index: -1;
  transition: 1.5s ease;
  background: #f08400;
}

.statusImg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 20px;
}

.footer-top-left {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.redeem_amount_color {
  transition: 0.1s ease;
}


/* ------------redeem popup design -----------*/

.showAmount {
  width: 51%;
  margin: auto;
}

#showTotalAmuntBreak {
  width: 70%;
  margin: auto;
  background: #fff5ef;
  border: 1px solid #006fdb;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.countAmount {
  @media screen and (min-width: 875px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.5rem;
    gap: 5px;
    width: 100%;
    cursor: pointer;
  }
}

.range-flex {
  width: 36%;
  margin: auto;
  border: 1px solid #006fdb;
}

.Dollar-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* ----scroll bar ----------- */

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #e2b645;
  border-radius: 5px;
}

/* slider arrows */
.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10%;
}

.arrow-btn {
  top: 55%;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.next {
  // float: right;
  right: 5%;
}
.prev {
  left: 5%;
}
// section#giftCard {
//     height: 275px;
// }
.redeem_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.redeem_modal.redeem_modal_open {
  transform: translateY(0);
}

/* MODAL STARTS */

.redeem-modal {
  display: flex;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-content > hr {
  height: 1px;
  width: 55%;
  background-color: #016dda;
}

.modal-logo > h1 {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    font-family: "Bree Serif", serif;
    color: #6a6a6a;
  }
}

.modal-logo > img {
  width: 40px;
}

.modal-text {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    text-align: center;
    font-size: 17px;
  }
}

#RdmPopup {
  font-size: 1.3rem;
  display: block;
  @media screen and (min-width: 875px) {
    font-size: 2rem;
  }
}

.modal-content > .apply {
  display: flex;
  justify-content: center;
}

.redeem-disabled {
  background-color: transparent;
  color: #b2bec3;
  border: 1px solid #b2bec3;
  border-radius: 4px;
  padding: 10px 60px;
  cursor: not-allowed;
}

/* The Close and Cancel Button */

.close-redeem-modal {
  color: #f1cb55;
  padding: 10px;
  text-align: right;
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 4%;
  z-index: 20;
}

#reedmHdr {
  margin-top: 5px;
  color: #e2b645;
  font-size: 1rem;
  display: block;
  font-weight: 800;
  @media screen and (min-width: 875px) {
    font-size: 3rem;
  }
}

.redeem-modal-header {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    display: flex;
    flex-direction: row-reverse;
    font-size: 2em;
    font-weight: bold;
  }
}

.redeem-modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease 0.06s;
}

.redeem-modal-content {
  align-items: center;
  -webkit-animation-duration: 0.9s;
  -webkit-animation-name: slideDown;
  -webkit-animation-timing-function: ease;
  backdrop-filter: blur(100px);
  border: 1px solid #707070;
  box-shadow: 2px 2px 10px 0 rgba(99, 106, 119, 0.6);
  margin: 20px auto;
  border-radius: 10px;
  margin-top: 10%;
}

#redeemInput {
  display: flex;
  width: 100%;
  align-items: center;
}

#redeemInput > input {
  font-size: 0.75rem;
  width: 100%;
  border: 1px solid #e2b645;
  background: transparent;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000;
  color: #fff;
  flex: 1 1;
  height: 30px;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 2rem;
  outline: none;
}

#redeemInput > .triplet-zeros-for-redeem {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    font-size: 1.2rem;
    position: relative;
    left: -15px;
    user-select: none;
    display: none;
  }
}

.redeemBtnErrorMessage {
  display: none;
  padding: 0 10px;
  color: #fe3b4e;
}

.RdmInptTitle {
  font-family: Nunito;
  font-weight: 800;
}




#apply-redeem-btn {
  font-size: 0.75rem;
  @media screen and (min-width: 875px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b3f62 !important;
    width: 100%;
    padding: 10px;
    font-size: 3rem;
    color: white;
    border: 5px solid #f1cb55;
    cursor: pointer;
  }
}

#apply-redeem-btn:disabled {
  color: #707070 !important;
  border-color: #a5a7aa;
  border: 5px solid;
  cursor: no-drop;
}
.levels {
  line-height: 2.5;
}
.earnings {
  line-height: 2.5;
}
