#groups-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  .input-container input {
    font-size: 1.3rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 1px 5px black;
    background: transparent;
    border-color: #e2b645;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 350px) {
    .input-container {
      .zipcode-input {
        &::placeholder {
          font-size: 1rem;
        }
      }
    }
  }
  .pos-cancel-search {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 350px) {
      display: grid;
      grid-template-columns: auto auto; // Two columns, one for input and one for button
    }
  }
  .Clear-Group-City-Search {
    position: absolute;
    right: 10px; // Adjust to align the button within the input
    top: 50%;
    transform: translateY(-50%); // Vertically center the button
    cursor: pointer;
  }
  .Clear-Group-ZipCode-Search {
    position: absolute;
    right: 10px;
    top: 20%;
    transform: translateY(-50%);
    cursor: pointer;
    @media screen and (min-width: 351px) {
      top: 40%;
      right: calc(
        50% + 12px
      ); // Adjusts dynamically based on screen width so I don't have too many break points incrementing 10points each time, easy formula
    }
  }

  .Clear-Group-Title-Search {
    position: absolute;
    right: 10px; // Adjust to align the button within the input
    top: 20%;
    transform: translateY(-50%); // Vertically center the button
    cursor: pointer;
    /* Additional styling for the clear button */
  }
  .groups-main-content {
    display: flex;
    flex-direction: column;
    .groups-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 5px;
      justify-content: center; /* Horizontally center the items */
      align-items: center;
      margin: 0 auto; /* Center the entire flex container on the page */
    }
  }
}
.group-search-container {
  position: relative; /* Ensures children are positioned relative to this container */
}
.toggle-filters {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  background-color: rgba(0, 0, 0, 0.7333333333);
  justify-content: center; /* Centers the button horizontally */
  backdrop-filter: blur(2000px);
  position: sticky;
  z-index: 5; /* Ensures it stays above the CategoryContainer and under the hamburger open menu */
  top: 50px; /* has to be a higher spacing because of objects above set at 100px to stick from the header */
  @media (min-width: 811px) {
    cursor: pointer;
    z-index: 10; /* Ensures it stays above the CategoryContainer and under the hamburger open menu */
    position: fixed;
    top: 15%;
    left: 10%;
    z-index: 0;
  }
}

// .cancel-categories-btn {
//   position: sticky;
//   z-index: 5; /* Ensures it stays above the CategoryContainer and under the hamburger open menu */
//   top: 300px; /* has to be a higher spacing because of objects above set at 200px to stick from the header */
// }
.CategoryContainer {
  margin-top: 10px; /* how far it is from the cancel button when it opens*/
}
.group-list-search {
  background-color: rgba(0, 0, 0, 0.7333333333);
  backdrop-filter: blur(200px);
  z-index: 10;
  position: sticky;
  top: 100px;
  @media (min-width: 811px) {
    padding-top: 50px;
    cursor: pointer;
    position: fixed;
    top: 22%;
    left: 3%;
    z-index: 10;
  }
}

.zip-code-error-message {
  color: red; /* Error message color */
  position: absolute;
  font-family: "roboto-slab";
  font-weight: 900;
  bottom: 2%;
  font-size: 0.9rem; /* Font size for the error message */
}
.hide-chat-animation {
  @media (max-width: 811px) {
    display: none; /* Hide the Chat Animation on small screens */
  }
}
.hidden {
  display: none;
}
.group-follow-options-filter-container {
  display: flex;
}
.followers-group-active,
.Following-group-active {
  color: #e2b645;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Helvetica;
}

.followers-group-inactive,
.Following-group-inactive {
  color: #fdfd96;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Helvetica;
}

.group-categories-search-container {
  position: relative; /* Ensures relative positioning context */
  .cancel-categories-btn {
    position: absolute;
    top: 100px; /* Adjust this value so the button does not overlap with the header */
    z-index: 20; /* Make sure this is higher than the CategoryContainer but lower than the header's z-index */
  }
  .CategoryContainer {
    position: absolute;
    z-index: 20; /* Ensure this is above the page content but below the cancel button and header */
    bottom: -100px; /* Position it below the header; adjust as needed */
    // transform: translateX(-0%); /* Center the container horizontally */
    /* The max-widths are already defined in your media queries */

    @media screen and (min-width: 300px) {
      max-width: 300px;
    }
    @media screen and (min-width: 350px) {
      .container {
        max-width: 300px;
      }
    }
    @media screen and (min-width: 400px) {
      .container {
        max-width: 350px;
      }
    }
    @media screen and (min-width: 450px) {
      .container {
        max-width: 400px;
      }
    }
    @media screen and (min-width: 500px) {
      .container {
        max-width: 450px;
      }
    }
    @media screen and (min-width: 550px) {
      .container {
        max-width: 500px;
      }
    }
    @media screen and (min-width: 600px) {
      max-width: 564px;
    }
    @media screen and (min-width: 650px) {
      .container {
        max-width: 608px;
      }
    }
    @media screen and (min-width: 700px) {
      max-width: 652px;
    }
    @media screen and (min-width: 750px) {
      .container {
        max-width: 696px;
      }
    }
    @media screen and (min-width: 800px) {
      .container {
        max-width: 740px;
      }
    }
    @media screen and (min-width: 811px) {
      max-width: 750px;
    }
  }
}
