.custom-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    font-size: 1.3rem;
    font-weight: 800;
    -webkit-text-stroke: 0.5008px black;
    font-family: "Roboto Slab";
    padding-right: 30px;  // Moved padding to the right


    .checkbox-label {
      margin-right: 10px;  // Add some space between the label text and the checkbox visuals
    }

    .checkmark-container {
      position: relative;
      width: 24px;
      height: 24px;
      border: 2px solid #e2b645;
      background: transparent;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: none; // Default hide the checkmark
      }
    }
  }

  // Show the checkmark when the checkbox is checked
  input[type="checkbox"]:checked + label .checkmark-container svg {
    display: block;
  }
}

.custom-checkbox, .custom-checkbox label {
  cursor: pointer;
}
