.billing-country-input,
.billing-zipcode-input,
.billing-city-input,
.billing-address-line2-input,
.billing-address-line1-input,
.billing-last-name-input,
.billing-first-name-input,
.billing-expiry-input,
.billing-credit-card-input,
.input-container .focused-input {
  box-shadow: 0 1px 5px black;
  border-color: transparent;
}
.billing-country-input,
.billing-zipcode-input,
.billing-city-input,
.billing-address-line2-input,
.billing-address-line1-input,
.billing-last-name-input,
.billing-first-name-input,
.billing-expiry-input,
.billing-credit-card-input,
.input-container input {
  font-size: .75rem;
  font-weight: 600;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 1px 5px black;
  background: transparent;
  border-color: #e2b645;
  margin-top: 10px; /* Increased from 10px */
  margin-bottom: 10px; /* Increased from 10px */
}
.billing-country-input,
.billing-zipcode-input,
.billing-city-input,
.billing-address-line2-input,
.billing-address-line1-input,
.billing-last-name-input,
.billing-first-name-input,
.billing-expiry-input,
.billing-credit-card-input,
input::placeholder {
  font-size: .75rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #cecfd1;
  // -webkit-text-stroke: 0.5008px #e2b645;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.invoice-modal {
  width: 80%; // or any desired width
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: red;
}
.invoice-content {
  color: "red";
}
#select-tier {
  padding-top: 10px;
}

.miniDashboard .positionUnlockContent {
  position: relative;
  right: 55px;
}

/* Styling for texts */
.black {
  margin: 0;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  -webkit-text-stroke: 0.5008px black;
  font-family: "Roboto Slab";
}

.weight700 {
  font-weight: 700;
}
.mrg-top {
  margin-top: 5px;
}
