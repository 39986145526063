.button {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  text-align: center;
  text-decoration: underline;
  text-shadow: 0 0 black;
  padding: 0.5em 2em;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  outline: 0;
  line-height: 20px;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  border-color: #ffff;
  // top: 15px;
  &:active {
    transition: none !important;
  }
  /* Media query for screen widths between 965px and 1023px */
  @media (min-width: 768px) {
    top: -10px;
  }
}

.glass {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  border-image: none;
  border-style: double;
  border-width: 1px;
}

.glassref {
  position: relative;
  cursor: pointer;
  top: -85px;
  width: 200px;
  height: 35px;
  border-radius: 6px;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 10%,
    rgba(255, 255, 255, 0.1) 48%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.glasshi {
  &:before {
    position: absolute;
    content: "";
    top: -2px;
    left: 10%;
    width: 36px;
    height: 3px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.99) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: "";
    top: -2px;
    left: calc(10% + 16px);
    width: 4px;
    height: 2px;
    background-color: #fff;
    box-shadow: 0 0 8px 3px rgba(255, 255, 255, 0.99);
    z-index: 1;
  }
}

// set on the global
#hi2 {
  transform: rotate(90deg) translate(-40px, 100px);
  z-index: 9;
}

#hi3 {
  transform: rotate(90deg) translate(-40px, -100px);
  z-index: 9;
}

.sparkle {
  #hi2 {
    /* Default styles for the smallest screens */
    transform: rotate(90deg) translate(-40px, 100px);

    /* Styles for screens wider than 400px */
    @media (min-width: 400px) {
      transform: rotate(90deg) translate(-40px, 100px);
    }

    /* Styles for screens wider than 768px */
    @media (min-width: 768px) {
      transform: rotate(90deg) translate(-40px, 100px);
    }
  }

  #hi3 {
    /* Default styles for the smallest screens */
    transform: rotate(90deg) translate(-40px, -100px);

    /* Styles for screens wider than 400px */
    @media (min-width: 400px) {
      transform: rotate(90deg) translate(-40px, -100px);
    }

    /* Styles for screens wider than 768px */
    @media (min-width: 768px) {
      transform: rotate(90deg) translate(-40px, -100px);
    }
  }
}

.glasshover {
  position: relative;
  top: -30px;
  left: -1px;
  width: 198px;
  height: 45px;
  line-height: 36px;
  border-radius: 6px;
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0;
  box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.5);

  transition: all 0.3s ease-in-out;
  z-index: 2;
}

div:hover.glasshover {
  opacity: 1;
}
.content {
  display: flex;
  align-items: center; // vertically aligns the icon with the text
  justify-content: center; // centers them horizontally within the button

  .icon-container {
    margin-right: 10px; // provides a small space between the icon and the text
  }
}
