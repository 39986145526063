#current-day-calendar {
  font-family: "Istok Web", sans-serif;
  width: 130px;
  height: 130px;
  font-size: 20px;
  // margin: 60px auto;
  background: white;
  box-shadow: 0px 5px 5px #222, -5px 7px 0px 3px #726a57,
    -12px 13px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 1px;
  // transform: rotate(-20deg); nice style may add l8terssss somewhere else
  @media (max-width: 811px) {
    // too much relestate taking up on smaller screens
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    border-left: 200px solid transparent;
    // border-bottom: 30px solid rgba(0, 0, 0, 0.1); this works when it is tilted
    bottom: 0px;
  }

  .month-section {
    width: 100%;
    background: linear-gradient(to right, #a32929, #ff6666 75%);
    height: 40px;
    color: white;
    box-shadow: 0px 5px 5px #ddd;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 13px;
      top: 5px;
      border-radius: 50%;
      background: #4d0000;
      box-shadow: 60px 0px 0px #4d0000;
    }

    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 20px;
      background: #555;
      border-radius: 20% 20% 0 0;
      left: 15px;
      top: -12px;
      box-shadow: 0px -2px 0px #777, -1px 0px 2px #777, 0px 3px 0px #4d0000,
        60px 0px 0px #555, 60px -2px 0px #777;
    }

    .month-name {
      padding-left: 10px;
      font-size: 15px;
      letter-spacing: 2px;
      position: absolute;
      bottom: 0;
      text-transform: uppercase;
    }
  }

  .day-section {
    display: flex;
    flex-direction: column;

    .day-as-text {
      color: #2d2d2d;
      display: flex;
      font-weight: 700;
      justify-content: center;
    }

    .day-as-number {
      text-align: center;
      font-size: 50px;
      line-height: 1;
      color: #2d2d2d;
      font-weight: 700;
    }
  }
}
