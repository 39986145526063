// classes
// glow-on-animation : this is the root class.
// glow-on-by-default: use this class to show animation without any user event press hover etc.
// glow-on-hover: use this class when need to show animation on hover
// glow-on-press: use to show animaiton when the button is pressed or clicked.
// animation-5s: see below to all the class related to animation speed give it increase or decrease the speed of animation duration

/* glow-on-btn-transparent: use this class when you have button with transparent background for example
   view more/less button on the parent pages */

.glow-on-animation {
  cursor: pointer;
  position: relative !important;
  z-index: 0;
  // three buttons
  // transition: all .5s linear;
  // border-radius: 10px;
}

/* -----------------------------
 * Class: .glow-on-glass-animation
 * Description: This class provides the glowing effect specifically designed for the glass button.
 * ----------------------------- */


// using "before" to show the animation colors outside the buttons like a border
// using opacity property to show hide this "before"
.glow-on-glass-animation:before {
  content: "";
  background: linear-gradient(
    45deg,
    #cb8836,
    #faf9f5,
    #cb8836,
    #ffe39d,
    #cb8836,
    #faf9f5,
    #cb8836,
    #faf9f5,
    #cb8836,
    #faf9f5,
    #cb8836,
    #ffe39d,
    #cb8836,
    #faf9f5,
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(5px);
  animation: glowing linear infinite; // play in a continous loop
  animation-duration: 20s;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: inherit;
}
.glow-on-btn-with-blur:before {
  /* 
   * 'content' property is used to generate content in a ::before or ::after pseudo-element, 
   * setting it to an empty string so it can have dimensions and other properties.
   */
  content: "";

  /* Defining the gradient for the glow effect. */
  background: linear-gradient(
    45deg,
    #f08400,
    #fff,
    #016dda,
    #fff,
    #f08400,
    #fff,
    #016dda,
    #fff,
    #f08400
  );

  /* Positioning the pseudo-element to be absolute to the button, 
   * so it overlays perfectly over the button.
   */
  position: absolute;

  /* Adjusting the position slightly to ensure the glow appears outside the button. */
  top: -2px;
  left: -2px;

  /* Setting up the glowing animation */
  animation: glowing linear infinite;
  animation-duration: 20s;
  /* Initially, we're setting the glow to be invisible. 
   * It will become visible on specific actions/events.
   */
  opacity: 0;

  /* Smooth transition for opacity changes. */
  transition: opacity 0.3s ease-in-out;

  /* Ensuring the glow effect inherits the border radius of the button. */
  border-radius: inherit;

  /* The background-size property sets the size of the element's background. 
   * In this case, it ensures our gradient pattern is expanded to enhance the glowing effect.
   */
  background-size: 400%;

  /* Setting the z-index to -1 ensures our glow appears behind the actual content of the button. */
  z-index: -1;
}

// animation colors for search button on banner
.glow-on-rainbow-animation:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing linear infinite;
  animation-duration: 20s;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: inherit;
}

// animation colors for search button on banner
.glow-on-search-banner-btn:before {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing linear infinite;
  animation-duration: 20s;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: inherit;
}

// animation colors for view more/less button
.glow-on-view-more-less-btn:before {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  animation: glowing linear infinite;
  animation-duration: 10s;
  background-size: 400%;
}
.glow-on-quick-search {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  animation: glowing linear infinite;
  animation-duration: 10s;
  background-size: 400%;
}

.glow-on-orange-tab {
  overflow: inherit !important;
  &:before {
    background: linear-gradient(
      45deg,
      #346cb4,
      #ff7300,
      #006dda,
      #bbd0f0,
      #f08400
    );
    // animation: glowing linear infinite;
    animation: unset;
    animation-duration: 15s;
    background-size: 400%;
    opacity: 1;
  }
}
.glow-on-blue-tab {
  overflow: inherit !important;
  &:before {
    background: linear-gradient(
      45deg,
      #ff0000,
      #006fdb,
      #bbd0f0,
      #002bff,
      #bbd0f0,
      #ff0000
    );
    // animation: glowing linear infinite;
    animation: unset;
    animation-duration: 20s;
    background-size: 400%;
    overflow: inherit !important;
    opacity: 1;
  }
}

.play-animation:before {
  animation: glowing linear infinite;
  animation-duration: 20s;
  opacity: 1;
}
.play-animation:before {
  opacity: 1;
}
.play-animation {
  color: #fff !important;
}
.play-animation:after {
  background: transparent !important;
}

.glow-on-blue-color:before {
  background: linear-gradient(
    45deg,
    #bbd0f0,
    #006dda,
    #bbd0f0,
    #006dda,
    #bbd0f0,
    #006dda,
    #bbd0f0,
    #006dda,
    #bbd0f0
  );
  animation: glowing linear infinite;
  animation-duration: 20s;
  background-size: 400%;
}

.glow-on-learn-more-btn:before {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #006dda,
    #ff00c8,
    #ff0000
  );
  animation: glowing linear infinite;
  animation-duration: 20s;
  background-size: 400%;
}
.glow-on-animation:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // default background
  background: inherit;
  // Note: if the button has background class it out and give the "after" the same color in this way we can simulate the button press and outer border animtaion
  left: 0;
  top: 0;
  border-radius: inherit;
}

// this is an example class if the button has transparent background color copy the body background color and give it to "after" for example viewless/more button on the parent pages
.glow-on-btn-transparent:after {
  background: #f0f6ff;
}
// use this class name to show animation by default without any button press or hover
.glow-on-by-default:before {
  opacity: 1;
}
// use this class name to show animation on active means when button is pressed.
// this will make the bacground of the button transparnt and will use to show the "before element" which contains the animaiton colors.
.glow-on-press:active {
  color: #fff !important;
}
.glow-on-press:active:after {
  background: transparent;
}
.glow-on-press:active:before {
  opacity: 1;
}
//
.glow-on-press-red-text:active {
  color: red !important;
}

.show-animation:before {
  opacity: 1;
}

// class to control animation speed
.animation-5s:before {
  animation-duration: 5s;
}
.animation-10s:before {
  animation-duration: 10s;
}

.animation-15s:before {
  animation-duration: 15s;
}

.animation-20s:before {
  animation-duration: 20s;
}

// animations
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
