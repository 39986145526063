:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  min-width: 100%; /* Change 100vw to 100% */
  min-height: 100%; /* Change 100vh to 100% */
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
h1 {
  font-size: 3.2rem; /* Changed em to rem for font-size */
  line-height: 1.1;
  margin: 0; /* Add margin reset for h1 */
}

html {
  background-color: whitesmoke;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, -apple-system, BlinkMacSystemFont, 'Courier New',
    monospace, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html {
  background-color: whitesmoke;
}
