.comment-block {
  margin-bottom: 20px;
}
.post-details-container {
  padding-bottom: 160px;
}
.comment-top-level {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.comment-top-level .comment-username,
.thread-reply .thread-username {
  font-weight: bold;
}
.comment-username {
  position: relative;
}
.comment-username::before {
  content: "-";
  position: absolute;
  left: -10px;
  top: 0;
}
.userNameCommentWrapper {
  flex-direction: column;
  border: 1px solid #e2b645;
  box-shadow: 0 1px 5px black;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 4px 4px 4px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}
.userNameCommentWrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 10px 10px 0; /* Adjust for size */
  border-color: transparent #e2b645 transparent transparent; /* The second color is the color of the triangle */
}
.comment-top-level .comment-text {
  margin-left: 20px;
}

.thread-replies {
  margin-left: 40px; /* Indent replies further from the top-level comment */
}

.thread-reply {
  margin-top: 10px; /* Space out the thread replies */
  margin-left: 5%;
}

.thread-reply .thread-username {
  font-size: 0.9rem;
  color: #e2b645;
}

.thread-reply .thread-text {
  margin-left: 20px; /* Indent thread text under the user name */
}

.thread-username {
  /* Your existing styles for username */
  position: relative;
}
.thread-username::before {
  content: "-";
  position: absolute;
  left: -10px; /* Adjust as needed */
  top: 0;
}
.userThreadBubblePosition {
  display: flex;
}
.userNameThreadWrapper {
  flex-direction: column;
  border: 1px solid #e2b645;
  box-shadow: 0 1px 5px black;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 4px 4px 4px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}
.userNameThreadWrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px; /* Adjust as necessary */
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 10px 10px 0; /* Adjust for size */
  border-color: transparent #e2b645 transparent transparent; /* The second color is the color of the triangle */
}
.Express-Respons-btns {
  display: flex;
  align-items: center; /* Align items vertically center */
  gap: 10px; /* Space between items */
}
.media-section {
  /* Optional: Specify a margin if you want to control the spacing */
  // margin-right: 20px; /* Adjust the spacing to the right */
  padding-left: 5%;
  padding-right: 5%;
  object-fit: contain;
  display: block;
  background: linear-gradient(
    to bottom,
    #2c3e50,
    /* Dark color at the top */ #bdc3c7
      /* Lighter color at the bottom, create a file that analzyes the process color and show the dominate color from top down to enahnce the mood for version 1 this works */
  );
  @media (min-width: 768px) {
    width: 100%;
    padding: 0px 32px 24px;
}
  img {
    width: 100%;
    object-fit: fill; /* or 'cover' if you want to maintain aspect ratio */
    object-position: center center;
  }
}
@media (min-width: 768px) {
  /* This is for larger screens */
  .post-details-container {
    display: flex; /* Use flexbox to create a row */
  }
}

// To write the CSS rule for setting the max-height of an element by subtracting
// the height of the UserCommentBox and additional offsets from 100% of the
// viewport height, you can use the calc() function in CSS. Assuming the
// height of the UserCommentBox is 250px and you have additional offsets
// to consider, you can replace [Height of UserCommentBox] and
// [Additional Offsets] with their respective values. If, for example,
// the additional offsets amount to 50px, the rule would be written as:
.comments-container {
  max-height: calc(100vh - 250px - 50px); /* Adjust the value as needed */
  overflow-y: auto; /* Enables scrolling */
}
.comment-and-discussion-section {
  width: 100%;
  overflow: hidden auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
  position: relative;
  @media (min-width: 768px) {
    /* This is for larger screens */
  padding: 0px 32px 24px;
}
}