.white-gold-plain-btn {
    cursor: pointer;
    height: 50px;
    background-color: #fff;
    display: flex;
    border-radius: 25px;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
    align-items: center;
    justify-content: center;
    border: 1px solid #e2b645;

    /* Your additional custom styles can go here */
}
