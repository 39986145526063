.receipt-card {
    background-color: #ffffff; // white background
    border: 1px solid #ccc;    // grayish border
    margin-bottom: 16px;       // spacing between cards
    padding: 16px;             // padding inside each card
    border-radius: 5px;        // optional, for rounded corners

    .receipt-info {
        margin-bottom: 12px;
    }

    .receipt-info>p{
        color:#000;
        font-size:1rem;
    }

    // Add any other stylings as necessary for the receipt card content and actions
}
