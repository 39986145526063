.group-card {
  box-shadow: inset 0 -3px 8px rgba(0, 0, 0, 0.6),
    inset 0 3px 8px rgba(252, 255, 255, 0.7), 0 3px 8px -3px rgba(0, 0, 0, 0.8);
  border: 5px solid #c79f27;
  border-radius: 50px;
  width: 280px; // Min width for very small screens
  overflow: hidden;
  cursor: pointer;
  @media (min-width: 811px) {
    max-width: 350px;
  }
  p {
    font-size: 0.75rem;
  }
  h1 {
    word-wrap: break-word;
    font-weight: 800;
    text-align: center;
    color: #fefefe;
    -webkit-font-smoothing: antialiased;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background: rgba(0, 0, 0, 0.3);
  }

  .group-card-top-section {
    border-radius: 8px 8px 0 0;
    height: 150px;
    width: 100%;

    img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }

  .group-card-bottom-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0) 0px 8px 32px;
    backdrop-filter: contrast(0.7) brightness(0.7);

    .groupTitle {
      font-family: "roboto-slab";
      font-weight: 800;
      font-size: 1rem;
      text-align: center;
    }

    .gpInterestedPost {
      display: flex;
      flex-direction: row;
      font-family: "helvetica";
      font-size: 0.75rem;
      font-weight: 600;
      color: #fefefe;
      align-self: start;
      -webkit-backdrop-filter: contrast(0.7) brightness(0.7);
      backdrop-filter: contrast(0.7) brightness(0.7);
      box-shadow: rgba(0, 0, 0, 0) 0px 8px 32px;
    }

    .connection-info {
      display: flex;
      gap: 5px;
      padding-left: 12px; // offset the negative margin that allows thumbnails to overlap.
      font-weight: 600;
      color: #fcfcfc;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 32px;
      backdrop-filter: contrast(0.7) brightness(0.7);

      .profile-thumbnails {
        display: flex;

        .profile-thumbnail {
          width: 30px;
          height: 30px;
          border-radius: 99999px;
          overflow: hidden;
          margin-left: -12px; /* Add negative margin to overlap the pictures */

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
