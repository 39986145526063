.solid-color-btn {
  margin: 20px auto;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  height: 40px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 0 #cecfd1;
  transition: all 0.2s ease;
  font-weight: 800;
  text-shadow: 0 1px #fff;
  animation: active 0.9s alternate infinite;

  &.gold-btn {
    animation: animation-gold 0.9s alternate infinite;
    background-image: linear-gradient(
      to top,
      #f3cf71 20%,
      #fff 112%,
      #e2b645 100%
    );
    color: white;
    min-width: 100px;
  }

  @keyframes animation-gold {
    from {
      box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
        0 -6px 4px #f6f0dc, inset 0 0 10px 0px #e2b645;
    }
    to {
      box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
        0 -6px 4px #f6f0dc, inset 0 0 3px 3px #e2b645;
    }
  }

  .position-relative {
    position: relative;
  }
}

// .solid-color-btn {
//     margin: 20px auto;
//     -webkit-user-select: none;
//     user-select: none;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     outline: none;
//     cursor: pointer;
//     height: 40px;
//     background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
//     border-radius: 30px;
//     box-shadow: 0 4px 3px 1px #fcfcfc,
//                 0 6px 8px #d6d7d9,
//                 0 -4px 4px #cecfd1,
//                 0 -6px 4px #fefefe,
//                 inset 0 0 3px 0 #cecfd1;
//     transition: all 0.2s ease;
//     font-weight: 800;
//     text-shadow: 0 1px #fff;
//     animation: active 0.9s alternate infinite;

//      &.brown-btn {
//         animation: animation-brown 0.9s alternate infinite;
//         background-image: linear-gradient(to top, #964b00 20%, #fff 112%, #964b00 100%) !important;
//         color: white;
//         min-width: 100px;
//     }

//     @keyframes animation-brown {
//         from {
//           box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
//             0 -6px 4px #fefefe, inset 0 0 10px 0px #964b00;
//         // box-shadow: inset 0 0 10px 0px #c79f27;
//         }
//         to {
//           box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
//             0 -6px 4px #fefefe, inset 0 0 3px 3px #964b00;
//         }
//       }

//     .position-relative {
//         position: relative;
//     }
// }

// &.blue-btn {
//     animation: animation-signup-blue 0.9s alternate infinite !important;
//     background-image: linear-gradient(to top, #233f5f 20%, #fff 112%, #233f5f 100%) !important;
//     color: white;
//     min-width: 100px;
// }
