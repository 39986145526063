$emoji-base-skin-color: #ffda6a;
$emoji-emotion-love-color: #f55064;
$emoji-emotion-angry-color: #d5234c;
$emoji-black-color: #000;
$emoji-dark-blue: #233f5f;
$emoji-light-blue: #016dda;
$emoji-blue: #0000ff; /* New variable for blue */

/* Base Emoji Style */
.emoji-angry-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color; /* Initial background color */
  position: relative;
  display: inline-block;
  margin: 5px;
  animation: angry-transition 3s infinite ease-in-out; /* Apply the animation */
  transition: background-color 1s ease-in-out; /* Smooth transition for background change */
}
/* Define Keyframes for Color Transition */
@keyframes angry-transition {
  0%,
  100% {
    background-color: $emoji-base-skin-color; /* Start and end with base skin color */
  }
  50% {
    background: linear-gradient(
      $emoji-emotion-angry-color,
      $emoji-base-skin-color
    ); /* Mid-point with gradient to angry color */
  }
}

.emoji-angry-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji--angry-eyebrows {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 3px;
    background: $emoji-black-color;
    border-radius: 50%;
  }
  &:before {
    left: -12px;
    transform: rotate(45deg);
  }
  &:after {
    right: -12px;
    transform: rotate(-45deg);
  }
}
.emoji-eye {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $emoji-black-color;
  &.emoji-eye-left {
    left: calc(50% - 12px);
    top: 40%;
  }
  &.emoji-eye-right {
    right: calc(50% - 12px);
    top: 40%;
  }
}

.emoji-angry-mouth {
  position: relative;
  width: 12px;
  height: 2px;
  top: 5px;
  background: #000000;
  border-radius: 50%;
  animation: angry-mouth 2s ease-in infinite;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: angry-mouth;
  animation-timeline: auto;
  animation-range-start: normal;
}

@keyframes angry-mouth {
  25%,
  50% {
    height: 6px;
    bottom: 25px;
  }
}
.emoji-angry-expression {
  // Add animation to the inner part of the emoji
  animation: angry-face 2s ease-in infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

@keyframes angry-face {
  35%,
  60% {
    transform: translateX(0) translateY(10px) scale(0.9);
  }
  40% {
    transform: translateX(-5px) translateY(10px) scale(0.9);
  }
  45% {
    transform: translateX(5px) translateY(10px) scale(0.9);
  }
  50% {
    transform: translateX(-5px) translateY(10px) scale(0.9);
  }
  55% {
    transform: translateX(5px) translateY(10px) scale(0.9);
  }
}

.emoji-serious-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px; // Spacing between emojis
}
.emoji-serious-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emoji-serious-eyebrows {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 3px;
    background: $emoji-black-color;
    border-radius: 50%;
  }
  &:before {
    left: -12px;
    transform: rotate(-45deg);
  }
  &:after {
    right: -12px;
    transform: rotate(45deg);
  }
}
.emoji-serious-mouth {
  position: relative;
  width: 12px;
  height: 2px;
  top: 5px;
  background: #000000;
  border-radius: 50%;
  animation: angry-mouth 2s ease-in infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: angry-mouth;
  animation-timeline: auto;
  animation-range-start: normal;
}

@keyframes serious-mouth {
  25%,
  50% {
    height: 6px;
    bottom: 25px;
  }
}
.emoji-serious-expression {
  // Add animation to the inner part of the emoji
  animation: serious-face 2s ease-in infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
@keyframes serious-face {
  0% {
    top: 0;
  }

  50% {
    top: 6px;
  }
  100% {
    top: 0;
  }
}
.emoji-wow-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px; // Spacing between emojis

  .emoji-wow-expression {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .emoji-wow-eyebrows {
    display: inline-block;
    margin: 0px 10px;
    position: relative;
    width: 20px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid $emoji-black-color;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: wow-brow 3s linear infinite;
    &.emoji-wow-eyebrow-right {
      right: 4px;
      top: 25%;
      position: absolute;
    }
    &.emoji-wow-eyebrow-left {
      left: 4px;
      top: 15%;
      position: absolute;
    }
  }

  .emoji-wow-eyes {
    width: 6px;
    height: 6px;
    top: 35px;
    border-radius: 50%;
    background-color: $emoji-black-color;
    &.emoji-wow-eye-left {
      position: absolute;
      top: 35%;
      right: 15%;
    }
    &.emoji-wow-eye-right {
      position: absolute;
      top: 35%;
      left: 25%;
    }
  }
  .emoji-wow-mouth {
    position: relative;
    width: 10px;
    height: 8px;
    top: 15px;
    left: 20%;
    transform: translate(-50%, -50%);
    background: $emoji-black-color;
    border-radius: 50%;
    animation: wow-mouth 5s ease-in infinite;
    transition: all 3s ease-in-out; /* Smooth transition for background change */
  }
  .emoji-wow-expression {
    // Add animation to the inner part of the emoji
    animation: wow-face 4s linear infinite;
    transition: all 3s ease-in-out; /* Smooth transition for background change */
  }
  @keyframes wow-face {
    15%,
    25% {
      transform: rotate(20deg) translateX(-10px);
    }
    45%,
    65% {
      transform: rotate(-20deg) translateX(5px);
    }
    75%,
    100% {
      transform: rotate(0deg) translateX(0);
    }
  }
  @keyframes wow-mouth {
    0%,
    100% {
      width: 10px;
      height: 10px; // Initial state, mouth closed
    }
    10%,
    30% {
      width: 12px;
      height: 14px; // Slightly open on the left
    }
    50% {
      width: 10px;
      height: 16px; // More open in the middle
    }
    70% {
      width: 12px;
      height: 5px; // Slightly open on the right
    }
  }
  @keyframes wow-brow {
    25%,
    65% {
      top: 5px;
    }
    75%,
    100%,
    0% {
      top: 8px;
    }
  }
}
.emoji-teethy-laugh-expression {
  // Add animation to the inner part of the emoji
  animation: haha-face 2s linear infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
.emoji-teethy-laugh-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px;
  animation: rock-and-bounce 4s linear infinite; // Apply the rocking and bouncing animation
}

@keyframes rock-and-bounce {
  // for teethy smile
  0%,
  50%,
  100% {
    transform: translateY(0) rotate(0); // Start, halfway, and end in original position
  }
  6%,
  18%,
  31% {
    transform: translateY(10px) rotate(-10deg); // Lower bounces with left tilt
  }
  12%,
  25%,
  37% {
    transform: translateY(5px) rotate(10deg); // Mid-way up with right tilt
  }
  56%,
  68%,
  81% {
    transform: translateY(-10px) rotate(10deg); // Higher bounces with right tilt
  }
  62%,
  75%,
  87% {
    transform: translateY(-5px) rotate(-10deg); // Mid-way down with left tilt
  }
}

.emoji-teethy-laugh-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Keyframes for eyebrow animation
@keyframes raise-eyebrows {
  0%,
  100% {
    top: 15%; // Original position
  }
  50% {
    top: 10%; // Eyebrows raised higher
  }
}

.emoji-teethy-laugh-eyebrows {
  position: absolute;
  animation: arch-eyebrows 2s ease-in-out infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 20px; // Width of the eyebrow
    height: 10px; // Height to allow for border-radius curvature
    border: 2px solid $emoji-black-color; // Thinner border
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-left-radius: 20px; // Curvature for the arch
    border-top-right-radius: 20px;
  }
  &:before {
    left: -3px;
  }
  &:after {
    right: -3px;
  }
}
@keyframes arch-eyebrows {
  0%,
  100% {
    top: 15%;
  }
  50% {
    top: 10%; // Raise the eyebrows for expression
  }
}
@keyframes squint-eyes {
  0%,
  100% {
    height: 6px; // Original height
    top: 40%; // Original position
  }
  50% {
    height: 1px; // Reduced height
    top: 38%; // Slightly higher position
  }
}
.emoji-teethy-laugh-eye {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $emoji-black-color;
  animation: squint-eyes 2s ease-in-out infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */

  // Position each eye
  &.emoji-teethy-laugh-eye-left {
    left: calc(50% - 12px);
    top: 40%;
  }
  &.emoji-teethy-laugh-eye-right {
    right: calc(50% - 12px);
    top: 40%;
  }
}

.emoji-teethy-laugh-mouth {
  position: relative;
  width: 20px;
  height: 15px;
  left: calc(50% - 20px);
  top: 30%;
  background: #fff;
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  z-index: 1;
  animation: haha-mouth 1s linear infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: angry-mouth;
  animation-timeline: auto;
  animation-range-start: normal;
}

// for potential laughter with teeth and wow
@keyframes teethy-laugh-mouth {
  0%,
  100% {
    height: 20px; // Initial height
    bottom: 20px; // Initial position from the bottom
  }
  50% {
    height: 20px; // Increased height at the peak of the animation
    bottom: 18px; // Slightly higher position at the peak
  }
}
.emoji-toungue-laugh-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px;
}

.emoji-toungue-laugh-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji-toungue-laugh-eye {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $emoji-black-color;
  background-color: $emoji-black-color;
  @keyframes transform-eye {
    0%,
    100% {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
    50% {
      width: 10px;
      height: 2px;
      border-radius: 0;
    }
  }
  // Position and rotate each eye
  &.emoji-toungue-laugh-eye-left {
    left: calc(50% - 12px);
    top: 40%;
    animation: transform-eye 2s infinite;
    transition: all 3s ease-in-out; /* Smooth transition for background change */
  }
  &.emoji-toungue-laugh-eye-right {
    right: calc(50% - 12px);
    top: 40%;
    animation: transform-eye 2s infinite;
    transition: all 3s ease-in-out; /* Smooth transition for background change */
  }
}
.emoji-toungue-laugh-expression {
  // Add animation to the inner part of the emoji
  animation: toungue-laugh-face 3s linear infinite;
}

.emoji-toungue-laugh-mouth {
  position: relative;
  width: 30px;
  height: 20px;
  left: calc(50% - 20px);
  top: 30%;
  background: $emoji-black-color;
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  z-index: 1;
  animation: toungue-laugh-mouth 1s linear infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
  animation-duration: 8s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: toungue-laugh-mouth;
  animation-timeline: auto;
  animation-range-start: normal;
  .emoji-tongue {
    position: absolute;
    background: $emoji-emotion-love-color;
    border-radius: 50%;
    width: 60px;
    height: 20px;
    left: calc(50% - 35px);
    bottom: -10px;
    border-radius: 50%;
  }
}

@keyframes toungue-laugh-face {
  10% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(-10px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-5);
  }
  70% {
    transform: translateY(-3px);
  }
  80% {
    transform: translateY(-5);
  }
  90% {
    transform: translateY(-10px);
  }
}

@keyframes toungue-laugh-mouth {
  10% {
    transform: scale(0.5);
    top: 45%;
  }
  20% {
    transform: scale(0.8);
    top: 45%;
  }
  30% {
    transform: scale(0.1);
    top: 45%;
  }
  40% {
    transform: scale(0.8);
    top: 45%;
  }
  50% {
    transform: scale(0.9);
    top: 45%;
  }
}

.emoji-annoyed-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color; // Use your color variable
  position: relative;
  display: inline-block;
  margin: 5px;
}

.emoji-annoyed-eyes {
  position: relative;
  width: 12px; // Eye width
  height: 12px; // Eye height
  border-radius: 50%;
  background-color: #ffffff; // White background for the eye
}

#annoyed-left-eye {
  left: calc(50% + 3px);
  top: 30%;
}

#annoyed-right-eye {
  right: calc(50% - 25px);
}
.pupil {
  position: absolute;
  width: 4px; // Pupil width
  height: 4px; // Pupil height
  border-radius: 50%;
  background-color: black; // Pupil color
  top: 4px; // Center pupil vertically
  left: 4px; // Center pupil horizontally
  transform-box: fill-box;
  transform-origin: center;
  animation: rotate 3s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
.eyelid {
  position: absolute;
  width: 12px; // Same as eye width
  height: 4px; // Eyelid height
  background-color: $emoji-base-skin-color; // Same as face color
  top: 0; // Position at the top of the eye
  border-radius: 12px 12px 0 0; // Rounded top
  z-index: 2; // Above the pupil
}

#left-eyelid {
  left: 0; // Align with left eye
}

#right-eyelid {
  left: 0; // Align with right eye
}

@keyframes rotate {
  // roll eyes
  0%,
  100% {
    transform: translate(0, 0);
  } // Center
  20% {
    transform: translate(4px, -4px);
    opacity: 0;
  } // Top-right (behind eyelid)
  40% {
    transform: translate(3px, 3px);
    opacity: 1;
  } // Bottom-right
  60% {
    transform: translate(-3px, 3px);
    opacity: 1;
  } // Bottom-left
  80% {
    transform: translate(-4px, -4px);
    opacity: 0;
  } // Top-left (behind eyelid)
}
.emoji-annoyed-mouth {
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: black;
  top: 150%;
  left: 120%;
  border-radius: 2px;
}

/* Example keyframes for a slight mouth movement */
@keyframes annoyed-mouth-movement {
  0%,
  100% {
    transform: translateX(-50%) rotate(-2deg);
  }
  50% {
    transform: translateX(-50%) rotate(-12deg); // Slightly more downturned
  }
}

.emoji-annoyed-mouth {
  animation: annoyed-mouth-movement 5s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
.emoji-sad-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $emoji-base-skin-color; // replace with the actual color variable
  position: relative;
  display: inline-block;
  margin: 5px;
}

.emoji-sad-eye {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 20%; // Adjust to place the eyes appropriately
  border-radius: 50%;
  box-shadow: 0 0 0 0.9px rgba(34, 34, 34, 0.05);
}

#sad-left-eye {
  left: 20%; // Adjust to position the left eye
  animation: tear-well-up-right 3s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
#sad-right-eye {
  right: 20%; // Adjust to position the right eye
  animation: tear-well-up-left 3s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.emoji-sad-pupil {
  position: absolute;
  width: 4px; // Pupil width
  height: 4px; // Pupil height
  border-radius: 50%;
  top: 3px; // Center pupil vertically within the eye
  left: 3px; // Center pupil horizontally within the eye
}

/* Keyframes for the left eye - tears welling up */
@keyframes tear-well-up-left {
  0%,
  100% {
    background: $emoji-base-skin-color;
  }
  50% {
    background: linear-gradient(35deg, #964b00 65%, rgba(255, 0, 0, 0) 35%);
  }
}

/* Keyframes for the right eye - tears welling up */
@keyframes tear-well-up-right {
  0%,
  100% {
    background: $emoji-base-skin-color;
  }
  50% {
    background: linear-gradient(-35deg, #964b00 65%, rgba(255, 0, 0, 0) 35%);
  }
}
/* Keyframes for the tears falling */
@keyframes tear-fall {
  0%,
  20%,
  100% {
    opacity: 0; // Tear is invisible
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(15px);
  }
  80% {
    opacity: 0;
    transform: translateY(25px);
  }
}

/* Tears falling animation remains the same */
#sad-left-eye::after,
#sad-right-eye::after {
  /* ... existing styles ... */
  animation: tear-fall 1s infinite;
}
/* Apply the tear-fall animation to the pseudo-element after the eyes */
#sad-left-eye::after,
#sad-right-eye::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 6px;
  background-color: #b4d9f8;
  border-radius: 50%;
  opacity: 0;
  animation: tear-fall 3s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
/* Ensure the tears are timed correctly with the tear-well-up animation */
#sad-left-eye::after {
  animation-delay: 0.5s; /* half the duration of the tear-well-up to sync with the peak */
}

#sad-right-eye::after {
  animation-delay: 0.5s; /* half the duration of the tear-well-up to sync with the peak */
}
.emoji-sad-face {
  /* ... existing styles ... */
  animation: sad-face 3s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.emoji-sad-face-expression {
  // Add animation to the inner part of the emoji
  animation: sad-expression 4s linear infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
@keyframes sad-face {
  0% {
    top: 0;
  }

  50% {
    top: 6px;
  }
  100% {
    top: 0;
  }
}
@keyframes sad-expression {
  25%,
  35% {
    top: -15px;
  }
  55%,
  95% {
    top: 10px;
  }
  100%,
  0% {
    top: 0;
  }
}
.emoji-sad-mouth {
  position: absolute;
  width: 40px;
  height: 80px;
  left: calc(50% - 21px);
  box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 50%;
  right: -20px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  background: transparent;
  animation: sad-mouth 2s ease-in infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
@keyframes sad-mouth {
  25%,
  35% {
    transform: scale(0.85);
    top: 20px;
  }
  55%,
  100%,
  0% {
    transform: scale(1);
    top: 25px;
  }
}
.emoji-mouth:after {
  width: 6px;
  height: 6px;
  background: transparent;
  border-radius: 50%;
  top: 4px;
  left: calc(50% - 3px);
  box-shadow: -18px 0 0 0 #000000, 18px 0 0 0 #000000;
}
.emoji-sad-mouth:after {
  position: absolute;
  content: "";
}

.emoji-zipper-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px; // Spacing between emojis
}
.emoji-zipper-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zipper {
  position: absolute;
  bottom: 25%;
  left: 20%;
  height: 4px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  /* Adjust the left position to start earlier */
  left: calc(20% - (5% / 2)); /* Start halfway before the first track */
  /* Adjust the width to extend further */
  width: calc(60% + 5%); /* Total width plus one track width */
}

.zipper-track {
  width: 6%; // Adjust this value so all tracks fit within the 60% width of the zipper
  height: 100%;
  background-color: $emoji-black-color;
  transform: scaleY(0);
  transform-origin: bottom;
}

@keyframes appear {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.zipper-track {
  animation: appear 3s ease infinite var(--delay);
}
.zipper-line {
  position: absolute;
  bottom: 2%; /* Align with the zipper */
  width: 0; /* Start with no width */
  height: 4px; /* Same height as the zipper */
  background-color: grey; /* Grey color for the zipper line */
  transform-origin: left; /* Grow from the left */
  animation: grow-line 3s ease infinite; /* Animation to grow the line */
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.zipper-pull {
  position: relative;
  bottom: 4%;
  transform: translateX(-10%) translateY(55%);
  width: 12px; // Smaller width
  height: 16px; // Smaller height
  background: radial-gradient(
    transparent 0,
    transparent 0.25em,
    #fff 0.275em,
    #fff 0.2925em,
    #333333 0.325em,
    #333333 100%
  );
  border-radius: 2.5px 2.5px 7.5px 7.5px; // Adjusted border radius
  border: 1px solid #ffffff; // Thinner border
  box-shadow: 0 0.5px 2.5px 0px #00000080; // Adjusted box-shadow
  z-index: 2; // Ensure it's above other elements
}

.zipper-pull-attacher {
  position: absolute;
  z-index: -4; /* This should be less than the .zipper-pull's z-index */
  left: 50%;
  transform: translateX(17%) translateY(-19%);
  width: 8px; // Diameter of the steel-looking circle
  height: 8px; // Height equal to the width for a perfect circle
  //     background-color: grey; // Color of the pull tab
  background-color: #bbb; // Steel-like color
  border-radius: 50%; // Makes it a circle
  box-shadow: 0 0 2px black; // Add a shadow for depth
  z-index: 1;
}
.zipper-pull-hook {
  position: absolute;
  z-index: -1; /* This should be less than the .zipper-pull's z-index */
  right: 20%;
  transform: translateX(-10%) translateY(50%);
  width: 1px;
  height: 5px;
  background: #333;
  display: block;
  border-radius: 10px;
  border: 1px solid #fff;
  z-index: 2;
}
@keyframes grow-line {
  to {
    width: 100%; /* Fill the entire width of the .zipper container */
  }
}

@keyframes slide-pull {
  from {
    transform: translateX(-50%) translateY(55%); /* Starting position */
  }
  to {
    transform: translateX(8%) translateY(55%);
  }
}

@keyframes slide-attacher {
  from {
    transform: translateX(-50%) translateY(-19%); /* Starting position */
  }
  to {
    transform: translateX(50%) translateY(-19%); /* Ending position */
  }
}

@keyframes slide-hook {
  from {
    transform: translateX(-50%) translateY(50%); /* Starting position */
  }
  to {
    transform: translateX(50%) translateY(50%); /* Ending position */
  }
}

.zipper-pull {
  animation: slide-pull 3s ease infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.zipper-pull-attacher {
  animation: slide-attacher 3s ease infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.zipper-pull-hook {
  animation: slide-hook 3s ease infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}
.emoji-zipper-face-eye {
  position: absolute;
  width: 10px; /* Adjust size as needed */
  height: 10px;
  border-radius: 50%;
  top: 20%; /* Position of eyes */
  box-shadow: 0 0 0 0.9px rgba(34, 34, 34, 0.05);
}

.emoji-zipper-face-eye-left {
  left: 20%; /* Position of left eye */
  animation: zipper-face-eyes-blink 2s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.emoji-zipper-face-eye-right {
  right: 20%; /* Position of right eye */
  animation: zipper-face-eyes-blink 2s infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

/* Keyframes for the left eye - tears welling up */
/* Keyframes for the left eye - tears welling up */
@keyframes zipper-face-eyes-blink {
  0%,
  100% {
    background: $emoji-base-skin-color;
  }
  50% {
    background: linear-gradient(
      360deg,
      $emoji-light-blue 65%,
      rgba(255, 0, 0, 0) 35%
    );
  }
}

/* Keyframes for the right eye - tears welling up */
@keyframes zipper-face-eyes-blink {
  0%,
  100% {
    background: $emoji-base-skin-color;
  }
  50% {
    background: linear-gradient(
      -360deg,
      $emoji-light-blue 65%,
      rgba(255, 0, 0, 0) 35%
    );
  }
}

.emoji-yay-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px;

  /* Add blush on cheeks */
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: transparent; /* Ensuring the background is transparent */
    /* Soft, diffused blush effect using box-shadow */
    box-shadow: 0 0 8px 4px $emoji-emotion-love-color;
  }
  &::before {
    left: 10%; /* Adjust position for left blush */
    top: 55%;
  }

  &::after {
    right: 10%;
    top: 55%;
  }
}
@keyframes yay-expression {
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(0deg) translateY(-10px); /* Tilt up */
  }
}

.emoji-yay-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: yay-expression 1s linear infinite alternate;
}
/* Adding blush to the cheeks */

.emoji-yay-eye {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $emoji-black-color;
  animation: squint-eyes 2s ease-in-out infinite;
  transition: all 3s ease-in-out; /* Smooth transition for background change */

  // Position each eye
  &.emoji-yay-eye-left {
    left: calc(50% - 12px);
    top: 40%;
  }
  &.emoji-yay-eye-right {
    right: calc(50% - 12px);
    top: 40%;
  }
}
.emoji-yay-mouth {
  top: 60px;
  background: transparent;
  left: 50%;

  border: 2px solid #000;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transform: rotate(180deg);
  width: 40px;
  height: 20px;
}
.emoji-heart-eyes-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $emoji-base-skin-color;
  position: relative;
  display: inline-block;
  margin: 5px;
}
.emoji-heart-eyes-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: heart-beat 1s linear infinite alternate;
}

.emoji-heart-eye {
  position: absolute;
  top: 10px; /* Adjust as needed */
  font-size: 18px; /* Adjust the size of the heart emoji */
  color: $emoji-emotion-love-color; /* Color of the heart */
  animation: heart-beat 1s linear infinite alternate;
  transition: all 3s ease-in-out; /* Smooth transition for background change */
}

.emoji-heart-eye-left {
  left: 3px;
  content: "";
  top: 0%;
}

.emoji-heart-eye-right {
  right: 3px;
  content: "";
  top: 0%;
}

@keyframes heart-beat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
  }
}

/* New styles for the mouth */
.emoji-heart-eyes-mouth {
  width: 25px; /* Adjusted size for the emoji */
  height: 12.5px; /* Adjusted size for the emoji */
  border: 1.5px solid black; /* Adjusted thickness for the emoji */
  position: absolute;
  top: 75%; /* Adjust to position the mouth */
  left: 50%; /* Center the mouth horizontally */
  transform: translate(-50%, -50%); /* Centering adjustment */
  border-radius: 0 0 60px 60px; /* Makes the bottom of the mouth rounded */
  background-color: lightpink; /* Pink color for the mouth */
  box-shadow: 0px 0px 4px black;
}

/* Keyframes for the mouth pulse animation */
@keyframes pulse-mouth {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1); /* Start and end with no scaling */
    border-width: 2.5px; /* Original border width */
  }
  50% {
    transform: translate(-50%, -50%) scale(0.9); /* Slightly scale down the mouth */
    border-width: 5px; /* Make the border thicker */
  }
}
.emoji--heart-mouth-kiss {
  content: "";
  position: absolute;
  top: 50%;
  right: 35%;
  width: 19px;
  height: 17px;
  clip-path: polygon(
    49% 0%,
    100% 10%,
    65% 48%,
    100% 74%,
    51% 100%,
    77% 72%,
    57% 46%,
    81% 13%
  );
  border-radius: 50%;
  background-color: #492a0d; /* Adjust color as needed */
}

@keyframes heart-beat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
  }
}
.emoji-heart-eyes-face {
  position: relative;
}

.floating-heart {
  font-size: 24px; /* Adjust the size of the floating heart */
  color: $emoji-emotion-love-color; /* Color of the floating heart */
  position: absolute;
  animation: float-and-rotate 3s ease-in-out infinite;
  top: 11px;
  right: -7px;
}

@keyframes float-and-rotate {
  0%,
  100% {
    opacity: 1; /* Make sure the heart is visible at the start */
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    opacity: 1; /* Heart is still visible while starting to rotate */
    transform: translate(0, -10px) rotate(45deg); /* Rotate to 45 degrees */
  }
  50% {
    opacity: 0; /* Heart disappears mid-way */
    transform: translate(0, -20px) rotate(45deg);
  }
  75% {
    opacity: 0; /* Keep the heart invisible */
    transform: translate(0, -10px) rotate(45deg);
  }
}

.emoji--heart-mouth-kiss {
  position: absolute;
  bottom: 10%; /* Adjust as needed for placement */
  animation: kiss 2s ease-in-out infinite;
}

.emoji-wink-heart-kiss-face {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $emoji-base-skin-color; /* Standard emoji yellow */
  position: relative;
  display: inline-block;
  margin: 5px;
}

.emoji-wink-heart-kiss-expression {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji-wink-eye {
  position: absolute;
  background: #000;
  border-radius: 50%;
}

.emoji-wink-eye-left {
  top: 35%;
  left: 25%;
  width: 5px;
  height: 5px; /* A small dot to represent the closed winking eye */
  animation: wink-eye 2s ease-in-out infinite;
}

.emoji-wink-eye-right {
  top: 35%;
  right: 25%;
  width: 5px;
  height: 5px; /* A small dot to represent the closed winking eye */
}

.emoji--heart-mouth-kiss {
  position: absolute;
  bottom: 20%;
  left: 30%;
  width: 12px;
  height: 8px;
  background: #000;
  border-radius: 50%;
}

.floating-heart {
  position: absolute;
  top: 0;
  right: -5px;
  animation: float-and-rotate 2s ease-in-out infinite;
}

@keyframes float-heart {
  0%,
  100% {
    transform: translate(0, -10px);
  }
  50% {
    transform: translate(0, 0);
  }
}
@keyframes kiss {
  0%,
  100% {
    transform: scaleX(1.3); /* No horizontal scaling */
  }
  50% {
    transform: scaleX(1.5) rotate(-10deg); /* Tilt upward */
  }
}
/* Define the wink animation */
@keyframes wink-eye {
  0%,
  100% {
    transform: scaleY(1); /* Eye is open */
    opacity: 1; /* Eye is fully visible */
  }
  50% {
    transform: scaleY(0.1); /* Eye is almost closed */
    opacity: 0.6; /* Eye is partially visible */
  }
}
.emoji-love-face {
  position: relative;
  width: 40px; /* Consistent size with other emojis */
  height: 40px;
  border-radius: 50%;
  background: $emoji-base-skin-color; /* Consistent background color */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.emoji-love-heart {
  font-size: 24px; /* Adjust size as needed */
  color: $emoji-emotion-love-color; /* Color of the floating heart */

}

/* Add other elements like eyes, eyebrows, etc. */

// change this to droopy eye and make it blink
/* Applying the animation to the mouth */
// .emoji-annoyed-mouth {
//   animation: annoyed-mouth-movement 5s infinite;
// }
// .emoji-sad-face {
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: $emoji-base-skin-color; // replace with the actual color variable
//   position: relative;
//   display: inline-block;
//   margin: 5px;
// }

// .emoji-sad-eye {
//   position: absolute;
//   width: 10px;
//   height: 10px;
//   background-color: #ffffff; // White part of the eye
//   border-radius: 50%;
//   top: 20%; // Adjust to place the eyes appropriately
//   transform: rotate(180deg); // Flip the eyelid to droop at the top
// }

// #sad-left-eye {
//   left: 25%; // Adjust to position the left eye
// }

// #sad-right-eye {
//   right: 25%; // Adjust to position the right eye
// }

// .emoji-sad-eyelid {
//   position: absolute;
//   width: 100%;
//   height: 50%;
//   background-color: $emoji-base-skin-color; // Same as face color for eyelid
//   bottom: 0;
//   border-radius: 100% 100% 0 0; // Semi-circle on the top

// }

// #sad-left-eyelid {
//   left: 0;
// }

// #sad-right-eyelid {
//   right: 0;
// }

// .emoji-sad-pupil {
//   position: absolute;
//   width: 4px; // Pupil width
//   height: 4px; // Pupil height
//   background-color: black; // Pupil color
//   border-radius: 50%;
//   top: 3px; // Center pupil vertically within the eye
//   left: 3px; // Center pupil horizontally within the eye
// }

// .emoji-teethy-laugh-face {
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: $emoji-base-skin-color;
//   position: relative;
//   display: inline-block;
//   margin: 5px;
//   animation: jolly-bounce 2s linear infinite; // Apply the bouncing animation
// }

// @keyframes jolly-bounce {
//   0%,
//   100% {
//     transform: translateY(0); // Start and end at the original position
//   }
//   10%,
//   30%,
//   50%,
//   70% {
//     transform: translateY(10px); // Downward bounce
//   }
//   20%,
//   40%,
//   60%,
//   80% {
//     transform: translateY(-10px); // Upward bounce
//   }
// }
