#group-detail-mb-pad-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  box-shadow: 0 1px 5px black;
  z-index: 5;
  padding-bottom: 60px;
  @media (min-width: 780px) {
    display: none; // hide for bigger screens
  }
}
.group-detail-mb-pad-nav button {
  margin: 5px;
  padding: 10px;
  width: 80%;
  border: none;
  border-radius: 5px;
}
.mb-pad-party-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.mb-pad-groups-navtop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Adds space between buttons when they wrap */
  width: 100%;
}
.group-mb-pad-connections-input-slider-container {
  margin-bottom: 10px;
  width: 80%;
  @media (min-width: 780px) {
    display: none;
  }
}
.members-section {
  z-index: 5;
}
.groups-cover-picture {
  position: relative;
  overflow: hidden;
  border: 3px solid black;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(
    to bottom,
    #2c3e50,
    /* Dark color at the top */ #bdc3c7
      /* Lighter color at the bottom, create a file that analzyes the process color and show the dominate color from top down to enahnce the mood for version 1 this works */
  );
  img {
    width: 100%;
    // max-height: 50vh;
    max-height: clamp(350px, 35vh, 500px);
    object-fit: fill; /* This will maintain the aspect ratio and fill the container */
    overflow-clip-margin: content-box;
    overflow: clip;
    object-position: center center;
    -webkit-tap-highlight-color: transparent;
  }
}
