.group-popup {
    position: fixed;
    overflow-y: auto; /* This will add a scrollbar when the content inside exceeds the container's height */
    overflow-x: hidden; /* This ensures there's no horizontal scrollbar */
    max-height: 90vh; /* This will ensure the popup doesn't take up the whole viewport height, you can adjust this value as needed */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    backdrop-filter: blur(100px) !important;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 0px rgba(99, 106, 119, 0.6); // Slight shadow for elevation
    z-index: 5000;
    width: 95%;
    max-width: 400px;
    .required-message::placeholder {
      color: red;
      font-size: 1.5rem;
      font-weight: 800;
      background: rgba(0, 0, 0, 0.3);
      font-family: "roboto-slab";
      -webkit-text-stroke: 0.5008px red;
    }
  }
  .group-popup p {
    font-size: 1.3rem;
    font-weight: 700;
    font-style: italic;
  }
  
  .header {
    height: 50px;
    background-color: rgba(255, 255, 255, 0.09) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    position: relative;
  }
  
  /* CSS for overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 5;
  }
  
  /* Apply a class to show the overlay */
  .overlay.active {
    display: block;
  }
  
  .datetime-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* in groupPopup.scss */
  .overlay-label {
    position: absolute;
    top: 5px;
    left: 5px;
    pointer-groups: none;
    opacity: 0.7;
    background-color: white;
    padding: 0 5px;
  }
  
  /* Styling for texts */
  .black {
    margin: 0;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 800;
    -webkit-text-stroke: 0.5008px black;
    font-family: "Roboto Slab";
  }
  
  .weight700 {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .mrg-top {
    margin-top: 5px;
  }
  
  /* Styling for focused inputs */
  .focused-input {
    box-shadow: 0px 1px 5px #000;
    border-color: transparent;
    background-color: #fff;
  }
  
  /* Styling for group name input's placeholder */
  .input-container input::placeholder {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: Helvetica;
    color: #ffff;
    -webkit-text-stroke: 0.5008px #e2b645;
  }
  
  /* More input stylings */
  .input-container .focused-input {
    background-color: white;
    box-shadow: 0 1px 5px black;
    border-color: transparent;
  }
  
  .input-container .focused-input::placeholder {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: Helvetica;
    color: inherit !important;
    -webkit-text-stroke: 0 !important;
  }
  
  .input-container input {
    font-size: 1.3rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 1px 5px black;
    background: transparent;
    border-color: #e2b645;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  /* Styling for description input */
  .description input {
    height: 100px;
  }
  
  /* Styling for datetime wrapper */
  .datetime-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: nowrap;
  }
  
  /* Styling for date-time-pair */
  .date-time-pair {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .checkbox-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .user-checkboxes {
    margin-top: 5px;
    padding-left: 15px; /* Some padding to show hierarchy */
  }
  /* Chrome, Edge, and Safari */
  .group-popup::-webkit-scrollbar {
    width: 10px;
  }
  
  .group-popup::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .group-popup::-webkit-scrollbar-thumb {
    background-color: #e2b645;
    border-radius: 5px;
  }
  
  /* Firefox */
  .group-popup {
    scrollbar-width: thin;
    scrollbar-color: #e2b645 transparent;
  }
  /* Input styles */
  .focused-input {
    color: #964b00;
    font-size: 1rem;
    font-weight: 600;
  }
  
  /* Clear button styles */
  .clear-button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 0px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 50px;
    display: table-cell;
    vertical-align: middle;
    border-radius: 25px !important;
    background: rgba(255, 255, 255, 0.09);
    font-weight: 700;
    font-family: "roboto-slab";
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    font-size: 1rem;
    border: 1px solid #fdfd96;
    color: #fdfd96;
    margin-top: 10px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
  }
  /* Container styles to position clear button correctly */
  .input-container {
    position: relative;
  }
  .input-with-value {
    color: #fdfd96;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-focused-value {
    color: #fefefe;
    border: 1px solid #964b00;
  }
  
  .input-container .input-focused-value + .clear-button {
    color: #964b00;
    border: 1px solid #964b00;
  }
  .visibility-container {
    display: flex;
    flex-direction: column;
  
    .private-section,
    .group-section {
      margin-bottom: 16px; // Gives some space between sections
    }
  }
  .group-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
    height: 100%;
    left: 0;
    overflow: scroll;
    position: fixed;
    top: 0;
    transition: all 0.5s ease 0.06s;
    width: 100%;
    z-index: 10;
    .header-text {
      -webkit-text-stroke: 0.5008px #fff;
      color: #fff;
      font-size: 2rem;
      font-weight: bolder;
      margin: 10px auto;
    }
  }
  
  .overlay-light {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .popup-width {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 90%;
    max-height: 100vh;
    overflow: auto;
    @media screen and (min-width: 768px) {
      width: 445px;
    }
  }
  
  .popup-width-center {
    right: 50%;
    transform: translateX(50%);
  }
  
  .receipt-text {
    padding: 20px 0;
    .receipt-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .receipt-key {
      color: #fff;
    }
    .receipt-value {
      text-align: right;
      display: inline-block;
      color: #f18400;
    }
  }
  .align-btn {
    text-align: right;
    margin: 10px 10px 10px auto;
  }
  .btn-cancel {
    color: #f18400;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .popup {
    .title-section {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      min-height: 39px;
      background-color: #233f5f;
      font-size: 20px;
      font-weight: 700;
    }
    .title-section-big {
      font-size: 26px;
      font-weight: 700;
    }
    .inner-section {
      padding: 20px 8px 24px;
    }
    .widget-text {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
      display: inline-block;
    }
  }
  
  /* Tablet Views */
  @media (min-width: 768px) {
    .group-popup {
      width: 90%;
      font-size: 16px;
    }
  }
  @media (min-width: 992px) {
    .group-popup {
      width: 80%;
      max-width: 500px;
      font-size: 18px;
    }
  
    .header {
      height: 60px;
    }
  }
  /* Mobile Views: Default settings without media query since mobile-first is a common approach */
  .group-popup .popup-content {
    padding: 20px;
    box-sizing: border-box;
  }
  
  .input-container,
  .blinker-wrapper,
  .description {
    width: 100%;
  }
  
  .datetime-wrapper,
  .date-time-pair,
  .visibility-container,
  .private-section,
  .group-section {
    flex-direction: column;
  }
  
  .WhiteGoldButton,
  .PlainBtn {
    width: 100%;
  }
  
  /* Tablet Views */
  @media (min-width: 768px) {
    .group-popup .popup-content {
      padding: 20px 40px;
    }
  }
  
  /* Desktop Views */
  @media (min-width: 992px) {
    .group-popup .popup-content {
      padding: 40px 60px;
    }
  }
  .required-message::placeholder {
    color: red;
    font-size: 1.5rem;
    font-weight: 800;
    background: rgba(0, 0, 0, 0.3);
    font-family: "roboto-slab";
    -webkit-text-stroke: 0.5008px red;
  }
