/* SettingsPage.css */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Some padding for aesthetics */
    width: 250px;
    padding-bottom: 80px;
    @media (min-width: 400px) {
      width:300px
    }
  }
  #update{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #update > .settings-container > div {
    width: 100%;
  }