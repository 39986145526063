.custom-radio {
  position: relative;
  display: inline-flex; // Flex container to layout the visual radio and label text.
  align-items: center;
  input[type="radio"] {
    display: none; // Hide the real radio button
  }

  label {
    display: flex;
    align-items: center;
    position: relative; // For the ::before and ::after positioning
    padding-left: 30px; // Space to the left for the circle visuals
    font-size: 1.5rem;
    font-weight: 800;
    -webkit-text-stroke: 0.5008px black;
    font-family: "Roboto Slab";

    // Outer circle visual
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #e2b645;
      background: transparent;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    // Inner circle visual for checked state
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 12%; // to center it
      width: 16px;
      height: 16px;
      background: #e2b645;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      opacity: 0; // By default, hide the inner circle
    }
  }

  // Show the inner circle when the radio is checked
  input[type="radio"]:checked + label::after {
    opacity: 1;
  }
}
.custom-radio, .custom-radio label {
  cursor: pointer;
}
  

