.sans-media-event-card {
  background: linear-gradient(120deg, #f6f0dc 0%, #e2b645 80%);
  box-shadow: inset 0 -3px 8px rgba(0, 0, 0, 0.6),
    inset 0 3px 8px rgba(252, 255, 255, 0.7), 0 3px 8px -3px rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  display: grid;
  grid-template-rows: 1fr 100px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border: 10px solid black;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  .sans-media-event-info {
    color: #000;
    display: grid; /* Use grid to center content */
    place-items: center; /* Center content */
    grid-row: 1; /* Occupies the first row */
    padding-top: 40px; /* Add padding at the top */
  }
  // .eventDate-s {
  //   font-family: "helvetica";
  // }
  .sans-media-eventTitle,
  .sans-media-eventTotalInterested,
  .sans-media-eventLocation {
    text-align: center; /* Center the text */
  }
  
  .sans-media-eventTitle {
    word-wrap: break-word;
    font-weight: 800;
    text-align: center;
    color: #000;
    background-color: rgba(235, 235, 235, .3);
    font-size: 1.2rem;
    -webkit-font-smoothing: antialiased;
    -webkit-backdrop-filter: contrast(0.7) brightness(0.7);
}



  .sans-media-eventTotalInterested {
    font-family: "roboto-slab";
    font-weight: 800;
    font-size: 1rem;
  }

  .sans-media-eventLocation {
    font-family: "helvetica";
    font-weight: 500;
  }
  #sans-media-locationPlace {
    font-family: "helvetica";
    font-weight: 800;
  }
  .sans-media-button-container {
    grid-row: 2; /* Button container in the second row */
    display: grid; /* Use grid layout inside the button container */
    place-items: center; /* Center the content horizontally and vertically */
  }
}
.sans-media-eventDate-s {
  font-family: helvetica;
  font-weight: 600;
  line-height: 1.5;
}
.sans-media-location-remote-and-physical {
  font-weight: 800;
  font-size: 1rem;
}

.sans-media-only-remote {
  font-style: italic;
  font-weight: 600 !important;
}

.sans-media-in-person {
  font-style: italic;
  font-weight: 800 !important;
  font-size: .75rem;
}
.sans-media-eventTotalAttending{
  font-weight: 800;
  font-size: .75rem;
}
.sans-media-spacer{
  display: inline-block; /* makes margin work as expected */
  margin: 0 15px; /* creates 25px margin on both sides */
}
