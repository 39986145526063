.character-error {
    color: red;
    font-size: 1rem;
    font-weight: 600;
    font-family: "roboto-slab";
  }
  /* Styling for the thumbnail */
  .LoggedinUserPostThumbnail {
    width: 40px; /* Adjust as required */
    height: 40px;
    top: 10px; /* Adjust to vertically center with the textarea */
    border-radius: 50%; /* This will make it circular */
    overflow: hidden; /* To make sure the image doesn't spill outside the div if it's not a perfect square */
    position: relative; /* This will allow us to place the thumbnail right in front of the input */
    top: 0px;
    z-index: 6; /* Makes sure it stays above the input */
  }
  .LoggedinUserPostThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the div fully */
  }
  .UserPostDetails-listItem {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally in a column flex container */
    justify-content: center; /* Centers items vertically in a column flex container */
    padding: 5px; /* Optional: Adds some spacing inside each list item */
    top: 50px;
  }
  .UserPostDetails-discussion-buttons {
    display: flex;
    list-style: none;
    z-index: 5;
  }
  
  .userComment-container textarea {
    resize: none;
    text-indent: 20px; /* Indents both placeholder and value */
    line-height: 1.5; /* Line height for the text */
    white-space: pre-wrap; /* Wrap text as in textarea */
    word-wrap: break-word; /* Ensure long words do not overflow */
    width: 100%;
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: #fefefe;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    &::placeholder {
      font-family: helvetica;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.5;
      color: #ffff;
      -webkit-text-stroke: 0.5008px #e2b645;
    }
  }
  .userComment-container {
    border: 1px solid #e2b645;
    box-shadow: 0 1px 5px black;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    border-radius: 4px; /* Rounded corners like textarea */
  }

  
  