#sign-up-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .full-name-section {
    display: flex;
    flex-direction: column; 
    width: 100%;

    .input-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: unset;
      min-width: unset;
    }
  }
  input::placeholder {
    color: #757575; /* Default placeholder color */
  }
  .red-placeholder::placeholder {
    color: red;
  }
  .custom-placeholder-color::placeholder {
    color: red;
  }


  hr {
    height: 1px;
    background-color: #cccccc;
    border: none;
    width: 85%;
  }
  .react-tel-input .form-control {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: gray;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: gray;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
    }
  }
  .show-hide-password-toggle {
    cursor: pointer;
    font-size: 0.75rem;
    color: #964b00;
    font-weight: 900;
    line-height: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    position: absolute;
    bottom: 5px;
    right: 2%;
    width: 45px;
  }
}
