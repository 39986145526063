.clear-color-btn {
    animation: animation-blue 0.9s alternate infinite !important;
    color: #964b00;
    margin: 20px auto;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    height: 40px;
    width: 150px;
    background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
    border-radius: 30px;
    box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1, 0 -6px 4px #fefefe, inset 0 0 3px 0 #cecfd1;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    font-weight: 800;
    text-shadow: 0 1px #fff;
}
