
.slide {
  position: relative;
  height: 50px;
  width: 290px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 32px;
  backdrop-filter: brightness(4) saturate(3) blur(4rem);
  background: linear-gradient(92deg, #faf9f5 0%, #ffe39d 80%);
  box-shadow: 0 5px 5px #b3ab96;
}

.slidebtn {
  position: absolute;
  top: 2px;
  left: -2px;
  width: 47px;
  height: 47px;
  border: 1px solid rgb(214, 212, 212);
  border-radius: 100px;
  box-sizing: border-box;
  margin-left: 2%;
  background: linear-gradient(120deg, #ffffff 0%, #e6dcc3 80%);
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  cursor: grab;
  overflow: hidden;

  .profileThumbnail {
    width: 50px;
    object-fit: cover;
    align-self: end;
    justify-self: start;
    box-shadow:  5px 5px #b3ab96;
  }
}

.slide .label {
  font-family: helvetica;
  font-size: 1.3rem;
  font-weight: 900;
  color: #964b00;
  text-shadow: 0 0 black;
  box-sizing: border-box;
  flex: 1;
  padding-left: 36px;
  text-align: center;
}
.thumbnailAvatar {
  height: 30px;
  width: 30px;
}
