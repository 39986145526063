// white background-ish with gold color animate
button.white-gold-btn {
    width: 100%;
    margin: 20px auto;
    // -webkit-appearance: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    // width: 250px;
    height: 40px;
    background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
    border-radius: 30px;
    // border: 1px solid #8f9092;
    box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
      0 -6px 4px #fefefe, inset 0 0 3px 0 #cecfd1;
    transition: all 0.2s ease;
    // font-family: "Robot slab";
    font-size: 0.875rem;
    font-weight: 800;
    // color: #c79f27;
    text-shadow: 0 1px #fff;
  //   this animate the button color
    animation: active 0.9s alternate infinite; 
  }
  @keyframes animation-light-gold{
    from {
      box-shadow: 0 4px 3px 1px #C79F27, 0 6px 8px #C79F27, 0 -4px 4px #C79F27,
        0 -6px 4px #C79F27, inset 0 0 10px 0px #C79F27;
    // box-shadow: inset 0 0 10px 0px #c79f27;
    }
    to {
      box-shadow: 0 4px 3px 1px #e2b645, 0 6px 8px #C79F27, 0 -4px 4px  #e2b645,
        0 -6px 4px #e2b645, inset 0 0 3px 3px  #e2b645;
        ;
    }
  }
  
  .light-gold-btn{
    animation: animation-light-gold 0.9s alternate infinite !important;
      // animation: animation-signup-blue 0.9s alternate infinite !important;
   }
  button.globalized-btn{ width: 100%;
    margin: 20px auto;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    /* background: linear-gradient(92deg, #ffd8a8 0%, #ffe39d 80%); */
    height: 40px;
    background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);  
     border-radius: 30px;
    /* box-shadow: 0 4px 3px 1px #f3cf71, 0 6px 8px #f3cf71, 0 -4px 4px #c79f27, 0 -6px 4px #f3cf71, inset 0 0 3px 0 #f3cf71; */
    transition: all 0.2s ease;
    font-size: 0.875rem;
    font-weight: 800;
    text-shadow: 0 1px #fff;
    animation: active 0.9s alternate infinite;
  }
  .position-relative {
    position: relative;
}