.user-post-container {
  position: relative;
  border-radius: 50px;
  border: 5px solid black;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  width: 256px; // Width maintaining the aspect ratio for mobile
  height: 400px; // Desired height for mobile
  overflow: hidden;
  background-size: cover; // Ensures the image covers the entire container
  background-position: center; // Centers the image in the container
  // @media (min-width: 812px) {
  //   height: 500px;
  //   width: 320px;
  // }
  .media {
    height: 100%;
    object-fit: contain; // This will stretch the image to fit the container.
    // Uncomment below if you prefer to cover the area instead.
    // object-fit: cover;
  }
  .Creat_Share {
    position: absolute;
    font-size: 25px;
    top: 0;
    backdrop-filter: blur(100px); // Adjusted value from stashed changes
    font-family: "roboto-slab";
    color: #fff; // Removed extra semicolon
    width: 100%;
    text-align: center;
  }

  .createPostCircle {
    position: absolute;
    top: 0;
    left: 24%;
    backdrop-filter: blur(15px);
  }
  .user-info-wrapper {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    bottom: 15%;
    left: 0;
  }
  .user-name-heading {
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    font-family: "roboto-slab";
    color: #e2b645;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }
  .user-name-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .userName {
    margin-left: -10px; // bringing the user name closer to the icon
  }
  .user-heading-bottom {
    margin-left: 0px; // giving it an indention appearance
  }
  .report-icon-wrapper {
    right: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 5%;
  }

  .report-icon-wrapper .icon-text {
    margin-top: 5px;
    font-size: 0.75rem;
  }

  .create-post-mb-pad {
    position: absolute;
    top: 0;
    backdrop-filter: blur(100px);
    font-family: "roboto-slab";
    color: #fff;
    width: 100%;
    text-align: center;
    @media (min-width: 812px) {
      display: none;
    }
  }
  .slide-button-wrapper {
    position: absolute;
    bottom: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .sidebar-icons {
    position: absolute;
    top: 43%;
    right: -8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-backdrop-filter: blur(1px);
    //  backdrop-filter: blur(1px);
  }

  .profile-thumbnail {
    position: relative;
    margin-bottom: 15px;
  }

  .follow-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    /* Add more styling for the plus icon */
  }

  /* Styling for Heart and Chat icons */
  .HeartIconComponent,
  .ChatIconComponent {
    margin-bottom: 15px;
    cursor: pointer;
    /* Add hover or other interaction styles */
  }
}

.user-post-container {
  // Existing styles
  display: flex;
  flex-direction: column; // Use column for vertical alignment
  align-items: center; // Horizontally center content
  justify-content: center; // Vertically center content

  .create-share-profile-pic {
    width: 217px; // Example width
  }
}
.slide-button-create-share-wrapper{
  .create-share-round-slider{
    height: 30px;
    width:30px;
  }
}

.user-post-container svg.user-profile-picture {
  width: 250px;
  height: 250px;
  margin-top: 30%;
}

.update-container {
  position: absolute;
  top: 10px;
  left: 50%; /* Centering the button horizontally */
  transform: translateX(-50%); /* Centering the button horizontally */
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}

.remove-container {
  position: absolute;
  bottom: 10px;
  left: 50%; /* Centering the button horizontally */
  transform: translateX(-50%); /* Centering the button horizontally */
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}

.hide-create-post-on-mobile {
  @media (max-width: 811px) {
    display: none; /* Hide the "Create Post" on mobile and tablet */
  }
}

// heart animation for when you click on the heart
.animate {
  animation: pulse 0.7s ease;
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.heart-like-count {
  font-size: 1rem;
  color: #ffff;
  font-weight: 900;
  font-family: "roboto-slab";
  text-align: center;
  // text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 2px 4px;
  backdrop-filter: blur(100px);
}

.comment-count {
  font-size: 1rem;
  color: #ffff;
  font-weight: 900;
  font-family: "roboto-slab";
  padding: 2px 4px;
  border-radius: 4px;
  text-align: center;
  backdrop-filter: blur(100px);
}
.not-interested-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.not-interested-icon-wrapper .icon-text {
  text-align: center; /* Center the text under the icon */
}

.followProfile-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.followProfile-btn .icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-text {
  font-size: 0.75rem;
  color: #ffff;
  font-weight: 900;
  backdrop-filter: blur(100px);
}


// below is centered perfect, but i am going to test the market with it lower first
// .heart-like-count {
//   font-size: 1rem;
//   color: #fff;
//   -webkit-backdrop-filter: blur(100px);
//   backdrop-filter: blur(100px);
//   font-family: "roboto-slab";
//   text-align: center;
//   margin-top: 5px;
//   text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
//   padding: 2px 4px;
// }
// .comment-count {
//   font-size: 1rem;
//   color: #fff;
//   -webkit-backdrop-filter: blur(100px);
//   backdrop-filter: blur(100px);
//   font-family: "roboto-slab";
//   text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
//   padding: 2px 4px;
//   border-radius: 4px;
//   text-align: center;
//   margin-top: 5px;
//   /* Additional styling as needed */
// }
