.categories-menu-wrapper.show {
  display: flex;
  position: fixed; /* Fixed positioning makes the wrapper cover the whole viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 2px 2px 10px 0px rgba(99, 106, 119, 0.6); // Slight shadow for elevation
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center; /* Centers vertically */
  justify-content: center; /* Centers horizontally */
  z-index: 30;
}
.popup-wrapper.popup--wrapper.event-attached {
  // padding: 15px 25px;
  box-sizing: border-box;
  position: sticky;
  top: 50px;
  // padding-top: 100px;
}

.subcatmenu-popup-body {
  background: linear-gradient(88deg, #d6d6d6 58%, #ffe39d);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  padding: 15px 25px;
  @media (min-width: 811px) {
    width: 100%;
  }
}

.categories-list {
  position: absolute;
  top: 0;
  bottom: 0;
  scroll-behavior: smooth;
  font-size: 1.2rem;
  overflow: auto;
  overflow-x: hidden;
  padding: 15px 25px;
  list-style: none;
  margin: 0;
  max-height: 900px;
  left: 0;
  right: 120px;
  max-height: 400px; // Adjust as needed
  overflow-y: auto; // Enable scrolling within the categories list
}
.icon-container .category-menu-parent-icon {
  fill: #000;
}

.subcategories-container {
  display: flex;
  justify-content: space-between;
  max-height: 608px; // this will adjust the size of the subcategory menu which is independent of the list amount size in view if this is larger than the list set then there will be space at the bottom the subcategories-column class controls the container size within the container itself
}
.subcategories-scroll-container {
  overflow-y: auto;
  max-height: 600px;
}
.alphabet-index-column {
  flex-shrink: 0;
}
.subcategories-column {
  flex-grow: 1;
  overflow-y: auto; /* Enables scrolling */
  max-height: 700px; // this will adjust the size of the column container so if the container is adjusted with space at the bottom this can be ajusted to fill that space if desired
  margin-right: 10px; /* Space between the columns */
}

.subcategories-alphabet {
  width: 110px;
  margin: 0 15px 0 auto;
  text-align: center;
}
.subcategories-alphabet-prefix {
  display: block;
  font-size: 1.2rem;
  font-family: "roboto-slab", helvetica, arial;
  margin-bottom: 15px;
}

.subcategories-alphabet-char {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 50%;
  text-align: center;
  color: #964b00;
  font-weight: 600;
  transition: border-color 0.5s;
  border: 1px solid transparent;
}
.subcategories-alphabet-char:hover {
  color: #e2b645;
  border-color: #e2b645;
}

.subcategories-alphabet-char.active {
  border-color: #000;
  color: #000;
}
.categories-popup-prime-icon {
  width: 62px;
  height: 52px;
}
.categories-popup-cat-icon {
  width: 74px;
  height: 74px;
}
.categories-list {
  position: relative;
  overflow-y: auto; /* Allow scrolling within the list */
  max-height: 400px;
  right: 80px;
  font-size: 1rem;
}
.categories-list-char {
  font-size: 1.5625rem;
}
.subcategories-alphabet {
  width: 60px;
}

.subcategories-alphabet-char {
  width: 26px;
  height: 26px;
  line-height: 26px;
}
.subcategories-alphabet {
  height: 100vh;
  overflow: auto;
}
.subcategories-alphabet-prefix {
  font-size: 0.9375rem;
}
.subcategories-alphabet-char {
  // sets the size for the vertical index
  font-size: 1rem;
  width: 22px;
  height: 20px;
  line-height: 22px;
}
.inactive-letter {
  // alpha marker on left
  color: #ffff;
}
.active-letter {
  // alpha marker on left
  color: black;
  text-decoration: underline;
}
.popup-wrapper {
  flex-direction: column;
  max-height: 90vh;
}

.subcatmenu-header {
  background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: sticky;
  @media (min-width: 811px) {
    width: 100%;
    padding-left: 40px;
    padding-right: 10px;
  }
}
.header-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  font-family: "nunito";
  font-size: 1rem;
  font-weight: 800;
  color: #000;
}
.subcategory-title {
  color: #000;
}

.subcategory-item a {
  color: #964b00;
  font-weight: 900;
  font-size: 0.75rem;
}
.icon-container {
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Align items vertically in the center */
  justify-content: flex-start; /* Align items to the start of the container */
  padding: 10px; /* Adjust padding as needed */
}
.selected-subcategory-display {
  display: grid;
  place-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border: 1px solid #e2b645;
  outline: none;
  color: #964b00;
  cursor: pointer;
  width: 100%;
  /* Specific styles for <p> tag inside .selected-subcategory-display */
  p {
    font-size: 1rem; /* Or any other specific styles you want */
    color: #fff;
    font-weight: 800;
    font-family: "roboto-slab";
  }
}
