#payments-form {
  h2{
    color:#fefefefe;
  }
  .blinker-wrapper {
    position: relative;

    // hide custom blinker when input is focused
    input:focus + .blinking-cursor {
      display: none;
    }

    // hide custom blinker when the placeholder isn't shown
    input:not(:placeholder-shown) + .blinking-cursor {
      display: none;
    }
  }
  .blinking-cursor {
    font-weight: 600;
    font-size: 23px;
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    letter-spacing: -4px;
    animation: blink 1s step-end infinite;
  }

  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #000;
    }
  }
}
.error-border {
  border: 1px solid red;
  padding: 5px; // Optional padding to give a little space around the content.
}
.error-text {
  color: red;
  font-size: 0.8em;
  margin-left: 10px;
}
.payment-form {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-content,
.paymentPlacholder {
  cursor: pointer;
  border: 1px solid #e2b645;
}
.paymentPlacholder {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  color: #964b00;
  font-family: roboto-slab;
  font-weight: 800;
  max-width: fit-content;
  padding: 5px;
}
/* Apply a bottom border to all <p> elements inside .dropdown-content except the last one */
// children are the payment options and not the place holder
.dropdown-content p:not(:last-child) {
  border-bottom: 1px solid#e2b645; /* Example border, change color and width as needed */
}
/* Apply a top border to the last <p> element inside .dropdown-content */
// children are the payment options and not the place holder
// nothing was needed currently for this child but could change in the future
// .dropdown-content p:last-child {
// }
.billing-expiry-input {
  width: 300px;
  height: 40px;
}

.billing-credit-card-input {
  width: 50%;
  height: 40px;
}
.billing-first-name-input {
  width: 90%;
  height: 40px;
}
.billing-last-name-input {
  width: 90%;
  height: 40px;
}
.billing-address-line1-input {
  width: 100%;
  height: 40px;
}
.billing-address-line2-input {
  width: 100%;
  height: 40px;
}

.billing-city-input {
  width: 100%;
  height: 40px;
}
.billing-zipcode-input {
  height: 40px;
}
.billing-country-input {
  height: 40px;
}
.billing-statedropdown {
  width: 200px;
}
.billingAddDescription {
  font-style: italic;
  font-family: "emoji";
}
.billingNameWrapper {
  display: flex;
}
