#book-control {
    margin: 10px auto 0 auto;
    padding: 10px 0;
    border-radius: 32px;
    border: solid 11.2px black;
    box-shadow: 
      inset 0 -3px 8px rgba(0,0,0,.6), 
      inset 0 3px 8px rgba(252,255,255,.7), 
      0 3px 8px -3px rgba(0,0,0,.8);
  
    display:flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    width: fit-content;
  
    span {
      font-weight:900;
      &.black {
        color:#000
      }
  
      @media only screen and (max-width: 476px) { 
        font-size: .75rem ;
      }
    }
  
    button {
      width: fit-content;
      @media only screen and (max-width: 476px) { 
        font-size: .75rem !important;
      }
    }
  
    .start-stop-button {
      cursor: pointer;
      user-select: none;
      height: 40px;
      padding: 0 15px;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      font-family: "Roboto Slab";
      font-size: 1.3rem;
      font-weight: 800;
      text-shadow: 0 1px #fff;
      white-space: nowrap;
      color: #815121;
  
      outline: none;
      border-radius: 30px;
      background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
      
      transition: all 0.2s ease;
      animation: active 0.9s alternate infinite;
    }
  
    .slider-label {
      font-size: 1rem;
      white-space: nowrap;
    }
  
    .slider-and-control-buttons-container {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
  
      .slider-ui {
        -webkit-appearance: none;
        height: 5px;
        background: #ddd;
        outline: none;
        transition: opacity 0.2s;
        transform: scaleX(-1);
        width: 200px;
        border-radius: 500px;
        box-shadow: rgba(0, 0, 0, 0.7333333333) 0px -1px, rgba(0, 0, 0, 0.7333333333) 0px 1px;
  
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          background: #fdfd96; /* Thumb color */
          border-radius: 50%;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.7333333333) 0px -1px, rgba(0, 0, 0, 0.7333333333) 0px 1px;
        }
  
        &::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background: #fdfd96; /* Thumb color */
          border-radius: 50%;
          cursor: pointer;
        }
  
        @media only screen and (max-width: 606px) {
          width: calc(100% - 2 * 40px - 2 * 10px); // Adjust the slider width considering button widths and margins
        }
      }
  
      .speed-button {
        cursor: pointer;
        height: 40px;
        font-family: "Roboto Slab";
        font-weight: 900;
        font-size: 1.5rem;
        text-shadow: 0 1px #fff;
        white-space: nowrap;
        color: #815121;
        transition: all 0.2s ease;
        border-radius: 30px;
        background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
        background: linear-gradient(92deg, #ffd8a8 0%, #ffe39d 80%);
        box-shadow: 
          inset 0 -3px 8px rgba(0,0,0,.6), 
          inset 0 3px 8px rgba(252,255,255,.7), 
          0 3px 8px -3px rgba(0,0,0,.8);
      }
    }
  }
  