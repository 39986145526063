.profilePg {
  padding-bottom: 70px;
}
.buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  .followProfile-btn,
  .BlockProfile-btn,
  .ChatProfile-btn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.profile-posts-container {
  text-align: center; // Center the header

  h3 {
    margin-bottom: 20px; // Space between the header and the posts, adjust as needed
  }
}
/* Targeting the PointsCircle component inside the profileUnlockLevel and positionUnlockContent classes */
.profileUnlockLevel .positionUnlockContent {
  position: relative;
  bottom: 75px;
  left: 30%;
}

.sticky-container {
  top: 120px; // This means the element will "stick" to the top once 10px from the top
  position: sticky;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(100px);
  h4.user-posts-header {
    margin-top: -10%; // Add your margin top value here
    font-size: 1.3rem;
    width: 85%;
    background-color: rgba(0, 0, 0, 0.7333333333);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    margin-left: 9%; // to center the text horizontally
    z-index: 1;
    position: absolute;
  }
}

.profile-posts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // or any value for spacing between the posts

  .UserPost {
    flex: 1 0 calc(33.33% - 20px); // 3 posts per row and accounts for the gap
    max-width: calc(33.33% - 20px);

    @media (max-width: 992px) {
      // for tablets
      flex: 1 0 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }

    @media (max-width: 768px) {
      // for mobile devices
      flex: 1 0 100%;
      max-width: 100%;
    }
  }
}

.user-post-header {
  font-size: 2.5rem;
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  font-family: "roboto-slab";
  color: #fff;
  width: 100%;
  text-align: center;
  position: sticky;
  top: 190px; // This means the element will "stick" to the top once 190px from the top
  z-index: 2; // To ensure it's above other elements if there's any overlap
  margin-top: 30px; // this will position it without the scroll
}
.underlay {
  backdrop-filter: blur(200px);
}

.ProfileConnects {
  white-space: nowrap;
  z-index: 19;
}
.slide.ProfileConnects {
  padding-left: 22px;
  @media (max-width: 500px) {
    .profile-connections-slider {
      padding-left: 0; // Adjust as needed for smaller screens
    }
  }
}
#pointsConectWrap {
  display: flex;
  align-items: center;
  justify-content: center; // Added for better alignment

  @media (max-width: 600px) {
    // Adjust the breakpoint as needed
    flex-direction: column;
  }
}

.ProfileConnects {
  width: fit-content; // Ensures the slider doesn't stretch beyond its content
}

// Other styles...

.flex {
  margin-left: 50%;
}
.profile-picture-wrapper {
  width: 30px;
  height: 30px;
  overflow: hidden; // This ensures that the image won't spill over if it's bigger
}

.PositionShow {
  width: 8%;
  height: auto;
  display: flex;
}

.visibility {
  display: none;

  &.is-visible {
    display: block;
    margin-top: -6%;
  }
}

.stickUserPost {
  position: sticky;
  top: 10px; // This means the element will "stick" to the top once 190px from the top from the scroll set on the jsx file
  z-index: 200;
}
.underlay-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(100px);
  display: flex;
  justify-content: center;
  align-items: center; // These center the child element (the form in this case)
}

.buttons-placeholder {
  height: 10px; /* Set this to whatever the typical height of the buttons-row is */
}
