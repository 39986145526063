#event-details-pad-mobile-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  padding-bottom: 60px;
  z-index: 10; /* To ensure it appears above other content */
  .connections-input-container,
  .SlideBtn {
    width: 80%;
  }
  @media (min-width: 767px) {
    display: none; // hide for bigger screens
  }
}
.actionBtnEventsDetailsMbPad{
  display:flex;
  gap:5px;
}
.mbpdeventfilterNavContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  box-shadow: 0 1px 5px black;
  z-index: 20; /* To ensure it appears above other content */
}
.mbpdeventfilterNavContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.topNavEventsDetailsMbPad {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Adds space between buttons when they wrap */
  width: 100%;
}
.mb-pad-events-navtop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Adds space between buttons when they wrap */
  width: 100%;
}
#event-mp-pad-search-bar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.connections-input-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.guest-list,
.admin-ctrls {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.events-mb-pad-container-btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.flex-column {
  padding-top: 110px;
  @media (min-width: 376px) {
    padding-top: 100px;
  }
  @media (min-width: 400px) {
    padding-top: 90px;
  }
  @media (min-width: 410px) {
    padding-top: 80px;
  }
  @media (min-width: 415px) {
    padding-top: 70px;
  }
  @media (min-width: 420px) {
    padding-top: 60px;
  }
  @media (min-width: 500px) {
    padding-top: 50px;
  }
  @media (min-width: 550px) {
    padding-top: 40px;
  }
}
.hide-top-nav {
  display: none;
}
.actionBtns {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Align items horizontally in the center */
  flex-wrap: wrap;
}
.lrgScreeBtnDetailsPgActions{
  display:flex;
}

