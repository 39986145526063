#book {
  transition: opacity 0.4s 0.2s;
  perspective: 5000px;
  perspective-origin: 50% 50%; // Try adjusting these values
  position: relative;

  .book-intro {
    position: relative;
    margin-top: 100px;
    font-family: "roboto-slab";
  }

  .pages {
    width: 90vw;
    max-width: 350px;
    min-height: 300px;
    height: 44vw;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    @media (min-width: 450px) {
      max-width: 420px;
      min-height: 300px;
    }

    @media (min-width: 550px) {
      max-width: 500px;
      max-height: 645px;
    }

    @media (min-width: 768px) {
      width: 60vw;
      max-width: 880px;
      max-height: 645px;
    }

    .page {
      margin: 0;
      position: absolute;
      top: 0;
      width: 45vw;
      max-width: 175px;
      min-height: 300px;
      height: 44vw;
      max-height: 645px;
      transform-origin: 0 0;
      // this changes the speed of the swing of the page
      transition: transform 1.4s;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      cursor: pointer;
      user-select: none;
      background-color: #fff;
      overflow: hidden;

      @media (min-width: 450px) {
        min-width: 210px;
        min-height: 300px;
      }

      @media (min-width: 550px) {
        max-width: 250px;
        max-height: 645px;
      }

      @media (min-width: 768px) {
        width: 30vw;
        max-width: 440px;
        max-height: 645px;
      }

      .book-intro {
        text-align: center;
        position: relative;
        color: black;
        text-transform: uppercase;
        font-family: helvetica;
      }

      .username {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #000000;
        letter-spacing: 0.5px;
        font-size: 0.75rem;
        position: absolute;
        z-index: 2;
        border-radius: 2rem;
        background-color: #00000083;
        backdrop-filter: brightness(1) saturate(2) blur(20px);
        color: #fff;
        width: 80%;
        padding: 3px;
        top: 90%;

        @media (min-width: 768px) {
          font-size: 1.125rem;
          padding: 10px;
        }
      }

      .profile-image-container {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        overflow: hidden;
        display: flex;
        height: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &.book-cover {
        background-color: black;
        background-image: url("../../assets/leatherTexture.png") !important;

        .book-title {
          font-size: 1.2rem;
          font-family: Arial, Helvetica, sans-serif;
          letter-spacing: 2px;
          line-height: 1.7rem;
          color: #e2b645; //gold
          text-align: center;
          display: block;
          margin-top: 50%;
          transform: translateY(-50%);

          @media (min-width: 550px) {
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: 5px;
          }
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        transition: background 0.7s;
        z-index: 2;
      }

      &:nth-child(odd) {
        pointer-events: all;
        transform: rotateY(0deg);
        right: 0;
        border-radius: 5px 10px 10px 5px;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(0, 0, 0, 0) 10%
        );
      }

      &:nth-child(odd):hover {
        // transform: rotateY(-15deg);
      }

      &:nth-child(odd):hover:before {
        background: rgba(0, 0, 0, 0.03);
      }

      &:nth-child(odd):before {
        background: rgba(0, 0, 0, 0);
      }

      &:nth-child(even) {
        pointer-events: none;
        transform: rotateY(180deg);
        transform-origin: 100% 0;
        left: 0;
        border-radius: 10px 5px 5px 10px;
        border-color: black;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.12) 0%,
          rgba(0, 0, 0, 0) 10%
        );
      }

      &:nth-child(even):before {
        background: rgba(0, 0, 0, 0.2);
      }

      &.grabbing {
        transition: none;
      }

      &.flipped {
        &:nth-child(odd) {
          pointer-events: none;
          z-index: 1; // Adjust z-index
          transform: rotateY(-180deg);
        }

        &:nth-child(odd):before {
          background: rgba(0, 0, 0, 0.2);
        }

        &:nth-child(even) {
          z-index: 2; // Adjust z-index
          pointer-events: all;
          transform: rotateY(0deg);
        }

        &:nth-child(even):hover {
          // transform: rotateY(15deg);
        }

        &:nth-child(even):hover:before {
          background: rgba(0, 0, 0, 0.03);
        }

        &:nth-child(even):before {
          background: rgba(0, 0, 0, 0);
        }

        * {
          :before,
          &:after {
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.contact-list-page {
  display: flex;

  #contact-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15% 30px 20px 30px;
    // the margin is set to 0 so I remove the top margin at
    // screen size 430 and below
    @media (min-width: 400px) {
      margin-top: -10% !important;
    }
  }
}


#book-control-button {
  align-self: flex-end;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    position: relative; // Position it absolutely relative to #book
    left: -250px; // Adjust this value to position the button to the left of the book
    top: 50%; // Center it vertically
    transform: translateY(-50%); // Adjust vertical position to be centered
  }
}
