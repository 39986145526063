.slider-wrapper{
  input{
        display: none;
      }
}
// globalized styling for checkbox  slider
// standriaizing border width  
.checkbox-slider {
display: flex;
transform: translate(-50%, -50%);
width: 100px;
height: 46px;
border-radius: 25px;
background: linear-gradient(0deg, #ccc, #fff);
border-top: 1px solid #ececec;
border-bottom: 1px solid #ececec;

}
.checkbox-slider > span {
  display: block;
  margin-bottom: 5px;  /* adjust this value as needed */
}


.inner {
position: absolute;
top: 10px;
left: 10px;
right: 10px;
bottom: 10px;
background: linear-gradient(0deg, #964b00, #964b00);
border-radius: 20px;
box-shadow: inset 0 0 15px rgba(0,0,0,.5);
}

// toggle componet
.toggle {
position: absolute;
top: -9px;
left: -3px;
width: 40px;
height: 40px;
border-radius: 50%;
background: linear-gradient(0deg, #fff, #fff);
box-shadow: 0 4px 6px rgba(0,0,0,.2);
box-sizing: border-box;
border-top: 0.64px solid #ececec; 
border-bottom: 0.64px solid #ececec;
cursor: pointer;
transition: 0.5s;
}
//  this will slide the toggle to the right 
.checkbox-slider .inner.active .toggle {
left: 47px;
}

.inner.active {
  background: linear-gradient(0deg, #ffe39d, #e2b645);
}

.toggle::before {
content: '';
color: #fff;
position: absolute;
top: 4px;
left: 4px;
right: 4px;
bottom: 4px;
// background: linear-gradient(0deg, #f18400, #ccc);
background: #964b00;
border-radius: 50%;
text-align: center;
font-size: 1rem;
font-weight: 800px 
/*     line-height: 28px; */

}
.inner.active .toggle::after {
  content: attr(data-content);
  color: #fff;
  font-weight: 800;
  position: absolute;
  left: 8px;
  top: 50%; // Center vertically
  left: 50%; // Center horizontally
  transform: translate(-50%, -50%); // Ensures it's perfectly centered
}

.inner .toggle::after {
  content: attr(data-content);
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 50%; // Center vertically
  left: 50%; // Center horizontally
  transform: translate(-50%, -50%); // Ensures it's perfectly centered
  font-size: 0.8rem;
}

.inner.active .toggle::before {
content: '';
color: #c79f27;
//  background: linear-gradient(0deg, #186acf, #ccc);
background: #c79f27;
border-radius: 50%;
text-align: bottom;
font-size: 1rem;
font-weight: 800px 
}
.slider-container {
  display: flex;
  justify-content: end;
  margin-top: -5%;
}






