.user-container {
  display: flex;
  flex-direction: column; // Stack children vertically
  align-items: center; // Center-align children horizontally
  border: none;
  overflow: visible;  // Remove hidden overflow, or adjust if needed
  
  .thumbnailPicAvatar {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .full-name {
    font-size: 1.3rem;
    font-family: "helvetica nue";
    font-weight: 900;
    margin-top: 2px;
    margin-bottom: 2px;
}

  .username{
    font-size: 1.3rem;
    font-family: "helvetica nue";
    font-weight: 900;
  }
  .italic{
    font-style: italic;
  }
 
}
