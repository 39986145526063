.eventListtwo {
  width: 150px;
  height: 150px;
  background: #222;
  margin: 100px auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.eventListcaltwo {
  width: 250px;
  height: 180px;
  // margin: 100px auto 0 auto;
  background: #222;
  text-align: center;
  color: #eaeaea;
  position: relative;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  padding: 10px 0;
  box-shadow: 2px 5px 2px rgba(black, 0.2);
}
.eventListdaytwo {
  font-size: 25px;
  letter-spacing: 7px;
  font-weight: 300;
  margin-bottom: -2px;
}

// The mix() function in Sass is used to mix two
// colors together and return the result. The function
// takes up to three arguments:
// The first color
// The second color
// An optional weight between 0% and 100% (default is 50%)
.eventListmonthtwo {
  font-size: 1rem;
  font-weight: 400;
  color: mix(
    black,
    #fdfd96,
    10
  ); // This will mix 10% of black with 90% of #fdfd96 and return the resulting color.
  letter-spacing: 5px;
  word-spacing: 5px;
}
.eventListclock {
  width: 150px !important;
  height: 30px;
  background: #eaeaea;
  margin: 0 auto;
  line-height: 30px;
  text-align: center;
  box-shadow: 5px 5px 2px rgba(black, 0.2);
}
.eventListtime {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-right: -4px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #666;
  letter-spacing: 5px;
}
.eventListmeter {
  width: 300px;
  position: relative;
  margin: 80px auto;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(white, 0.2);
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    left: 150px;
    top: 0px;
    background: #aaa;
  }
  &:after {
    content: "Visual Clock";
    position: absolute;
    top: -20px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    opacity: 0.3;
    font-size: 12px;
    letter-spacing: 5px;
    color: white;
  }
}
.eventListtimer {
  margin: 5px 0;
}

.eventListhours {
  height: 10px;
  background: #222;
  border-bottom: 1px solid #333;
}
.eventListminutes {
  height: 5px;
  width: 1px;
  background: #eaeaea;
  border-bottom: 1px solid #333;
  &:before {
    position: absolute;
    content: "";
    left: 150px;
    width: 1px;
    height: 5px;
    background: #333;
  }
}
.eventListseconds {
  height: 2px;
  width: 1px;
  background: mix(black, red, 40);
}

.eventListclock {
  text-align: center; /* To center inline or inline-block child elements */
  width: 100%; /* Optional, take the full width of parent */
  position: relative;
}

.slider-wrapper {
  position: relative;
  left: 100%;
  transform: translateX(-50%); /* Centers horizontally */
}
