#replyComponent-container {
  .userReplyThumbnail {
    width: 40px; /* Adjust as required */
    height: 40px;
    top: 0px;
    z-index: 6;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the image covers the div fully */
      border-radius: 50% !important; /* This will make it circular */
    }
  }
  .growable-textarea {
    resize: none;
    text-indent: 20px; /* Indents both placeholder and value */
    line-height: 1.5; /* Line height for the text */
    white-space: pre-wrap; /* Wrap text as in textarea */
    word-wrap: break-word; /* Ensure long words do not overflow */
    width: 100%;
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: #fefefe;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    &::placeholder {
      font-family: helvetica;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.5;
      color: #ffff;
      -webkit-text-stroke: 0.5008px #e2b645;
    }
  }
  .textarea {
    box-shadow: 0 1px 5px black;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    border-radius: 4px; /* Rounded corners like textarea */
  }
  .reply-bubble {
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 10px; /* Add space between thumbnail and text */
    padding: 10px; /* Padding inside the bubble */
  }

  .userReplyThumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* This will make it circular */
    overflow: hidden;
    flex-shrink: 0; /* Prevent the thumbnail from shrinking */
  }

  .userReplyThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .replying-to {
    position: relative;
    max-width: calc(100% - 50px); /* Prevents the bubble from exceeding the parent width */
    padding: 10px;
    background-color: black; 
    border-radius: 0 4px 4px 4px;
    color: #f3cf71; 
    margin-bottom: 20px; 
    box-shadow: rgba(0, 0, 0, 0.5) 0 3px 5px -3px;
    display: inline-block; 
    -webkit-animation-name: slideDown;
    animation-duration: 0.9s;
    -webkit-animation-duration: 0.9s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
  }
  
  .replying-to::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px; /* Half the width of the border width */
    transform: translateY(-50%);
    border-style: solid;
    border-width: 10px 10px 10px 0; /* Adjust size of triangle here */
    border-color: transparent black transparent transparent; /* The second value is the color of the triangle */
  }
  
}

// @keyframes slidein {
//   from {
//     transform: translateX(0%);
//   }

//   to {
//     transform: translateX(20%);
//   }
// }