.container {
  display: flex;
  justify-content: center; // Horizontally center children
  align-items: center; // Vertically center children
  flex-direction: column;
  width: 165px;
  height: 275px;
  background-color: #fefefe;
  background-repeat: no-repeat;
  position: relative;
  perspective: 1000px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 10%
  );
  @media (min-width: 550px) {
    width: 225px;
    height: 350px;
  }
}
.front {
  background-color: black;
  background-image: url("../../assets/leatherTexture.png") !important;
  .book-title {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    line-height: 1.7rem;
    color: #e2b645; //gold
    text-align: center;
    display: block;
    margin-top: 50%;
    transform: translateY(-50%);

    @media (min-width: 550px) {
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: 5px;
    }
  }
}
.cover {
  // width: 225px;
  width: 165px;
  height: 100%;
  position: absolute;
  bottom: 0%;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  transform-origin: left;
  // this controls the transition-duration CSS property
  // which controls the time for a transition to complete.
  transition: all 1s ease-in;
  @media (min-width: 550px) {
    width: 225px;;
  }
}
.opened-book {
  max-width: 10%; // allows some padding on the sides
  margin: auto; // centers the container
  .container .cover {
    transform: rotateY(-180deg);
  }
}
// .container .cover {
//   transform: rotateY(-180deg);
// }
figure {
  margin: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

.bio {
  color: #000000;
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  text-align: center; // Center the header (and all direct text children)
  p {
    text-indent: 7px; // Indent the first line
    font-size: 0.75rem;
    @media only screen and (min-width: 400px) {
      font-size: 1rem;
    }
  }
}

figure.back {
  background-color: #fefefe;
  transform: rotateY(180deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 10%
  );
}

@media only screen and (max-height: 1000px) {
  .author {
    padding: 10px;
    font-size: 80%;
  }
}

@media only screen and (max-height: 600px) {
  .author {
    padding: 20px;
    font-size: 60%;
  }
}
.ProfilePicBookPg {
  max-height: 200px; // Adjusted for maintaining aspect ratio
  object-fit: cover;
  overflow: hidden; // Hide anything that goes beyond the container's boundary
}
// using the whiteblend btn class name on the parent component and passing a new class name to the child for specifity and changing locally
.white-blend-btn.add-bio-button {
  font-size: 1rem;
  width: 50px;
  padding: 0px;

  @media (min-width: 768px) {
    // For tablets and smaller devices
    font-size: 1.3rem;
  }
}
.white-blend-btn.add-a-profile-pic-btn {
  font-size: 1rem;
  width: 50px;
  padding: 0px;

  @media (min-width: 768px) {
    // For tablets and smaller devices
    font-size: 1.3rem;
  }
}
