#reset-password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    margin-bottom: 35px;
    color: black;
  }
  .show-hide-password-toggle {
    cursor: pointer;
    font-weight: 900;
    color: black;
    font-size: 0.75rem;
    line-height: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    position: absolute;
    bottom: 5px;
    right: 2%;
    width: 45px;
  }
  input::placeholder {
    color: #757575; /* Default placeholder color */
  }
  .red-placeholder::placeholder {
    color: red;
  }
}
