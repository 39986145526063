.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .date-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%; // Adjust this width to control the space between buttons and month/year
  }
}
.current-month-year {
  color: #fff;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0 20px;
}

.days {
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 10px 0;
  font-size: 1.2rem;
  box-sizing: border-box;
}

.day {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  cursor: pointer;
  &:hover {
    color: #964b00 !important;
    background-color: #fdfd96;
  }
}
.day.selected {
  background-color: #964b00;
  color: white;
  border-radius: 5px;
  &:hover {
    color: #964b00 !important;
    background-color: #fdfd96;
  }
}
.day.disabled {
  opacity: 0.5;
  pointer-events: none; /* This makes the day not clickable */
  cursor: not-allowed; /* This gives a visual feedback to the user that it's not clickable */
}

.day.empty {
  border: none;
}
/* Add these styles to your eventDatePicker.scss */
.month-select {
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  position: relative;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 800;
  font-family: "roboto-slab";
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  font-size: 1.5rem;
  border: 1px solid #e2b645;
  outline: none;
  color: #964b00;
  margin-top: 10px;
  cursor: pointer;
  background-color: #fff;
  max-width: 290px;
}
.button-arrow {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: #fdfd96 !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.2) !important;
  -webkit-backdrop-filter: blur(30px) !important;
  backdrop-filter: blur(30px) !important;
  border: 1px solid #fdfd96 !important;
  cursor: pointer;
  transition: transform 0.3s; // For a subtle scaling effect on hover

  &:hover {
    transform: scale(1.05);
  }
}
.button-arrow {
  // ... (common styles for both buttons)

  &.button-arrow-left {
    margin-right: 20px !important; // adjust as needed
  }

  &.button-arrow-right {
    margin-left: 20px !important; // adjust as needed
  }
}

/* Add these styles to your eventDatePicker.scss */
.month-options {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.87);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  /* Default styles for non-focused items */
  div {
    padding: 5px;
    color: rgb(226, 182, 69);
    width: 250px;
    cursor: pointer;
    outline: none;
    background-color: transparent;

    &:hover {
      background-color: rgba(226, 182, 69, 0.1);
      color: #fdfd96;
    }
  }

  /* Styles for focused items */
  .focused {
    // background-color: #fdfd96;
    // color: #3f3f3f;
    color: #fdfd96;
    border: 1px solid #e2b645;
  }
}
.date-picker-content {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden; /* This will ensure inner content doesn't spill outside the rounded corners. */
  max-width: 290px;
}
.event-date-picker-container {
  input {
    width: 200px;
    height: 30px;
    border: 1px solid #e2b645;
    background-color: transparent;
    /*user's response text color */
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(253, 253, 150);

    &::placeholder {
      /* Initial placeholder color */
      font-size: 1.3rem;
      font-weight: 600;
      color: #ffff;
      -webkit-text-stroke: 0.5008px #e2b645;
    }
    &:focus {
      /*user's response text color */
      font-size: 1.3rem;
      font-weight: 600;
      background-color: white;
      box-shadow: 0 1px 5px black;
      border-color: transparent;
      outline-color: #964b00; /* Change to your desired color */
      color: #964b00;
      &::placeholder {
        /* Placeholder color on focus */
        font-size: 1.3rem;
        font-weight: 600;
        color: black;
      }
    }
  }
  
}
.inputClearFlex {
  display: flex;
  white-space: nowrap;
}

.month-options div {
  padding: 5px;
  color: rgb(226, 182, 69);
  width: 250px;
  position: relative;
  z-index: 1000 !important;
  cursor: pointer;
  outline: none;
  /* Default styles for non-focused items */
  &:not(.focused) {
    background-color: transparent;
    color: rgb(226, 182, 69);

    &:hover {
      // background-color: rgba(226, 182, 69, 0.1);
      // color: #fdfd96;
      color: #fdfd96;
      border: 1px solid #e2b645;
    }
  }
  /* Styles for focused items */
  &.focused {
    // background-color: #fdfd96;
    // color: #3f3f3f; /* Text color on focus */
    color: #fdfd96;
    border: 1px solid #e2b645;
  }
}
.day-headers,
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: grid;
  color: #fcfcfc;
  font-size: 1.3rem;
  font-weight: 800;
}

.day-header {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-header {
  font-weight: bold; /* Optional: make headers bold */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Add these styles to your eventDatePicker.scss */
.year-select {
  display: grid;
  place-items: center; /* Center both horizontally and vertically */
  position: relative;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 800;
  font-family: "roboto-slab";
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  font-size: 1.5rem;
  border: 1px solid #e2b645;
  outline: none;
  color: #964b00;
  margin-top: 10px;
  cursor: pointer;
  background-color: #fff;
  max-width: 290px;
}
.year-options {
  position: absolute; /* Change this back to absolute */
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(3, 3, 3);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
  z-index: 1000;
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.year-options div {
  padding: 5px;
  color: rgb(226, 182, 69);
  width: 250px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: rgba(226, 182, 69, 0.1);
    color: #fdfd96;
  }
  &.focused {
    background-color: #fff;
  }
}
.date-selection-container {
  .dropdowns-wrapper {
    display: flex;
    justify-content: space-between; // if you want some space between them
    width: 100%; // Adjust the width as per requirement

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
.date-error-message{
  color: red;
  font-size: 1rem;
  font-weight: bold;
}
