.time-select {
        display: grid;
        place-items: center;     /* Center both horizontally and vertically */  
        position: relative;
        background: rgba(255, 255, 255, 0.09);
        border: 1px solid rgba(255, 255, 255, 0.1);
        font-weight: 800;
        font-family: "roboto-slab";
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        font-size: 1.5rem;
        border: 1px solid #e2b645;
        outline: none;
        color: #964b00;
        margin-top: 10px;
        cursor: pointer;
        background-color: #fff;
        width: 100%;
}
.time-select {
    z-index: 1; /* default z-index */
}

.time-select-opened {
    z-index: 2 !important; /* higher z-index for the active dropdown */
}

.time-select-closed {
    z-index: 1 !important;
}


.time-options {
    position: absolute;
    top: 100%;
    left: 50%;  /* Center the starting point */
    transform: translateX(-50%); /* Shift it back half its width */
    background-color: rgb(3, 3, 3);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
}

.time-options div {
    padding: 5px;
    color: rgb(226, 182, 69);
    width: 250px; 
    cursor: pointer;
    outline: none;
    &:hover {
        background-color: rgba(226, 182, 69, 0.1);
        color: #fdfd96;  
    }
    
    &.focused {
        background-color: #fff;
    }
}

.time-options {
    width: 200px; // Adjust this width as needed for your time strings
    max-height: 300px; // how tall I want it to be before scroll
    overflow-y: auto; // Enable vertical scrolling
    overflow-x: hidden; // Prevent horizontal scrolling
    
    /* Styles for Webkit browsers like Chrome, Safari */
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e2b645; 
        border-radius: 12px; // To make it rounded
    }

    /* Styles for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #e2b645 transparent;
}


.time-options div:hover,
.time-options div.focused {
    .time-select {
        position: relative;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.09);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 5px;
        width: 100px; /* adjust as required */
    }
    
    .time-options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%; /* adjust as required */
    }
    
    .time-options div {
        padding: 5px;
        // color: rgb(150, 75, 0);
        cursor: pointer;
       
    }
    
    .time-options div:hover,
    .time-options div.focused {
        background-color: rgba(150, 75, 0, 0.2);
    }
    }
    .time-label-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px; /* spacing between the button and the input */
    }
    .time-select.time-selected {
        background-color: transparent;
    }
    

