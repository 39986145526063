#sign-in-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .password-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .show-hide-password-toggle {
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .show-hide-label {
    cursor: pointer;
    font-size: 0.75rem;
    color: #964b00;
    font-weight: 900;
    line-height: 10px;
  }

  .underline {
    text-decoration: underline;
    color: #964b00;
  }
  .black {
    color: black;
    font-family: "nunito-sans";
    font-weight: 600;
  }
  .forgot-password {
    position: relative;
    top: -8px;
    cursor: pointer;
  }
  .newAcct {
    position: relative;
    top: 20px;
  }

  #sign-in-button {
    margin-top: 15px;
  }

  hr {
    height: 1px;
    background-color: #000;
    border: none;
    width: 85%;
  }
  .and-or.horizontal-bar:before,
  .or.horizontal-bar:before {
    width: 2px;
    height: 245px;
  }

  .input-label {
    grid-gap: 10px;
    align-items: center;
    color: #fff;
    display: grid;
    font-family: Roboto Slab;
    font-size: 1.5rem;
    font-weight: 800;
    grid-template-columns: auto auto;
    justify-content: start;
    margin-top: 15px;
  }
  .input-label > p {
    font-family: roboto-slab;
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 27px;
  }
  input::placeholder {
    color: #757575; /* Default placeholder color */
  }

  .red-placeholder::placeholder {
    color: red;
  }
  .custom-placeholder-color::placeholder {
    color: red;
  }

  .and-or span {
    font-size: 0.75rem;
    font-weight: 900;
    color: #000;
    border: solid 2px #000;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    padding: 2px;
    background: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
  }
  .seprator-icon .vertical-icon:before {
    -webkit-transform: translate(-50%, -50%) rotate(90deg) !important;
    transform: translate(-50%, -50%) rotate(90deg) !important;
  }
  .and-or.horizontal-bar:before,
  .or.horizontal-bar:before {
    height: 245px;
    width: 2px;
  }
  .and-or:before,
  .or:before {
    background-color: #000;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 245px;
    z-index: 0;
  }
  .and-or,
  .or {
    position: relative;
    text-align: center;
    top: 20px;
    // /* Media query for screen widths between 965px and 1023px */
    // @media screen and (min-width: 965px) and (max-width: 1023px) {
    //   top: 5px;
    // }
  }
  .phone_num {
    font-size: 0.75rem;
  }
  .createNewAcct {
    top: 10px !important;
    position: relative;
  }

  .seprator-icon {
    width: 100%;
    position: relative;
    top: -36px;
    .horizontal-icon {
      display: none;
    }

    .vertical-icon {
      &::before {
        transform: translate(-50%, -50%) rotateZ(90deg) !important;
      }
    }
  }

  .sep-icon {
    padding: 0 10px;

    .horizontal-icon {
      display: none;
    }

    .vertical-icon {
      &::before {
        height: 3px !important;
        width: 162px !important;
        transform: translate(-50%, -50%) rotateZ(90deg) !important;
      }
    }
  }

  .sep-icon {
    .vertical-icon {
      display: none;
    }

    .horizontal-icon {
      display: inline-block;
      width: 100%;
    }
  }
}
