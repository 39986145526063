.commentThumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.replyThumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.comment-username {
  color: #e2b645;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Increase the size slightly */
  }
  100% {
    transform: scale(1);
  }
}

.pulsing-heart {
  animation: pulse 1s infinite; /* Adjust timing as needed */
}

.redeemButton{
  font-size: 1.3rem;
  font-weight: 900;
  width: fit-content;
  height: auto;
  border-radius: 32px;
  color: #964b00;
  border-color: transparent;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 5px;
  animation: auto ease 0s 1 normal none running none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  backdrop-filter: brightness(4) saturate(3) blur(4rem);
  background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);
  box-shadow: 0 4px 3px 1px rgba(0, 0, 0, 0.7333333333), 0 6px 8px rgba(0, 0, 0, 0.7333333333), 0 -4px 4px rgba(0, 0, 0, 0.7333333333), 0 -6px 4px rgba(0, 0, 0, 0.7333333333), inset 0 0 3px 0 rgba(0, 0, 0, 0.7333333333);
  cursor: pointer;

}
.redeem-disabled {
  color: #000;
  opacity: 0.5; /* Example: make the button semi-transparent when disabled */
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
}
