.tooltip {
    color: rgba(0, 0, 0, 0.7333333333);
    position: absolute;
    z-index: 10;
    top: 70px;
    border-radius: 6px;
    width: 100%;
    backdrop-filter: blur(30px)!important;
    background-color: hsla(0,0%,100%,.09)!important;
    border: 1px solid hsla(0,0%,100%,.1)!important;
   .error-text{
    color:red;
    font-family: "roboto-slab";
    font-weight: 900;
   }
    svg {
      margin-right: 5px;
    }
  
    ul {
      list-style: none;
      padding: 0 10px;
    }
    .close-button{
      position:absolute;
      cursor: pointer;
    }
  }
  