.carousel {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    border: 1px solid #e2b645;
    box-shadow: 2px 2px 10px 0 rgba(99, 106, 119, 0.6);
    height: 210px;
    .slider-content {
      display: flex;
      height: 100%;
      flex-shrink: 0;
      transition: all 0.5s;
    }
    .slide {
      flex-shrink: 0;
    }
  }
  