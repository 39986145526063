#community-page {
  padding-bottom: 70px;
  #search-bar-wrapper {
    display: none; // Hide by default for mobile/tablet
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: sticky;
    top: 100px; // Adjust this value based  to "stick" above the buttons
    z-index: 10;
    margin: 0 auto 20px auto;
    width: fit-content;
    @media (min-width: 811px) {
      display: flex; // hiding the search bar for smaller screens will include on the post container instead
    }
    .required-message::placeholder {
      color: red;
      font-size: 1.5rem;
      font-weight: 800;
      background: rgba(0, 0, 0, 0.3);
      font-family: "roboto-slab";
      -webkit-text-stroke: 0.5008px red;
    }
    .input-container {
      display: flex;
      align-items: center; // Center-align children horizontally
      gap: 10px;
      .Clear-communitypg {
        position: absolute;
        bottom: 90px;
        @media (min-width: 851px) {
          bottom:0px;
          position: relative;
          right: 60px; /* Adjust as needed */
          cursor: pointer;
        }
      }
    }
    .buttons-container {
      display: flex;
      justify-content: center; // horizontally center the child buttons
      gap: 20px; // space between the two buttons
      margin-top: 20px;
      position: sticky;
      top: 10px; // Element will "stick" to the top once 10px from the top
      z-index: 10; // To ensure it's above other elements if there's any overlap
      .viewAll-inactive {
        font-size: 1.5rem;
        font-weight: 600;
        font-family: Helvetica;
        color: #fff;
        -webkit-text-stroke: 1px #e2b645;
      }
    }
    @media (max-width: 850px) {
      .input-container {
        flex-direction: column;
        input {
          margin-bottom: 10px; // Adds a little space between the input and the button
          padding: 15px 15px; // Adjust these values to increase the height and width slightly
        }
      }
    }
    input {
      padding-right: 10px; /* Adjust as needed */
      height: 30px;
      width: 100%;
      border-radius: 5px;
      outline: none;
      padding: 8px 10px;
      flex: 1;
      color: #fff;
      box-shadow: 0 1px 5px black;
      background: transparent;
      border-color: #e2b645;

      @media (min-width: 768px) {
        width: 500px;
      }
    }
  }

  .user-posts {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
}
.active-button {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}

.inactive-button {
  background-color: transparent; /* Inactive color */
}
.followOption-container {
  display: flex;
  flex-direction: "row";
  justify-content: space-around; /* Space out buttons evenly */
  gap: 10px;
}
.followingNumDskCommunity-inactive {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #e2b645;
}
.followingNumDskCommunity-active {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}
.followersNumDskCommunity-inactive {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #fdfd96;
}
.followersNumDskCommunity-active {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}

.MemHeader {
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  font-family: "roboto-slab";
  color: #fff;
  width: 100%;
  font-size: 0.75rem;
  text-align: center;
  @media (min-width: 568px) {
    font-size: 2rem;
  }
}
#communnitywrapper.special-styling {
  margin-top: 10%;
  padding-top: 50px;

  @media (max-width: 768px) {
    margin-top: 5%; // Lesser margin for smaller screens
    padding-top: 25px; // Lesser padding for smaller screens
  }
}
/* CSS */
.hidden {
  display: none;
}
