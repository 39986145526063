  button.globalized-btn{ width: 100%;
    margin: 20px auto;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    color: #964b00;
    height: 40px;
    background-image: linear-gradient(120deg, #f6f0dc 0%, #f3cf71 80%);  
    border-radius: 30px;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    font-weight: 800;
    text-shadow: 0 1px #fff;
    animation: active 0.9s alternate infinite;
    box-shadow: 0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb;
  }
  
  button.fitContent{
   width:fit-content
  }
  button.globalized-btn::-moz-focus-inner::-moz-focus-inner {
    border: 0;
  }
  
  button.globalized-btn > * {
    transition: transform 0.2s ease;
  }
   
  // styles button with box shadow on zbutton hover
  button.globalized-btn:hover:not([disabled]) {
    box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
      0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
  }
  
  button.globalized-btn:hover:not([disabled]) > *{
    transform: scale(0.975);
  }
  
  button.globalized-btn:focus:not(:active) {
    animation: active 0.9s alternate infinite;
    outline: none;
  }
  
  // styles when button is disabled
  button.globalized-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
  
  button.globalized-btn.icon,
  [role="button"].icon {
    width: 50px;
  }
  button.globalized-btn span{
    font-family: "Roboto Slab";
    font-weight: 900;
    color: #964b00;
    font-size: 1rem;
    white-space: nowrap;
  }
  .margin-bottom{
    margin-bottom: 10px;
  }
  
  #changing-money{
    margin-right:5px;
  }
  #instantmatch{
    margin-right:5px;
  }
  .widththreehun{
    width: 350px;
  }
  
  
  .fontpos{
    margin-left: 40px;
  }
   // animation
   @keyframes active {
    from {
        box-shadow: 0 4px 3px 1px #f3cf71, 0 6px 8px #ffe39d, 0 -4px 4px #ffe39d, 
        0 -6px 4px #f3cf71, inset 0 0 10px 0px #c79f27;
       }
     to { 
        box-shadow: 0 4px 3px 1px #f3cf71, 0 6px 8px #f3cf71, 0 -4px 4px #ffe39d, 
        0 -6px 4px #f3cf71, inset 0 0 3px 3px #e2b645;
       }
      }
  
      .light-gold-btn{
        animation: animation-light-gold 0.9s alternate infinite !important;
          // animation: animation-signup-blue 0.9s alternate infinite !important;
       }
  // animation
  // @keyframes active {
  //     from {
  //         box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
  //           0 -6px 4px #fefefe, inset 0 0 10px 0px #c79f27;
  //       // box-shadow: inset 0 0 10px 0px #c79f27;
  //       }
  //       to {
  //         box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
  //           0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
  //       }
  //   }
  .font-color{
    color:#233f5f !important;
  }
  @keyframes active-payout {
    from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px rgba(255, 255, 255, 0.1), 0 -4px 4px
         rgba(0, 0, 0, 0.05), 0 -6px 4px #fefefe, inset 0 0 10px 0px #ffd8a8;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #fff, 0 6px 8px #fff, 0 -4px 4px rgba(255, 255, 255, 0.1), 
        0 -6px 4px rgba(255, 255, 255, 0.1), inset 0 0 3px 3px #ffd8a8;
      }
  }
   // styling and giving animation on clear button
   .clear-btn{
      animation: animation-blue 0.9s alternate infinite !important;
      color: #233f5f;
      width: 100px;
      height: 30px;
      span{
          font-size: 1.2rem;
      }
    }
  
  // animation for clear button
  @keyframes animation-blue {
      from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 10px 0px #233f5f;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 3px 3px #233f5f;
      }
    }
  
    // animation for signup button
    @keyframes animation-signup-blue {
      from {
        box-shadow: 0 4px 3px 1px #233f5f, 0 6px 8px #233f5f, 0 -4px 4px #233f5f,
          0 -6px 4px #233f5f, inset 0 0 10px 0px #fff;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #233f5f, 0 6px 8px #233f5f, 0 -4px 4px #233f5f,
          0 -6px 4px #233f5f, inset 0 0 3px 3px #233f5f;
      }
    }
    // animation for signup button
    @keyframes animation-confirm-blue {
      from {
        box-shadow: 0 4px 3px 1px #233f5f, 0 6px 8px #233f5f, 0 -4px 4px #233f5f,
          0 -6px 4px #233f5f, inset 0 0 10px 0px #fff;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #233f5f, 0 6px 8px #233f5f, 0 -4px 4px #233f5f,
          0 -6px 4px #233f5f, inset 0 0 3px 3px #233f5f;
      }
    }
  
    // animation in our standard light blue color --> #186acf
  @keyframes animation-light-blue {
      from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 10px 0px #186acf;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
      }
    }
  
    @keyframes animation-light-gold{
      from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 10px 0px #C79F27;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
      }
    }
    @keyframes animation-bulleyes-blue {
      from {
        box-shadow: 0 4px 3px 1px #186acf, 0 6px 8px #186acf, 0 -4px 4px #186acf,
          0 -6px 4px #186acf, inset 0 0 10px 0px #c79f27;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #186acf, 0 6px 8px #186acf, 0 -4px 4px #186acf,
          0 -6px 4px #186acf, inset 0 0 3px 3px #186acf;
      }
    }
    // #3076bc
    @keyframes animation-sky-blue {
      from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 10px 0px #3076bc;
      // box-shadow: inset 0 0 10px 0px #c79f27;
      }
      to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
          0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
      }
    }
  
  
  
    // animation in our standard orange color  --> #c79f27
  @keyframes animation-orange {
      from {
        box-shadow: 0 4px 3px 1px rgba(148, 147, 147, 0.2), 0 6px 8px rgba(148, 147, 147, 0.2), 0 -4px 4px rgba(148, 147, 147, 0.2),
          0 -6px 4px rgba(148, 147, 147, 0.2), inset 0 0 10px 0px #c79f27;
      // box-shadow: inset 0 0 10px 0px rgba(148, 147, 147, 0.2);
      }
      to {
        box-shadow: 0 4px 3px 1px rgba(148, 147, 147, 0.2), 0 6px 8px rgba(148, 147, 147, 0.2), 0 -4px 4px rgba(148, 147, 147, 0.2),
          0 -6px 4px rgba(148, 147, 147, 0.2), inset 0 0 3px 3px rgba(148, 147, 147, 0.2);
      }
    }
  
  
  //   animation for add different location button
    .add-diff-location-btn{
      animation: animation-blue 0.9s alternate infinite !important;
    }
  //   animation for add location button
    .add-location-btn{
      animation: animation-light-blue 0.9s alternate infinite !important;
    }
  //   animation for add save button
    .addSaveOrange{
      animation: animation-orange 0.9s alternate infinite !important;
    }
  //   animation on remove location button
   .remove-location-btn{
      animation: animation-blue 0.9s alternate infinite !important;
   }
  //  animation on signup button
   .signup-btn{
    animation: animation-light-gold 0.9s alternate infinite !important;
      // animation: animation-signup-blue 0.9s alternate infinite !important;
   }
  //  when signup button  is disbaled animation will not show
   button.signup-btn:disabled{
      animation: none !important;
      box-shadow: 0 4px 3px 1px #233f5f, 0 6px 8px #233f5f, 0 -4px 4px #233f5f,
          0 -6px 4px #233f5f, inset 0 0 3px 3px #233f5f;
   }
  
   .verify-btn{
      width: 140px;
      background-image: linear-gradient(to top, #233f5f 20%, #fff 112%, #233f5f 100%) !important;
      animation:animation-confirm-blue 0.9s alternate infinite !important;
   }
  
   .cancel-btn{
      color: #233f5f !important;
      width: 165px !important;
      span{
          font-size: 1.1rem;
      }
      animation: animation-blue 0.9s alternate infinite !important;
    }
  
    .update-btn{
      background-image: linear-gradient(to top, #233f5f 20%, #fff 112%, #233f5f 100%) !important;
      width: 165px !important;
      height: 50px !important;
      color: #fff !important;
      text-shadow: none !important;
      animation: animation-signup-blue 0.9s alternate infinite !important;
      span{
        font-size: 1.1rem;
        font-family: 'Roboto Slab';
      }
    }
  
    
    // globalized class name for button with dark blue text color and animation color
    .dark-blue-btn{
      animation: animation-blue 0.9s alternate infinite !important;
      color: #233f5f;
      
    }
  
    .orange-btn{
      animation: active 0.9s alternate infinite;
      color: #c79f27;
    }
  
    .sky-blue-btn{
      animation: animation-sky-blue  0.9s alternate  infinite !important;
      color: #3076bc;
    }
  
  
    .light-blue-btn{
      animation: animation-light-blue 0.9s alternate infinite !important;
      color: #186acf !important;
    }
    .blue-btn{
      animation: animation-signup-blue 0.9s alternate infinite !important;
      background-image: linear-gradient(to top, #233f5f 20%, #fff 112%, #233f5f 100%) !important;
      color: white;
      min-width: 100px;
    }
   // just add this class to the button html property and will see new style easy
    .paychckout-btn{
      animation: active-payout 0.9s alternate infinite !important;
      background-image: linear-gradient(to bottom, #f6f4f4 -112%, #ffd9ab -14%, rgba(243, 145, 25, .98) 73%, #c79f27) !important;
      min-width: 100px;
    }
  
    .image-mr{
      img,
      svg{
        margin-right: 10px !important;
      }
    }
  
    .save-service-btn{
      text-transform: uppercase;
      height: 38px !important;
      width: 200px !important;
      cursor: pointer;
    }
  
    .search-btn{
      svg{
        width: 30px;
      }
    }