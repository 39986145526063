/* ====circle animation======= */

#cont-checkout {
    display: block;
    height: 200px;
    width: 200px;
    margin-bottom: 15px;
    border-radius: 100%;
    position: relative;
    transform: rotate(-90deg);
  }
  
  #cont-checkout .icon-level {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    content: "";
    background-image: var(--selection-background-image);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20%;
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    color: #000;
    transform: rotate(90deg);
    font-size: 3rem;
    font-family: "Roboto Slab" !important;
    font-weight: bold;
  }
  
  #svg circle {
    // stroke-dasharray: 0;
    transition: stroke-dashoffset 1.5s linear;
    stroke-width: 0.5em;
  }
  
  /* #svg {
      animation: threedCircleAnimate 1.5s linear;
  }
  
  @keyframes threedCircleAnimate {
      0% {
          transform: rotateX(-180deg);
      }
  
      100% {
          transform: rotateX(0deg);
      }
  } */
  
  #circle1 {
    stroke: #CECECE;
    stroke-width: 0.55em;
  }
  
  .percent {
    color: #000;
  }
  
  .svg_card {
    display: block;
  }
  
  /* #barSilver,
  #barGold,
  #barPlatinam,
  #barDiamond {
      display: none;
  } */
  
  // .points-to-go {
  //   position: relative;
  //   bottom:70px;
  //   // right: 25%;
  //   // margin-bottom: 15px;
  //   font-family: roboto!important;
  //   font-size: 1rem;
  //   color: #000000;
  // }
  
  // .Unlock_level {
  //   position: relative;
  //   bottom: 95px;
  //   right:200px;
  //   width: 100%;
  //   text-align: center;
  //   font-family: "Nunito";
  //   font-size: 0.8rem;
  //   text-transform: uppercase;
  //   font-weight: bold;
  // }