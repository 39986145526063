.red-placeholder::placeholder {
  color: red;
}
.custom-placeholder-color::placeholder {
  color: red;
}
.error-message {
  color: red;
}

.blinker-wrapper {
  position: relative;

  // hide custom blinker when input is focused
  input:focus + .blinking-cursor {
    display: none;
  }
  input:not(:placeholder-shown) + .blinking-cursor {
    display: none;
  }
  textarea:focus + .blinking-cursor {
    display: none;
  }
  textarea:not(:placeholder-shown) + .blinking-cursor {
    display: none;
  }
}

.blinking-cursor {
  font-weight: 600;
  font-size: 23px;
  display: block;
  position: absolute;
  left: 10px;
  top: 0px;
  letter-spacing: -4px;
  animation-duration: 1s;
  animation-timing-function: step-end;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
  animation-name: gold;
}

@keyframes gold {
  from,
  to {
    color: transparent;
  }
  50% {
    /* Use color property from inline style here */
    color: #e2b645;
  }
}

.blinkingcursor {
  font-weight: 100;
  font-size: 23px;
  color: #000;
  display: block;
  position: absolute;
  left: 10px;
  top: 0px;
  letter-spacing: -4px;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
.input-cursor {
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.quick-filter-cursor {
  left: 25px;
  top: 15px;
}
.banner-search-cursor {
  left: 25px;
  top: 4px;
}
.topten-cursor {
  top: 10px;
}

.community-username-input-cursor {
  top: 10px;
  @media screen and (min-width: 811px) and (max-width: 850px) {
    left: 10%;
  }
}

.blinking-middle{
  left: 20%;
  top: 40%;
}

.signup-cursor {
  left: 10px;
  top: 65%;
  transform: translateY(-50%);
}

.email-signup-cursor {
  left: 10px;
  top: 35%;
  transform: translateY(-50%);
}
.settings-signup-cursor {
  left: 10px;
  top: 65%;
  transform: translateY(-50%);
}
.signup-mobile-cursor {
  left: 21%;
  top: 82%;
  transform: translateY(-50%);
}
.signup-password-cursor {
  left: 5%;
  top: 68%;
  transform: translateY(-50%);
}

.confirm-password-cursor {
  left: 10px;
  top: 65%;
  transform: translateY(-50%);
}
.confirm-cursor {
  left: 70px;
  top: 60%;
  transform: translateY(-50%);
}

.signin-cursor {
  left: 10px;
  top: 65%;
  transform: translateY(-50%);
}

.margtop5percent {
  margin-top: 5%;
}

.teaxtarea-cursor {
  left: 10px;
  top: 2px;
}
.quantity-cursor {
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
#business-form {
  .blinking-cursor {
    top: 4px;
    left: 5px;
  }
}

//
.search-area {
  .blinking-cursor {
    left: 35px;
    top: 8px;
  }
}
.react-select-wrapper {
  .blinking-cursor {
    font-size: 1.8rem !important;
    letter-spacing: -5px;
  }
}
//  setting blinking cursor position
.single-service {
  .area-2 {
    .left {
      .blinking-cursor {
        left: 12px;
      }
    }
    .right {
      .blinking-cursor {
        left: 10px;
      }
    }
  }
}
.signin-password-cursor {
  left: 5%;
  top: 65%;
  transform: translateY(-50%);
  font-weight: 100;
  animation: blink 1s step-start infinite;
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #000;
    }
  }
}
.signin-mobile-cursor {
  left: 22%;
  top: 78%;
  transform: translateY(-50%);
  font-weight: 100;
  animation: blink 1s step-start infinite;
  @media screen and (max-width: 399px) {
    left: 25%;
  }
  @media screen and (min-width: 400px) {
    left: 20%;
  }
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #000;
    }
  }
}
.forgot-mobile-cursor {
  left: 20%;
  top: 65%;
  transform: translateY(-50%);
}
.settings-mobile-cursor {
  left: 20%;
  top: 90%;
  transform: translateY(-50%);
}
.settings-emailValue-cursor {
  left: 10px;
  top: 55%;
  transform: translateY(-10%);
}
.signup-emailValue--cursor {
  left: 10px;
  top: 20%;
  transform: translateY(-10%);
}

.enter-service-cursor {
  .blinking-cursor {
    left: 5px;
    font-weight: 100;
  }
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
