#forgot-password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .cta-section {
      display: flex;
      gap: 10px;
  
    }
  
  }