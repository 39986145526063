.chat-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  z-index: 10;
}

.message {
  margin: 15px 0; /* Increase margin to space out the messages */
  display: flex;
}
.messages {
  /* Add this to space out the messages from the input */
  margin-bottom: 10px;
}
/* Make sure your message bubbles are not too wide */
.message-content {
  max-width: calc(
    100% - 200px
  ); /* Increase the subtracted value if necessary */
  word-wrap: break-word;
}
/* Adjust this if your message actions are overlapping with message bubbles */

.other-user .message-content {
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 10px #000;
  border-radius: 10px 10px 10px 0;
  position: relative;
  padding: 10px 15px;
  color: #f3cf71;
}
.inbox-section .message-content {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
}
.me .message-content {
  background-color: #000; /* No fill */
  border: 2px solid #000; /* color choice border */
  border-radius: 10px 10px 0px 10px; /* Adjust the border-radius for the 'me' message bubble */
  position: relative;
  padding: 10px 15px;
  margin-left: auto;
  box-shadow: 2px 2px 10px #000;
}

.me .message-content::after {
  content: "";
  position: absolute;
  top: 100%; /* centers the triangle on the bubble */
  left: 20px;
  width: 0;
  height: 0;
  transform: translateY(-40%) rotate(116deg); /* Adjusted rotation and translation */
  border-right: 11px solid #000;
  border-top: 20px solid transparent;
  border-left: 0;
  border-bottom: 23px solid transparent;
}

.inbox-section .other-user .message-content::after {
  content: "";
  position: absolute;
  bottom: -18px;
  right: 60%;
  width: 0;
  height: 0;
  transform: translateY(-40%) rotate(140deg);
  border-left: 15px solid transparent;
  border-top: 15px solid #000;
  border-right: 15px solid transparent;
  border-bottom: 0;
}

.timestamp {
  color: #ccc;
  font-size: 0.75rem; /* Adjust the font size as needed */
  text-align: right; /* Align the timestamp to the right */
  padding-right: 10px; /* Adjust the padding to align with the message */
  margin-top: 2px; /* Space between the buttons and the timestamp */
}

.sp-messages {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  z-index: 10;
  border: 10px solid #000;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}
.inbox-section .message {
  margin: 20px 0;
  display: flex;
}

.chatFocus {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}
.messages {
  overflow-y: auto; /* This ensures scrollbar appears when content gets too long */
}
/* This targets the scrollbar track or the background */
.messages::-webkit-scrollbar {
  width: 10px; /* This sets the width of the scrollbar */
}
/* This targets the moving part of the scrollbar */
.messages::-webkit-scrollbar-thumb {
  background: #e2b645; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Making the scrollbar thumb rounded */
}

/* Optional: Style for hover on the scrollbar thumb for better user feedback */
.messages::-webkit-scrollbar-thumb:hover {
  background: #d4a53c; /* A slightly darker shade of the original color */
}

.popup-width-center {
  right: 50%;
  transform: translateX(50%);
  background-color: rgb(21 21 21 / 90%);
}
.inbox-type-btns {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Add space between the input and the button */
}
/* Glass effect for the chat input */
.chat-container  input {
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Slight white translucent background */
  backdrop-filter: blur(10px); /* Apply blur */
  transition: background 0.3s ease; /* Optional: Smooth transition */
  border: 2px solid #e2b645;
  color: #fdfd96; /* Color of input text */
}

/* On Focus effect for chat input */
.chat-container  input:focus {
  box-shadow: 0 1px 5px black; /* Shadow effect */
  border-color: #e2b645; /* Adjust as necessary */
  outline: none;
}

/* Blinking cursor effect */
@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.message-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.delete-btn,
.reply-btn {
  margin-left: 5px;
  padding: 5px 10px;
  white-space: normal;
  max-width: 100px; /* Adjust as needed */
  background-color: #e2b645;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-btn:hover,
.reply-btn:hover {
  background-color: #d4a53c;
}

.delete-btn:focus,
.reply-btn:focus {
  outline: none;
}
.emoji-btn {
  background: none;
  border: none;
  font-size: 1.5rem; /* Adjust size as needed */
  cursor: pointer;
  /* Add any additional styles */
}

.emoji-btn:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Optional: background on hover */
}
/* Shared Text Area Styling */
.chat-container textarea {
  resize: none;
  text-indent: 20px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  border-radius: 5px;
  outline: none;
  background: transparent;
  color: #fefefe;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
}
.emoji-selector {
  background: #000;
}

.chat-container textarea::placeholder {
  font-family: helvetica;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.5;
  color: #ffff;
  -webkit-text-stroke: 0.5008px #e2b645;
}

/* Apply the same styles for .userComment-container to .chatBox-container */
.chat-container {
  box-shadow: 0 1px 5px black;
  height: 500px; /* Adjust based on your required chat window height */
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  border-radius: 4px;
}
/* Additional container for the message block */
.message-block {
  margin-bottom: 10px; /* Space between messages */
}

/* Wrapper for message content and actions */
.message-content-wrapper {
  display: flex;
  flex-direction: column;
}

.beneathSend{
  display:flex;
}