.book-page-container {
    display: flex;
    align-items: center;
    gap: 10px;
  
    .user-thumbnail-container {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 1000px;
      .user-thumbnail {
        object-fit: contain;
        width: 100%;
      }
    }
    .user-full-name {
      font-weight: 500;
      color: black;
    }
     .user-last-name{
      font-weight: 500;
      color:black;
    }
    .user-last-name {
      @media screen and (max-width: 364px) {
        display: none;
      }
    }
    @media screen and (max-width:549px){
      flex-direction: column;
    
      .user-full-name {
        text-align: center;
        
      }
    }
  }