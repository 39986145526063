.BlockedPgHeader,
.ConnectionsPgHeader {
  margin: 0;
  background-color: rgba(255, 255, 255, 0.09);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  font-family: "roboto-slab";
  color: #fff;
  width: 100%;
  margin-top: 8%;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

#pageContainer {
  .buttonsContainer-wrapper {
    position: relative;
    padding-top: 10px;
  }
  .red-error-border {
    border: 2px solid red;
    @media (min-width: 768px) {
      height: 120px;
      padding-top: 30px;
    }
  }
  .error-message {
    color: red;
    font-size: 1.3rem;
    transform: translateX(-50%);
    font-weight: bold;
    margin-left: 35%;
  }
}

@media (min-width: 800px) {
  .BlockedPgHeader,
  .ConnectionsPgHeader {
    margin-top: 5%;
  }
}
// since the parent has .button best to targe the class on the parent
// reset the margin...
.button {
  // Mobile-first styles
  margin-bottom: 30px; // For mobile view when in a column
  // Larger screen styles
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px; // Give space between buttons when in a row
  }
}
// Additional styles for your other elements/classes can follow...
/*  base or mobile-first style (for screens smaller than 768px) */
.buttonsContainer {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Media query for screens 768px and up */
@media (min-width: 768px) {
  .buttonsContainer {
    flex-direction: row;
    padding: 0 325px;
    margin-top: 0%;
  }
}

/* Media query for screens between 768px and 1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .buttonsContainer {
    justify-content: center;
    padding: 0; /* This removes the large padding for screens in this size range */
  }
}
.searchSlidConnectionsPg {
  margin-left: 0px;
}

.connections-input-slider-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.connections-input-container,
.SlideBtn {
  width: 100%;
}
@media (min-width: 768px) {
  .connections-input-slider-container {
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    padding-bottom: 10px;
  }

  .connections-input-container {
    flex: 1;
    margin-right: 10px;
    width: 70%; /* Temporarily set this to see if it affects the layout */
  }

  .SlideBtn {
    width: 30%; /* Temporarily set this to see if it affects the layout */
  }
}
/* Styles for screens smaller than 850px */
@media (max-width: 850px) {
  .connections-input-container {
    flex-direction: column;

    input {
      margin-bottom: 10px;
      padding: 15px 15px;
    }
  }
}
.connects-required-message {
  color: red;
  position: absolute;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "roboto-slab";
  right: 30%;
  bottom: 30%;
}

/* Placeholder styles for the input inside .connections-input-container */
.connections-input-container input::placeholder {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #ffff;
  -webkit-text-stroke: 0.5008px #e2b645;
}

/* Base styles for the input */
.connections-input-container input {
  padding-right: 10px;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  padding: 8px 10px;
  flex: 1;
  color: #fff;
  box-shadow: 0 1px 5px black;
  background: transparent;
  border-color: #e2b645;

  @media (min-width: 768px) {
    width: 98%;
  }
}

.blockingNum-active {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}

.blockingNum-inactive {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #fdfd96;
}

.followingNum-inactive {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #e2b645;
}
.followingNum-active {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}
.followersNum-inactive {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #fdfd96;
}
.followersNum-active {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff;
  -webkit-text-stroke: 1px #000;
}
.stickyTop {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10; /* Ensure it stays on top of other elements */
}
.red-border {
  border: 2px solid red;
}

.connects-required-message {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-weight: bold;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.Connectionsunderlay {
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  height: 470px; /* Ensure it covers the entire height */
  z-index: 2; /* Increase the z-index value to ensure it covers the buttons */
}

@media (min-width: 768px) {
  .Connectionsunderlay {
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    height: 250px; /* Ensure it covers the entire height */
    z-index: 2; /* Increase the z-index value to ensure it covers the buttons */
  }
  /* Additional styles for screens >= 768px */
}
