#landing-page {
  .book-section {
    margin-top: -26px;
    @media screen and (min-width: 290px) {
      @media (min-aspect-ratio: 0.5) {
        zoom: 75%;
      }
    }
  }
  .landing-page-content-wrapper {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items horizontally */
    justify-content: center; /* Center align items vertically */
    backdrop-filter: blur(100px);
    background: transparent;
    @media screen and (min-width: 1024px) {
      flex-direction: row; /* Side by side on larger screens */
      align-items: center; /* Center align items horizontally */
    }
  }

  .form-section {
    width: 100%; /* Full width on smaller screens */
    display: flex;
    justify-content: center; /* Center content within this section */
    @media screen and (max-width: 1023px) {
      // margin: auto; /* Center the form with automatic margins */
    }
  }
}
