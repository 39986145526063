#events-page {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  padding-bottom: 70px;
  .events-main-content {
    display: flex;
    flex-direction: column;
    white-space: wrap;
    .events-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 5px;
      justify-content: center; /* Horizontally center the items */
      align-items: center;
      margin: 0 auto; /* Center the entire flex container on the page */
    }
  }
  .event-listing-page-toggle-filters {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center; /* Centers the button horizontally */
    backdrop-filter: blur(100px);
    position: sticky;
    z-index: 5; /* Ensures it stays above the CategoryContainer and under the hamburger open menu */
    top: 50px; /* has to be a higher spacing because of objects above set at 100px to stick from the header */
    @media (min-width: 811px) {
      cursor: pointer;
      z-index: 2; /* Ensures it stays above the CategoryContainer and under the hamburger open menu */
      position: fixed;
      top: 15%; /* Adjust as needed */
      left: 5%; /* Adjust as needed */
    }
  }
  .Clear-Event-ZipCode-Search {
    position: absolute;
    right: 10px; // Adjust as needed
    top: 30%;
    transform: translateY(-50%);
    color: rgb(253, 253, 150);
    padding: 1px 1px; 
    cursor: pointer;
  }

  .Event-list-search {
    backdrop-filter: blur(100px);
    z-index: 5;
    position: sticky;
    top: 100px;
    @media (min-width: 811px) {
      padding-top: 50px;
      cursor: pointer;
      position: fixed;
      top: 22%;
      left: 3%;
      z-index: 2;
    }
  }
}

.event-follow-options-filter-container {
  display: flex;
}
.followers-event-active,
.Following-event-active {
  color: #e2b645;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Helvetica;
}

.followers-event-inactive,
.Following-event-inactive {
  color: #fdfd96;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Helvetica;
}

.event-categories-search-container {
  position: relative; /* Ensures relative positioning context */
  .cancel-categories-btn {
    position: absolute;
    top: 100px; /* Adjust this value so the button does not overlap with the header */
    z-index: 20; /* Make sure this is higher than the CategoryContainer but lower than the header's z-index */
  }
  .CategoryContainer {
    position: absolute;
    z-index: 20; /* Ensure this is above the page content but below the cancel button and header */
    bottom: -100px; /* Position it below the header; adjust as needed */
    @media screen and (min-width: 300px) {
      max-width: 300px;
    }
    @media screen and (min-width: 350px) {
      .container {
        max-width: 300px;
      }
    }
    @media screen and (min-width: 400px) {
      .container {
        max-width: 350px;
      }
    }
    @media screen and (min-width: 450px) {
      .container {
        max-width: 400px;
      }
    }
    @media screen and (min-width: 500px) {
      .container {
        max-width: 450px;
      }
    }
    @media screen and (min-width: 550px) {
      .container {
        max-width: 500px;
      }
    }
    @media screen and (min-width: 600px) {
      max-width: 564px;
    }
    @media screen and (min-width: 650px) {
      .container {
        max-width: 608px;
      }
    }
    @media screen and (min-width: 700px) {
      max-width: 652px;
    }
    @media screen and (min-width: 750px) {
      .container {
        max-width: 696px;
      }
    }
    @media screen and (min-width: 800px) {
      .container {
        max-width: 740px;
      }
    }
    @media screen and (min-width: 811px) {
      max-width: 750px;
    }
  }
}

.Clear-Event-City-Search {
  position: absolute;
  right: 10px; // Adjust to align the button within the input
  top: 50%;
  transform: translateY(-50%); // Vertically center the button
  cursor: pointer;
}
.Clear-Event-Title-Search {
  position: absolute;
  right: 10px; // Adjust to align the button within the input
  top: 30%;
  transform: translateY(-50%); // Vertically center the button
  cursor: pointer;
  /* Additional styling for the clear button */
}
