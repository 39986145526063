#site-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 811px) {
    height: 50px;
  }
  #site-name-logo {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    display: flex;
    // setting x and y background size to be stretched
    background-size: 100% 100% !important; // The image is being stretched or squished to fit the dimensions of the header
    // gap: 10px;
    padding: 16px 10px;
    background: url("../../../assets/leatherTexture.png");
    background-color: #030303;
    color: #e2b645;
    font-weight: 500;
    font-size: 0.75rem;
    // white-space: nowrap;
    @media (min-width: 400px) {
      gap: 10px;
      padding: 15px 30px;
      font-size: 1rem;
      white-space: nowrap;
    }
    @media (min-width: 378px) {
      font-size: 1.3rem;
      white-space: nowrap;
    }
    @media (min-width: 811px) {
      font-size: 2.5rem;
    }
    #meetonup-svg {
      width: 30px;
      height: auto;

      @media (min-width: 768px) {
        width: 40px;
      }
    }
  }
  nav {
    display: flex;
    gap: 10px;
    z-index: 2; // to ensure it stays on top
    padding: 0px;
    @media (min-width: 305px) {
      padding: 10px;
    }

    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;

      height: fit-content;
      width: fit-content;
      cursor: pointer;
      text-decoration: none;
      font-weight: 800;
      font-family: "roboto-slab";

      .nav-link-circle {
        width: 5vw;
        height: 5vw;
        max-width: 50px;
        max-height: 50px;
        border-radius: 1000px;
        background-color: rgb(156, 156, 156);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 25px;
        box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
          0 30px 60px -30px rgba(0, 0, 0, 0.3),
          inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
        border: 1px solid #e2b645;

        &.active {
          box-shadow: none;
          cursor: not-allowed;
        }

        a {
          color: #e2b645;
          text-decoration: none;
          font-size: 1rem;

          @media (min-width: 1024px) {
            font-size: 1.25rem;
          }

          &.active {
            color: #fff;
          }
        }
      }
      @media (max-width: 812px) {
        .nav-link-circle {
          width: 20px; // Smaller size for smaller screens
          height: 20px;
          // Adjust other styles if necessary
        }
      }

      @media (min-width: 812px) {
        position: relative;
        flex-direction: column;
        margin: 0;
        gap: 0;
      }

      .dropdown-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%; // to position it just below the nav-link
        right: 0; // adjust as necessary
        background-color: #030303; // adjust as per your design
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); // to give it a bit of depth
        z-index: 3; // to ensure it stays on top of other elements
      }
    }

    .dropdown-link {
      color: #e2b645;
      padding: 10px 15px;
      text-decoration: none;
      transition: background 0.2s ease;
      font-weight: 800;
      font-family: "roboto-slab";

      &:hover {
        background-color: rgba(226, 182, 69, 0.1); // slight hover effect
      }
    }
  }
}

// Assuming you have a <nav> element with a class of "footer-nav" in your HTML
.footer-nav {
  display: flex; // Use flexbox for horizontal alignment
  flex-direction: row; // Align items in a row
  justify-content: space-around; // Distribute space around items
  align-items: center; // Vertically center items within the nav
  padding: 10px 0; // Padding at the top and bottom of the nav
  background-color: #030303; // Background color of the nav
  color: #e2b645; // Text/icon color
  position: fixed; // Fix to the bottom of the viewport
  bottom: 0; // Align to the bottom
  left: 0; // Align to the left
  width: 100%; // Full width of the viewport
  z-index: 30; // Above other content
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); // Optional: adds a shadow effect to the top of the nav

  .nav-link {
    display: flex;
    flex-direction: column; // Stack icon and text
    align-items: center; // Center items horizontally
    text-decoration: none; // Remove underline from links

    .nav-link-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px; // Fixed width for the circle
      height: 50px; // Fixed height for the circle
      border-radius: 50%; // Make it circular
      background-color: #fff; // Background color of the circle
      margin-bottom: 5px; // Space between the circle and text
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Optional: adds a shadow effect to the circle
    }
    span {
      // Style for the text label under the icon
      color: #e2b645;
      font-size: 12px; // Adjust text size as needed
    }
  }
}
// In siteHeader.scss or a new headerNav.scss file
// Hide "Account" and "Chat" links in the footer on mobile
@media (max-width: 811px) {
  .footer-nav .nav-link-account,
  .footer-nav .nav-link-chat {
    display: none;
  }
}

// Hide other links in the header on mobile
@media (max-width: 811px) {
  #site-header nav .nav-link:not(.nav-link-account):not(.nav-link-chat) {
    display: none;
  }
}
