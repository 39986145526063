.RadioHeadWrapper {
    .radioHead {
      position: relative;
      display: inline-flex; 
      align-items: center;
      
      input[type="radio"] {
        display: none; 
      }
  
      label {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 30px; 
        font-size: 1.5rem;
        font-weight: 800;
        -webkit-text-stroke: 0.5008px black;
        font-family: "Roboto Slab";
  
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #e2b645;
          background: transparent;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }
  
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 5%;
          width: 16px;
          height: 16px;
          background: #e2b645;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          opacity: 0;
        }
      }
  
      input[type="radio"]:checked + label::after {
        opacity: 1;
      }
    }
    
    .radioHead, .radioHead label {
      cursor: pointer;
    }
  }
  