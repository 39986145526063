.btn {
  border: 1px solid #964b00;
  cursor: pointer;
  background: transparent;
  font-weight: 800;
  border-radius: 25px;
  color: #232f5f;
  font-size: 1.3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  white-space: nowrap !important;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  font: bold 0.8rem "roboto-slab";
}

.glow-on-animation:before {
  content: "";
  background: linear-gradient(
    45deg,
    #f08400,
    #fff,
    #016dda,
    #fff,
    #f08400,
    #fff,
    #016dda,
    #fff,
    #f08400
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing linear infinite; // play in a continous loop
  animation-duration: 20s;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  border-radius: inherit;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.glow-on-animation:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // default background
  background: inherit;
  // Note: if the button has background class it out and give the "after" the same color in this way we can simulate the button press and outer border animtaion
  left: 0;
  top: 0;
  border-radius: inherit;
}

// this is an example class if the button has transparent background color copy the body background color and give it to "after" for example viewless/more button on the parent pages
.glow-on-btn-transparent:after {
  background: #f0f6ff;
}
// use this class name to show animation by default without any button press or hover
.glow-on-by-default:before {
  opacity: 1;
}
// use this class name to show animation on active means when button is pressed.
// this will make the bacground of the button transparnt and will use to show the "before element" which contains the animaiton colors.
.glow-on-press:active {
  color: #fff !important;
}
.glow-on-press:active:after {
  background: transparent;
}
.glow-on-press:active:before {
  opacity: 1;
}
//
.glow-on-press-red-text:active {
  color: red !important;
}

.show-animation:before {
  opacity: 1;
}

// class to control animation speed
.animation-5s:before {
  animation-duration: 5s;
}
.animation-10s:before {
  animation-duration: 10s;
}

.animation-15s:before {
  animation-duration: 15s;
}

.animation-20s:before {
  animation-duration: 20s;
}

// animations
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
