.CategoryContainer {
  background-image: linear-gradient(113deg, #f6f0dc 0%, #f6f0dc 80%);
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(100px, 1fr) /* Define the grid columns */
  ); /* Adjust minmax values as needed and auto fit will cause grid items to automatically fit the container's width, adjusting the number of columns based on the available space. This removes the need for multiple media queries for different screen widths.*/
  grid-gap: 15px; // Gap between grid items
  justify-content: center;
  background-color: rgba(242, 246, 249, 0.8);
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 560px); /* Adjust the max-height as needed, considering header/footer height */
  @media screen and (min-width: 811px) {
    max-width: 750px;
    position: absolute; /* Adjust as needed */
    z-index: 10;
  }
}
// 100vh: This represents 100% of the viewport height. vh stands for viewport height, and 100vh is the full height of the screen.
// 60px: This is a fixed value, typically used to account for other elements on the page, like a header or footer. In this example, it assumes that the combined height of the header and footer (or any other fixed elements at the top and bottom of your page) is 60 pixels.

.CategoryService {
  margin: 10px 0;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.33;
  font-family: "Roboto Slab";
  align-items: center;
  text-align: center;
  color: #964b00;
  font-weight: 900;
}
.CategoryService:hover {
  cursor: pointer;
}
.CategoryService {
  /* Remove or reduce margin if it's too much */
  margin: 5px 0;
  /* Other styles */
}
.center-menu-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


