.hidden {
  display: none;
}

#phone-verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;

  #top-section {
    h1 {
      margin: 0px;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 24px;
      color: black;
    }

    #timer {
      color: #964b00;
      margin: 16px 0 8px 0;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 900;
    }

    #timer-text {
      margin: 0;
      font-size: 0.75rem;
      font-style: italic;
      color: black;
      font-weight: 900;
      font-family: "Nunito Sans";
    }

    h2 {
      margin: 0;
      font-size: 1rem;
      line-height: 1rem;
      color: black;
      font-weight: 900;
      font-family: "Nunito Sans";
    }

    #verify-input {
      color: #964b00;
      text-align: center;
      border: 1px solid #000;
      margin: 20px 0 10px 0;
      letter-spacing: 2px;

      &:focus {
        border: 1px solid #964b00;
        outline: none;
      }

      &::placeholder {
        color: #964b00;
        opacity: 68%;
        font-size: 0.9rem;
        font-weight: 800;
      }
    }

    .error {
      color: red;
      font-size: 13px;
    }
  }

  #bottom-section {
    display: flex;
    gap: 10px;

    button {
      cursor: pointer;
      position: relative;
      padding: 8px 15px;
      border-radius: 100px;
      font-weight: 700;
      border: 1.5px solid #964b00;
      background: hsla(0, 0%, 100%, 0.09);
      box-shadow: 0 5px 5px #b3ab96;
      color: #964b00;
      box-shadow: 1.5rem 1rem 4rem -2rem rgba(0, 0, 0, 0.7333333333);
      background: linear-gradient(92deg, #faf9f5 0%, #ffe39d 80%);
      box-shadow: 0 5px 5px #b3ab96;
      background: radial-gradient(
        ellipse at center,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transition: all 0.3s ease-in-out;
      z-index: 2;
      border: 2px groove rgba(255, 255, 255, 0.5);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &#cancel-btn {
      white-space: nowrap;
    }

    &#request-btn {
    }

    &#skip-btn {
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 285px) {
  #phone-verification-form #bottom-section {
    gap: 0px !important;
  }
}
