.groups-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* In your SCSS or CSS file */
// .hide-chat-animation {
//   @media (max-width: 811px) {
//     display: none; /* Hide the Chat Animation on small screens */
//   }
// }

