.post-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
}
.form-post {
  align-items: center;
  animation-duration: 0.9s;
  animation-name: slideDown;
  animation-timing-function: ease;
  -webkit-animation-duration: 0.9s;
  -webkit-animation-name: slideDown;
  -webkit-animation-timing-function: ease;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid #707070;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0 rgba(99, 106, 119, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  width: 60vw;
}
.postComment-container textarea {
  text-indent: 50px;
}

.postComment-container textarea {
  -webkit-backdrop-filter: blur(100px);
  resize: none;
  backdrop-filter: blur(100px);
  background: transparent;
  background: rgba(0, 0, 0, 0.3);
  border-color: #e2b645;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000;
  color: #fefefe;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;
  width: 100%;
  width: 60vw;
  z-index: 5;
  &::placeholder {
    font-family: helvetica;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5;
    color: #ffff;
    -webkit-text-stroke: 0.5008px #e2b645;
  }
}
.post-clear-comment-button {
  margin: 0px;
  position: relative;
  right: 100%;
  padding-bottom: 10px;
}
.post-comment-btns {
  display: flex;
  list-style: none;
  margin: 0;
  position: relative;
  align-items: flex-end; /* Aligns children at the bottom */
}

.postListItem {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

/* Specific adjustments for CameraIcon if needed */
#cameraIcon {
  transform: translateY(6px);
}

.postTopContain {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  width: 100%;
}

/* Styling for the thumbnail */
.LoggedinPostCommentThumbnail {
  width: 40px; /* Adjust as required */
  height: 40px;
  bottom: 20px; /* Adjust to vertically center with the textarea */
  border-radius: 50%; /* This will make it circular */
  overflow: hidden; /* To make sure the image doesn't spill outside the div if it's not a perfect square */
  position: relative; /* This will allow us to place the thumbnail right in front of the input */
  z-index: 6; /* Makes sure it stays above the input */
}

.LoggedinPostCommentThumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the div fully */
}
.character-error {
  color: red;
  font-size: 1.2rem;
  padding-left: 10px;
  font-weight: 600;
  font-family: "roboto-slab";
  background-color: rgba(0, 0, 0, 0.5);
}
.postTopContain {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Creates space between items */
  width: 100%;
}
